import React, { useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { TRACK_SYMPTOM, ADDITIONAL_NOTE } from "../../constants/DashboardTranslation";

import { useForm } from "react-hook-form";
import trackerSchema from "./validations/trackerSchema";
import { yupResolver } from "@hookform/resolvers";
import format from "date-fns/format";

import { Form, Row, Col, Alert } from "react-bootstrap";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";

import { postAdditionalNote } from "../../api/TBIRequests";

const AdditionalNote = ({ date }) => {
  const { language } = useContext(PreferredLanguageContext);
  const { patient } = useContext(PatientContext);

  const [isSaving, setIsSaving] = useState(false);
  const [show, setShow] = useState(false);

  const { register, handleSubmit, errors, reset, formState } = useForm({
    resolver: yupResolver(trackerSchema),
  });

  const onSubmit = (data) => {
    setIsSaving(true);
    data.date = date;
    postAdditionalNote({
      patientId: patient.patientId,
      data,
    })
      .then(() => {
        reset();
        setIsSaving(false);
        setShow(true);
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <h6 className="d-flex justify-content-between">
        <span>
          {patient.userPatientRelationshipType == 'self'
            ? TRACK_SYMPTOM[language].patient
            : TRACK_SYMPTOM[language].non_patient[0] + patient.firstName + TRACK_SYMPTOM[language].non_patient[1]
          }
        </span>
        <span>{format(date, "LLL d, yyyy")}</span>
      </h6>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {show && !formState.isDirty ? (
          <Alert
            variant="success"
            onClose={() => {
              setShow(false);
            }}
            dismissible
          >
            {ADDITIONAL_NOTE[language].report_saved}
          </Alert>
        ) : null}
        <Col>
          <LabelledInput
            required
            label={ADDITIONAL_NOTE[language].anything_else}
            name="describeSymptom"
            errors={errors}
            inputRef={register}
            inputAs="textarea"
            rows={3}
          />
        </Col>
        <SubmitButton type="submit" disabled={isSaving}>
          {ADDITIONAL_NOTE[language].save}
        </SubmitButton>
      </Form>
    </>
  );
};

export default AdditionalNote;
