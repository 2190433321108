import React, { useContext } from "react";
import { ThemeContext, PreferredLanguageContext } from "../lib/main-context";
import { DOUGHNUT_MEDICAL, NO_TRACKING_DATA, SYMPTOM_CATEGORY } from "../constants/DashboardTranslation";

import { useHistory } from 'react-router-dom';

import ReactECharts from 'echarts-for-react';

import theme from "../index.scss";

const DoughnutMedical = ({ data, days, userName, splitView }) => {
    const { dark } = useContext(ThemeContext);
    const { language } = useContext(PreferredLanguageContext);

    const history = useHistory();

    const medicalData = data?.filter(obj => obj.symptom_id !== 53)

    const subcategoryCount = medicalData?.reduce((acc, obj) => {
        const subcategory = obj.subcategory;
        acc[subcategory] = (acc[subcategory] || 0) + 1;
        return acc;
    }, {});

    const chartData = Object.keys(subcategoryCount).map(key => {
        return {
            value: subcategoryCount[key],
            name: key.charAt(0).toUpperCase() + key.slice(1)
        }
    });

    const handleChartClick = (params) => {
        history.push({
            pathname: '/MedicalDetailsChart',
            state: { medicalType: params.data.name, fromMedicalDetailsChart: true, days: days } // Pass parameters as state
        });
    };

    const onEvents = {
        'click': handleChartClick,
    }

    const option = {
        title: {
            text: DOUGHNUT_MEDICAL[language],
            subtext: chartData.length == 0 ? `${NO_TRACKING_DATA[language][0]}\n${NO_TRACKING_DATA[language][1]}` : `${splitView ? `(${userName})` : ""}`,
            subtextStyle: {
                fontSize: '1.1rem',
                color: dark ? theme.darkModeText : theme.primary,
            },
            left: 'center',
            // padding: [0, 0, 40, 0],// not really working for that distance
            textStyle: {
                fontSize: '1.2rem',
                color: dark ? theme.darkModeText : theme.black,
            }
        },
        grid: {
            top: 60, // Adjust the value to set the desired distance between title and chart
            // marginTop: 60, // Adjust the value to set the desired margin top for the chart
        },
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                const percentage = params.percent.toFixed(1);
                const category = SYMPTOM_CATEGORY[language][params.name]
                return `${category}: ${percentage}%`;
            }
        },
        grid: {
            top: '60px',
        },
        legend: {
            show: false,
            orient: 'vertical',
            left: 'left'
        },
        label: {
            show: true,
            fontSize: 16,
            color: dark ? theme.darkModeText : theme.black,
            // formatter: '{b}: {d}%'
            formatter: function (params) {
                const percentage = params.percent.toFixed(1);
                const category = SYMPTOM_CATEGORY[language][params.name]
                return `${category}: ${percentage}%`;
            }
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['30%', '50%'],
                data: chartData,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
            }
        ],
    };
    return (
        <ReactECharts option={option} onEvents={onEvents} style={{ minHeight: "25vh" }} />
    )
}
export default DoughnutMedical
