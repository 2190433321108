import React, { useState, useEffect } from "react";
import { getLanguagePref, updateLanguagePref } from "../api/TBIRequests";

export const ThemeContext = React.createContext({
  dark: false,
  toggleTheme: () => { },
});

export const PatientContext = React.createContext({
  patient: undefined,
});

export const InputVisibleContext = React.createContext({
  inputVisible: undefined,
});

export const SelectedPatientContext = React.createContext({
  selectedPatient: undefined,
  selectedPatientTopSymptoms: undefined,
  setSelectedPatient: () => { },
  setSelectedPatientTopSymptoms: () => { },
});

export const PreferredLanguageContext = React.createContext({
  language: 'english',
  updateLanguage: () => { },
})

export const PreferredLanguageProvider = ({ children, user }) => {
  const [language, setLanguage] = useState('english');

  useEffect(() => {
    const fetchLanguagePref = async () => {
      if (user) {
        try {
          const response = await getLanguagePref(user.userId);
          setLanguage(response.data.language_preference || "english");
        } catch (err) {
          console.error('Error fetching language preferences:', err);
        }
      }
    }
    fetchLanguagePref();
  }, [user])

  const updateLanguage = async (newLanguage) => {
    if (user) {
      try {
        await updateLanguagePref({
          user_id: user.userId,
          language_pref: newLanguage
        })
      } catch (err) {
        console.error('Error updating language preferences:', err);
      }
    }
    setLanguage(newLanguage);
  }

  return (
    <PreferredLanguageContext.Provider value={{ language, updateLanguage }}>
      {children}
    </PreferredLanguageContext.Provider>
  )
}