import React from "react";
import './MedicationHistory.scss'

import { Form, Col, Button } from "react-bootstrap";

const MedicationHistory = ({ medications, onAddMedication, onDeleteMedication, onChange, unsavedChanges, onSubmit }) => {
    return (
        <div className='medication-history-container'>
            <Form.Label>Update your medical history</Form.Label>

            {medications.map((med, index) => (
                <div className='medication-item' key={index}>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Medication Type</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder='Enter'
                                value={med.medication}
                                onChange={(e) => onChange(e, index, 'medication')}
                            />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Currently using?</Form.Label>
                            <Col>
                                <Form.Check
                                    inline
                                    label='Yes'
                                    type="radio"
                                    name={`curUsing-${index}`}
                                    value="yes"
                                    checked={med.curUsing === 'yes'}
                                    onChange={(e) => onChange(e, index, 'curUsing')}
                                />
                                <Form.Check
                                    inline
                                    label='No'
                                    type="radio"
                                    name={`curUsing-${index}`}
                                    value="no"
                                    checked={med.curUsing === 'no'}
                                    onChange={(e) => onChange(e, index, 'curUsing')}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>How well do you tolerate this medication?</Form.Label>
                            <Col>
                                <Form.Check
                                    type="radio"
                                    label='No Problems'
                                    name={`tolerance-${index}`}
                                    value="No Problems"
                                    checked={med.tolerance === "No Problems"}
                                    onChange={(e) => onChange(e, index, 'tolerance')}
                                />
                                <Form.Check
                                    type="radio"
                                    label='Experienced Side Effects'
                                    name={`tolerance-${index}`}
                                    value='Experienced Side Effects'
                                    checked={med.tolerance === 'Experienced Side Effects'}
                                    onChange={(e) => onChange(e, index, 'tolerance')}
                                />
                            </Col>
                        </Form.Group>
                        <Col xs="auto" className="align-self-center">
                            <Button variant="outline-danger" onClick={() => onDeleteMedication(index)}>
                                X
                            </Button>
                        </Col>
                    </Form.Row>
                </div>
            ))}

            {unsavedChanges &&
                <Form.Text className='mt-1 mb-1'>
                    Your changes are pending. Click 'Save' to confirm your updates.
                </Form.Text>
            }

            <Form.Row className='justify-content-between'>
                <Col xs="auto">
                    <Button variant="outline-primary" onClick={onAddMedication}>
                        +
                    </Button>
                </Col>
                {unsavedChanges &&
                    <Col xs="auto">
                        <Button variant="outline-primary" onClick={onSubmit}>
                            Save
                        </Button>
                    </Col>
                }
            </Form.Row>
        </div>
    )
}

export default MedicationHistory;