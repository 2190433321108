/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { useHistory, useLocation } from "react-router-dom";
import format from "date-fns/format";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Row, Col, Form } from "react-bootstrap";
import brain2 from '../../images/brain2.jpg';
import brain3 from '../../images/brain3.jpg';

import {
  getTBISymptoms,
  postRegisterFactors,
  postNewResultingFactors,
  getNonTbiDate,
  addSymptomDetails,
} from "../../api/TBIRequests";
import useListSelector from "../../lib/useListSelector";

import MedicalListSelector from "../MedicalListSelector";
import Sallie from "../Sallie";
import OnboardingStepHeader from "./OnboardingStepHeader";
import SubmitButton from "../StyledComponents/SubmitButton";
import hasDuplicatesAcrossCategories from "../../lib/hasDuplicatesAcrossCategories";
import customizeMessages from "../../lib/customizeMessages";

import {
  MEDICAL_SYMPTOMS,
  SALLIE_BRAIN,
  SYMPTOM_SELECTOR,
  SAVE_AND_CONTINUE,
  NONE,
  SYMPTOM_LIST
} from "../../constants/OnboardingTranslation";

const SelectLaterSymptoms = ({ setOnboardingPercent, user }) => {
  const { patient } = useContext(PatientContext);
  const [loading, setLoading] = useState(true);
  const [returnedData, setReturnedData] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [dateOfTBI, setDateOfTBI] = useState(undefined);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [headacheParts, setHeadacheParts] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const { language } = useContext(PreferredLanguageContext);

  const {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  // Maps to Step 4 in the DB, Steps 2 and 3 have been removed from the workflow
  const getPossibleMessages = ({ getPronoun, isOtherGender }) => ({
    mainHeader: {
      tbiPatient: MEDICAL_SYMPTOMS[language],
      caregiver: MEDICAL_SYMPTOMS[language],
    },
    sallieText: {
      tbiPatient: SALLIE_BRAIN[language].tbiPatient,
      caregiver: SALLIE_BRAIN[language].caregiver,
    },
    duplicatesText: {
      tbiPatient: SYMPTOM_SELECTOR[language].errors.duplicate,
      caregiver: SYMPTOM_SELECTOR[language].errors.duplicate,
    },
    noSymptomsText: {
      tbiPatient: SYMPTOM_SELECTOR[language].errors.patient,
      caregiver: SYMPTOM_SELECTOR[language].errors.caregiver,
    },
    selectHeadacheLocationText: {
      tbiPatient:
        SYMPTOM_SELECTOR[language].errors.headache_location,
      caregiver:
        SYMPTOM_SELECTOR[language].errors.headache_location,
    },
  });
  const getMessage = customizeMessages({ user, getPossibleMessages });

  const changeErrorMsg = (errorCase) => {
    const errorMsgList = {
      hasDuplicates: getMessage("duplicatesText"),
      noSymptom: getMessage("noSymptomsText"),
      needHeadAcheLocation: getMessage("selectHeadacheLocationText"),
    };
    setErrorMsg(errorMsgList[errorCase]);
    setShowErrorMsg(true);
  };

  const categories = [
    "cognitive",
    "sleep",
    "emotional",
    "physical",
    "speech",
    "vision",
  ];

  const processDataForSending = () => {
    return Object.entries(selectedData).flatMap(([category, itemList]) => {
      return itemList.map((item) => ({
        factor: item,
        category: "medical",
        subcategory: category,
        datetimeOfSymptom: new Date(),
        hadSymptom: true,
      }));
    });
  };

  const createItemListFromRes = (res) => {
    return categories.map((category) => {
      const items = res.data
        .filter((item) => item.subcategory === category)
        .map((item) => item.factor);
      return { category, items };
    });
  };

  useEffect(() => {
    location.state?.fromPatientControl
      ? setOnboardingPercent(40)
      : setOnboardingPercent(40);
    getTBISymptoms()
      .then((res) => {
        const itemList = createItemListFromRes(res);

        const translatedItemList = itemList.map(itemList => {
          const { category, items } = itemList;
          // Translate each item in the items array
          const translatedItems = items.map(item =>
            SYMPTOM_LIST[language][category][item]
          );
          return { category, items: translatedItems };
        });

        setReturnedData(translatedItemList);
        initialize(itemList);
        setLoading(false);
      })
      .catch(() => {
        history.push("/oops");
      });
    // if we include initialize in the deps we get an infinite loop
    // TODO: Extract logic of init into outer function to use dep arr correctly
  }, [language]);

  // clears selected data from a category if none is selected
  useEffect(() => {
    for (let category in selectedData) {
      if (selectedData[category].includes("None") || selectedData[category].includes("Ninguno")) {
        const selectedArrayWithoutNone = [...selectedData[category]]
        selectedArrayWithoutNone.splice(selectedArrayWithoutNone.indexOf(NONE[language]));
        selectedArrayWithoutNone.forEach((item) => {
          toggleFromSelectedList(item, selectedArrayWithoutNone, category);
        })
      }
    }
  }, [selectedData]);

  const handleSubmit = () => {
    let processedData = processDataForSending();
    // Remember Array.splice is in place, no need to assign anything
    Object.entries(selectedData).forEach(([category, itemList]) => {
      const noneIndex = itemList.indexOf(NONE[language]);
      if (noneIndex !== -1) {
        selectedData[category].splice(noneIndex);
      }
    })
    // if the user added the same symptom to multiple categories, stop them!
    if (processedData.length === 0) {
      changeErrorMsg("noSymptom");
      return;
    }
    // TODO maybe make this an in-place splice like you did with selectedData, using Array.find instead of indexOf?
    processedData = processedData.filter((item) => item.factor !== "None");
    if (hasDuplicatesAcrossCategories(processedData)) {
      changeErrorMsg("hasDuplicates");
      return;
    }
    setSubmitting(true);
    try {
      if (
        selectedData.vision.includes(
          SYMPTOM_LIST[language].vision["Headaches caused by close work: computers, reading, gaming"]
        )
      ) {
        if (headacheParts.length > 0) {
          addSymptomDetails({
            patientId: patient.patientId,
            data: {
              symptom:
                "Headaches caused by close work: computers, reading, gaming",
              detail: headacheParts,
            },
          });
        } else {
          setSubmitting(false);
          changeErrorMsg("needHeadAcheLocation");
          return;
        }
      }

      postRegisterFactors({
        patientId: patient.patientId,
        data: processedData,
      });

      postNewResultingFactors({
        patientId: patient.patientId,
        data: processedData,
      }).then(() => {
        {
          //add more symptoms,if there's no tracking factors
          location?.state?.fromPatientDashboard ?
            history.push("/selectTopSymptoms", {
              forwardData: { selectedData, processedData, dateOfTBI },
              fromPatientControl: location.state?.fromPatientControl,
              fromPatientDashboard: true,
            }) :
            history.push("/selectTopSymptoms", {
              forwardData: { selectedData, processedData, dateOfTBI },
              fromPatientControl: location.state?.fromPatientControl,
            })
        }
        ;
      });
    } catch (err) {
      console.log("some error in SelectLaterSymptoms handleSubmit");
      console.log(err);
      history.push("/oops");
    }
  };

  const messages = {
    cognitive: `${SYMPTOM_SELECTOR[language].category.cognitive}: `,
    sleep: `${SYMPTOM_SELECTOR[language].category.sleep}: `,
    physical: `${SYMPTOM_SELECTOR[language].category.physical}: `,
    emotional: `${SYMPTOM_SELECTOR[language].category.emotional}: `,
    speech: `${SYMPTOM_SELECTOR[language].category.speech}: `,
    vision: `${SYMPTOM_SELECTOR[language].category.vision}: `,
  };

  const tabs = {
    cognitive: SYMPTOM_SELECTOR[language].category.cognitive,
    sleep: SYMPTOM_SELECTOR[language].category.sleep,
    physical: SYMPTOM_SELECTOR[language].category.physical,
    emotional: SYMPTOM_SELECTOR[language].category.emotional,
    speech: SYMPTOM_SELECTOR[language].category.speech,
    vision: SYMPTOM_SELECTOR[language].category.vision,
  };

  const colors = {
    cognitive: "#dab7ec",
    sleep: "#ffdadb",
    physical: "#ffdcaf",
    emotional: "#fff3c4",
    speech: "#cef3c4",
    vision: "#c1fdf7",
  };
  const colorsSelected = {
    cognitive: "#dab7ec",
    sleep: "#ffdadb",
    physical: "#ffdcaf",
    emotional: "#fff3c4",
    speech: "#cef3c4",
    vision: "#c1fdf7",
  };


  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
        <>
          <Row className="justify-content-center">
            <Col
              xs={10}
              md={8}
            >
              <OnboardingStepHeader text={getMessage("mainHeader")} />
              <Sallie text={getMessage("sallieText")} style={{ textAlign: "left" }} />
              <Row>
                <Col sm={6}>
                  <img src={brain2} style={{ display: 'inlineBlock', height: "18rem", width: "100%", marginBottom: "2rem", marginTop: "2rem" }}></img>
                </Col>
                <Col sm={6}>
                  <img src={brain3} style={{ display: 'inlineBlock', height: "18rem", width: "100%", marginBottom: "2rem", marginTop: "2rem" }}></img>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-between" style={{ marginTop: "1rem" }}>
            <Col
              sm={8}
            >
              <h5 style={{ textAlign: "center" }}>{SYMPTOM_SELECTOR[language].category_header}</h5>
            </Col>
            <Col
              sm={4}
            >
              <h5 style={{ textAlign: "center" }} >{SYMPTOM_SELECTOR[language].list_header}</h5>
            </Col>
          </Row>
          <Tab.Container id="left-tabs-example" defaultActiveKey="cognitive">
            <Row style={{ marginTop: "0.5rem" }}>
              <Col sm={3} >
                <Nav fill justify variant="tabs" className="flex-column">
                  {returnedData.map((itemList, i) => (
                    <Nav.Item key={itemList.category} index={i}
                    >
                      <Nav.Link eventKey={itemList.category}
                        style={{
                          background: `${colors[itemList.category]}`,
                          border: '1px solid #106995',
                        }}> {tabs[itemList.category]}</Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={5}>
                <Tab.Content>
                  {returnedData.map((itemList, i) => (
                    <Tab.Pane key={itemList.category} eventKey={itemList.category}
                    >
                      <MedicalListSelector
                        key={itemList.category}
                        patient={patient}
                        category={itemList.category}
                        message={messages[itemList.category]}
                        list={itemList.items}
                        index={i}
                        selectedList={selectedData[itemList.category]}
                        toggleFromSelectedList={toggleFromSelectedList}
                        selectOne={switchSingleSelection}
                        addOther={addOther}
                        setHeadacheParts={setHeadacheParts}
                        headacheParts={headacheParts}
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
              <Col sm={4}>
                <div className="justify-content-left" style={{
                  height: "30rem",
                  overflow: 'scroll',
                  color: 'black',
                  border: '1px solid #106995',
                  textAlign: "left",
                  background: "#fff",
                  padding: "2rem",
                  borderRadius: "5px",
                  margin: ".25rem 0",
                }}>
                  {Object.entries(selectedData).flatMap(([category, itemList]) => {
                    return itemList.map((item) => (
                      <div style={{ margin: '0.5rem 0' }}>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                          <title>icon/action/check_ccircle_24px</title>
                          <defs>
                            <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z" id="path-1"></path>
                          </defs>
                          <g id="icon/action/check_ccircle_24px" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <mask id="mask-2" fill="white">
                              <use xlinkHref="#path-1"></use>
                            </mask>
                            <use id="-↳Color" fill={colorsSelected[category]} xlinkHref="#path-1"></use>
                          </g>
                        </svg>
                        {" "}
                        {item}
                      </div>));
                  })}
                </div>
              </Col>
            </Row>
          </Tab.Container>
          <Row>
            <Col>
              {showErrorMsg && (
                <Form.Control.Feedback
                  style={{ display: "block" }}
                  type="invalid"
                >
                  {errorMsg}
                </Form.Control.Feedback>
              )}
            </Col>
            <Col xs lg="4">
              <SubmitButton
                disabled={submitting}
                type="button"
                onClick={handleSubmit}
              >
                {SAVE_AND_CONTINUE[language]} &gt;
              </SubmitButton>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default SelectLaterSymptoms;
