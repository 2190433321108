import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

const LoadingSpinner = () => {
  return (
    <Spinner className="d-block" animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  )
}

export default LoadingSpinner
