/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { useHistory, useLocation } from "react-router-dom";
import { TOP_SYMPTOMS, SALLIE_TOP_SYMPTOMS, SAVE_AND_CONTINUE, SAVE_AND_GO_TO_DASHBOARD } from "../../constants/OnboardingTranslation";

import forIn from "lodash/forIn";

import { Row, Col, Form } from "react-bootstrap";
import Sallie from "../Sallie";
import ListSelector from "../ListSelector";
import useListSelector from "../../lib/useListSelector";
import OnboardingStepHeader from "./OnboardingStepHeader";
import SubmitButton from "../StyledComponents/SubmitButton";

import { postTrackedFactors, postWorstSymptomsFactors } from "../../api/TBIRequests";
import customizeMessages from "../../lib/customizeMessages";

const SelectTopSymptoms = ({ setOnboardingPercent, user }) => {
  const { patient } = useContext(PatientContext);
  const { language } = useContext(PreferredLanguageContext);

  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [noSymptom, setNoSymptom] = useState(false);
  const { forwardData } = location.state || { forwardData: undefined };

  const [returnedData, setReturnedData] = useState([]);

  const {
    toggleFromSelectedList,
    switchSingleSelection,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  if (!patient) {
    history.push("/oops");
  }

  const createItemList = () => {
    const items = [];
    forIn(forwardData.selectedData, (value) => {
      value.forEach((item) => items.push(item));
    });
    const itemList = [
      {
        category: "data",
        items,
        maxSelectAmount: "3",
      },
    ];
    return itemList;
  };

  const worstSymptomsForSending = () => {
    return forwardData.processedData
      .filter((item) =>
        Object.values(selectedData).flat().includes(item.factor)
      )
      .map((item) => ({
        factor: item.factor,
        category: item.category,
        subcategory: item.subcategory,
      }));
  };

  const allTrackingSymptomsForSending = () => {
    return forwardData.processedData
      // .filter((item) =>
      //   Object.values(selectedData).flat().includes(item.factor)
      // )
      .map((item) => ({
        factor: item.factor,
        category: item.category,
        subcategory: item.subcategory,
      }));
  };

  useEffect(() => {
    if (!forwardData) {
      // api call, right now user must do onboarding in one full swoop
    } else {
      const itemList = createItemList();
      initialize(itemList);
      setReturnedData(itemList);
    }
    setLoading(false);
    location.state?.fromPatientControl
      ? setOnboardingPercent(80)
      : setOnboardingPercent(50);
  }, [forwardData]);

  const handleSubmit = () => {
    setSubmitting(true);
    const worstSymptoms = worstSymptomsForSending();
    const allTrackingSymptoms = allTrackingSymptomsForSending()
    if (allTrackingSymptoms.length === 0) {
      setSubmitting(false);
      setNoSymptom(true);
      return;
    }

    postWorstSymptomsFactors({
      patientId: patient.patientId,
      data: worstSymptoms,
    })
      .catch((error) => {
        // Handle errors
        console.error("An error occurred while posting tracked factors:", error);
        history.push("/oops");
      });


    postTrackedFactors({
      patientId: patient.patientId,
      data: allTrackingSymptoms,
    })
      .then(() => {
        location.state?.fromPatientDashboard ?
          //add more symptoms,if there's no tracking factors
          history.push("/", { fromPatientDashboard: true }) :
          history.push("/selectSDOH", {
            fromPatientControl: location.state?.fromPatientControl,
          })
      }
      )
      .catch((error) => {
        // Handle errors
        console.error("An error occurred while posting tracked factors:", error);
        history.push("/oops");
      });
  };

  const getPossibleMessages = () => ({
    mainHeader: {
      Stroke: TOP_SYMPTOMS[language].header,
      tbiPatient: TOP_SYMPTOMS[language].header,
      caregiver: TOP_SYMPTOMS[language].header,
    },
    sallieText: {
      Stroke: SALLIE_TOP_SYMPTOMS[language],
      tbiPatient: SALLIE_TOP_SYMPTOMS[language],
      caregiver: SALLIE_TOP_SYMPTOMS[language],
    },
    // messageAfterList: {
    //   Stroke: "You can change your symptoms, or you can add more later.",
    //   tbiPatient: "You can change your symptoms, or you can add more later.",
    //   caregiver:
    //     "You can change the patient's symptoms, or you can add more later.",
    // },
    noSymptomsText: {
      Stroke: "Please select at least 1 symptom to track",
      tbiPatient: "Please select at least 1 symptom to track",
      caregiver: "Please select at least 1 symptom to track",
    },
    selectSymptomsCategory: {
      Stroke: TOP_SYMPTOMS[language].body,
      tbiPatient: TOP_SYMPTOMS[language].body,
      caregiver: TOP_SYMPTOMS[language].body,
    },
  });

  const getMessage = customizeMessages({ user, getPossibleMessages });

  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
        <>
          <OnboardingStepHeader text={getMessage("mainHeader")} />{" "}
          <Sallie text={getMessage("sallieText")} />
          {returnedData.map((itemList, i) => (
            <ListSelector
              key={itemList.category}
              category={itemList.category}
              message={getMessage("selectSymptomsCategory")}
              list={itemList.items}
              index={i}
              selectedList={selectedData[itemList.category]}
              toggleFromSelectedList={toggleFromSelectedList}
              selectOne={switchSingleSelection}
              maxSelectAmount={itemList.maxSelectAmount}
              noOther
              noSuffix
            />
          ))}
          {/* {getMessage("messageAfterList")} */}
          <Row>
            <Col>
              {noSymptom && (
                <Form.Control.Feedback
                  style={{ display: "block" }}
                  type="invalid"
                >
                  {getMessage("noSymptomsText")}
                </Form.Control.Feedback>
              )}
            </Col>
            <Col>
              {location.state?.fromPatientDashboard ?
                <SubmitButton
                  disabled={submitting}
                  type="button"
                  onClick={handleSubmit}
                >
                  {SAVE_AND_GO_TO_DASHBOARD[language]} &gt;
                </SubmitButton>
                :
                <SubmitButton
                  disabled={submitting}
                  type="button"
                  onClick={handleSubmit}
                >
                  {SAVE_AND_CONTINUE[language]} &gt;
                </SubmitButton>
              }
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default SelectTopSymptoms;
