import React from "react";
import StyledCheckbox from "../StyledCheckbox";
import './FactorSelector.scss'

const FactorSelector = ({
    factors,
    colors,
    selectedCategory,
    selectedFactors,
    onCategoryClick,
    onFactorSelect,
    isChecked,
    other,
    onOtherChange,
    onAddOther,
    onSubmit
}) => {
    return (
        <div className='factor-selector-container'>
            <div className='select-factors'>
                <h5 className='select-header'>Go through the each category below to see the list of factors</h5>
                <div className='select-factors-pane'>
                    <div className='category-pane'>
                        {Object.keys(factors).map((category) => (
                            <div
                                key={category}
                                className='category-item'
                                style={{ backgroundColor: colors[category] }}
                                onClick={() => onCategoryClick(category)}
                            >
                                {category}
                            </div>
                        ))}
                    </div>
                    <div className='factor-pane'>
                        {selectedCategory && (
                            <div>
                                <h6>{selectedCategory} factors: <span>(Choose all that apply)</span></h6>
                                <div className='factors-list'>
                                    {factors[selectedCategory].map((factor) => (
                                        <div key={factor} className="factor-item">
                                            <label className='custom-checkbox-container'>
                                                <input
                                                    type="checkbox"
                                                    value={factor}
                                                    onChange={() => onFactorSelect(factor, selectedCategory)}
                                                />
                                                <StyledCheckbox
                                                    category={selectedCategory}
                                                    colors={colors}
                                                    isChecked={isChecked(factor, selectedCategory)}
                                                />
                                                {factor}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <div className='other-container'>
                                    <p>Other:</p>
                                    <input
                                        className='other-input-field'
                                        type='text'
                                        value={other}
                                        onChange={onOtherChange}
                                        onKeyDown={onAddOther}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='selected-factors'>
                <h5 className='selected-header'>Your list of factors</h5>
                <div className='selection-pane'>
                    {selectedFactors.length > 0 && (
                        <div className='factors-list'>
                            {selectedFactors.map((item) => (
                                <div key={item.factor} className="factor-item">
                                    <label className='custom-checkbox-container'>
                                        <input
                                            type="checkbox"
                                            value={item.factor}
                                            onChange={() => onFactorSelect(item.factor, item.category)}
                                            checked
                                        />
                                        <StyledCheckbox
                                            category={item.category}
                                            colors={colors}
                                            isChecked={isChecked(item.factor, item.category)}
                                        />
                                        {item.factor}
                                    </label>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default FactorSelector;