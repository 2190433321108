/////////////////
// Messages.js //
/////////////////
export const MESSAGES_HEADER = {
    english: {
        header: 'Messages',
        dashboard: 'Go Back to Dashboard',
        send_messages: 'Send Messages',
        select_receiver: 'Select Receiver'
    },
    spanish: {
        header: 'Mensajes',
        dashboard: 'Volver al Panel',
        send_messages: 'Enviar Mensajes',
        select_receiver: 'Seleccionar Receptor'
    }
}

export const MESSAGES_TABLE = {
    english: {
        sent_or_received: {
            label: 'Sent or Received',
            sent: 'Sent',
            received: 'Received'
        },
        other_user: 'Other User',
        message: 'Message',
        time: 'Time',
        status: {
            label: 'Status',
            mark_as_read: 'Mark as read',
            have_read: 'Have read'
        }
    },
    spanish: {
        sent_or_received: {
            label: 'Enviado o Recibido',
            sent: 'Enviado',
            received: 'Recibido'
        },
        other_user: 'Otro Usuario',
        message: 'Mensaje',
        time: 'Hora',
        status: {
            label: 'Estado',
            mark_as_read: 'Marcar como leído',
            have_read: 'Han leído'
        }
    }
}

export const CLOSE = {
    english: 'Close',
    spanish: 'Cerca'
}

////////////////////
// SendMessage.js //
////////////////////
export const SEND_MESSAGES = {
    english: {
        label: 'You have a connected user and can send messages to them.',
        send_message_to: 'Send message to ',
        back_to_selection: 'Back to selection',
        send: 'Send',
        message_sent: 'A message has been sent to '
    },
    spanish: {
        label: 'Tienes un usuario conectado y puedes enviarle mensajes.',
        send_message_to: 'Enviar mensaje a ',
        back_to_selection: 'Volver a la selección',
        send: 'Enviar',
        message_sent: 'Se ha enviado un mensaje a '
    }
}