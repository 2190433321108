import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../../lib/main-context";

import theme from "../../index.scss";

import LoadingSpinner from "../../Components/LoadingSpinner";

import { updateReceivingPatientMessages, getReceivingPatientMessagesStatus } from "../../api/TBIRequests"

const OnOffButtonMessages = ({ onTitle, offTitle, onOffButtonMessagesStatus, setOnOffButtonMessagesStatus }) => {
    const { dark } = useContext(ThemeContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Set up a flag to track whether the component is still mounted.
        setLoading(true)
        let isComponentMounted = true;

        // Function to fetch receiving patient messages status
        const fetchMessagesStatus = async () => {
            try {
                const response = await getReceivingPatientMessagesStatus();
                if (isComponentMounted) {
                    // Only update state if the component is still mounted.
                    setOnOffButtonMessagesStatus(response.data.receive_patient_messages);
                    setLoading(false);
                }
            } catch (error) {
                if (isComponentMounted) {
                    console.error('Error fetching messages status:', error);
                    setLoading(false);
                }
            }
        };

        // Call the fetchMessagesStatus function
        fetchMessagesStatus();

        // Cleanup function to set isComponentMounted to false when unmounted
        return () => {
            isComponentMounted = false;
        };
    }, []); // Empty dependency array to run this effect only once

    const onClick = async () => {
        try {
            setLoading(true);
            const updatedStatus = !onOffButtonMessagesStatus;
            await updateReceivingPatientMessages({ updateReceivingPatientMessages: updatedStatus });
            window.location.reload();// have to reload user here, because message receivers need to be updated
        } catch (err) {
            console.log("updateReceivingPatientMessages err:", err);
            setLoading(false);
        }
    };

    return (
        <div className="d-flex justify-content-center pt-2 pb-2 pl-1 pr-1">
            <h6
                className="pt-2 pb-2 pl-3 pr-3"
                style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "20px",
                    backgroundColor: dark ? theme.darkModePrimary : (onOffButtonMessagesStatus ? "#3EB1A6" : "#ef5866"),
                    color: dark ? theme.darkModeText : "white",
                    textAlign: "center",
                    overflowWrap: "break-word",
                    cursor: "pointer"
                }}
                onClick={() => onClick()}
            >
                {onOffButtonMessagesStatus ? (
                    <div>
                        {loading ? <LoadingSpinner /> : onTitle}
                    </div>
                ) : (
                    <div>
                        {loading ? <LoadingSpinner /> : offTitle}
                    </div>
                )}

            </h6>
        </div>
    )
}

export default OnOffButtonMessages