import React, { useContext } from "react";
import { ThemeContext, PreferredLanguageContext } from "../lib/main-context";
import { DARK_MODE } from "../constants/DashboardTranslation";

import PillButton from "./StyledComponents/PillButton";

const ThemeChanger = () => {
  const { dark, toggleTheme } = useContext(ThemeContext);
  const { language } = useContext(PreferredLanguageContext);

  return (
    <div className="d-flex justify-content-end">
      <PillButton className="pillBtn" onClick={() => toggleTheme()}>
        {dark ? DARK_MODE[language].off : DARK_MODE[language].on}
      </PillButton>
    </div>
  );
};

export default ThemeChanger;
