import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import Sallie from "../Sallie";
import SubmitButton from "../StyledComponents/SubmitButton";
import LoadingSpinner from "../LoadingSpinner";
import SallieSVG from "../../images/sallie.svg";
import SadSallie from "../../images/Sallie-Oops.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
  checkEmailToken,
  getProviderTokenInformation,
  getInviterInfo,
  sendConfirmEmail,
  deleteEmailToken,
  creatConnection,
} from "../../api/helpReq";

const EmailConfirmForExistingProvider = ({ setOnboardingPercent, user }) => {
  const location = useLocation();
  const history = useHistory();
  const url = new URLSearchParams(location.search);
  const token = url.get("token");
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [userType, setUserType] = useState(null);
  const [patientEmail, setPatientEmail] = useState(null);
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setOnboardingPercent(10);
    if (token) {
      getInviterInfo({ token })
        .then((res) => {
          const { data } = res;
          setFirstName(data.first_name);
          setLastName(data.last_name);
          setUserType(data.user_type);
          setPatientEmail(data.patient_email);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setError(true); // Set the error state to true
          setLoading(false);
        });
    }
  }, []);

  const reSendEmail = () => {
    const { firstName, lastName, email } = user;
    sendConfirmEmail({
      email: email,
      name: firstName + " " + lastName,
    });
    history.push("/Verification/EmailSent");
  };

  const clickHandlerLogin = async () => {
    history.push("/");
  };

  const clickHandler = async () => {
    try {
      setSubmitting(true);
      creatConnection({ token })
        .then((res) => {
          setSubmitting(false);
          setSuccess(true);
        })
        .catch((err) => {
          console.error(err);
          history.push("/oops");
        });
    } catch (err) {
      setSubmitting(false);
      history.push("/oops");
    }
  };

  let sallieMsg = `Hi! A ${userType} named ${firstName} ${lastName} from email ${
    patientEmail
  } is requesting a connection. By connecting, you will have access to view your patient's reports & send secure messages. Click on the Confirm button now to set the connection!`;
  let errorMsg =
    "Sorry, we could not verify your email. Please click the button below to send the verification email again.";

  let img = SallieSVG;

  return (
    <>
      <div className="d-flex justify-content-center">
        <h4 className="mt-4 mb-4">Patient Connecting Confirmation</h4>
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <>
          <Sallie text={errorMsg} sad="true" />
          {/* <div className="error-message required" style={{ textAlign: 'right' }}>{errorMsg}</div> */}
          <SubmitButton disabled={submitting} onClick={reSendEmail}>
            Resend Now
          </SubmitButton>
        </>
      ) : (
        <>
          <Row>
            <Col
              md={2}
              style={{ minWidth: "125px" }}
              className="d-flex align-items-center justify-content-center"
            >
              <img
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                src={img}
                alt="Dog named Sallie"
              />
            </Col>
            <Col className="p-0" style={{ maxWidth: "70%" }}>
              <div className="sallie" style={{ fontSize: "1.3rem" }}>
                {sallieMsg}
              </div>
            </Col>
          </Row>
          {success === false ? (
            <SubmitButton
              style={{ marginRight: "13%", fontSize: "1.4rem" }}
              disabled={submitting}
              onClick={clickHandler}
            >
              Confirm
            </SubmitButton>
          ) : (
            <>
              <h5 className="d-flex justify-content-center">
                Connection set successfully!
              </h5>
              <SubmitButton
                style={{ marginRight: "13%", fontSize: "1.4rem" }}
                disabled={submitting}
                onClick={clickHandlerLogin}
              >
                Login
              </SubmitButton>
            </>
          )}
        </>
      )}
    </>
  );
};

export default EmailConfirmForExistingProvider;
