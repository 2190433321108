import React, { useContext } from "react"
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { SYMPTOM_SELECTOR } from "../../constants/OnboardingTranslation";
import { UNPAUSE } from "../../constants/DashboardTranslation";

import { Row, Col } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import styled from "styled-components"

import {
    addTrackedFactorsUnpaused,
    removePausedFactor,
} from "../../api/TBIRequests";

const Container = styled.div`
  // width: 280px;
  margin: 10px;
  h6 {
    text-align: center;
  }
  ul {
    font-weight: normal;
    font-size: 15px;
    margin: 10px;
  }
`
const UlPausedList = ({ symptoms, title, setPauseList, pausedFactorObj, setPausedFactorObj, setTopSymptoms, topSymptomsList }) => {
    const { patient } = useContext(PatientContext);
    const { language } = useContext(PreferredLanguageContext);

    const removePauseF = (factor) => {
        let newParsedFactors = [...symptoms];
        if (newParsedFactors) {
            if (newParsedFactors.includes(factor)) {
                const index = newParsedFactors.indexOf(factor);
                if (index > -1) { // only splice array when item is found
                    newParsedFactors.splice(index, 1); // 2nd parameter means remove one item only
                    setPauseList(newParsedFactors);
                }
            }
        }

        removePausedFactor({
            patientId: patient.patientId,
            data: [factor],
        })
            .catch(() => {
                console.log("removePausedFactor error");
            })
    }

    const messages = {
        cognitive: `${SYMPTOM_SELECTOR[language].category.cognitive}: `,
        sleep: `${SYMPTOM_SELECTOR[language].category.sleep}: `,
        physical: `${SYMPTOM_SELECTOR[language].category.physical}: `,
        emotional: `${SYMPTOM_SELECTOR[language].category.emotional}: `,
        speech: `${SYMPTOM_SELECTOR[language].category.speech}: `,
        vision: `${SYMPTOM_SELECTOR[language].category.vision}: `,
    };

    const addTracking = (factor) => {
        const objWithFactor = pausedFactorObj?.find((symptom) => symptom.factor === factor);
        const filteredFactorsList = pausedFactorObj?.filter(symptom => symptom.factor !== factor);
        setPausedFactorObj(filteredFactorsList)

        let newTopSymptoms = [...topSymptomsList];
        newTopSymptoms.push(objWithFactor);
        setTopSymptoms(newTopSymptoms);
        addTrackedFactorsUnpaused({
            patientId: patient.patientId,
            data: [[factor]],
        }).catch(() => {
            console.log("addTrackedFactorsUnpaused error");
        })
    }

    // Create a Set to store unique subcategories
    const uniqueSubcategories = new Set();

    // Filter and populate the Set with unique subcategories
    pausedFactorObj.forEach((symptom) => {
        uniqueSubcategories.add(symptom.subcategory);
    });

    // Convert the Set to an array (if needed)
    const uniqueSubcategoryArray = Array.from(uniqueSubcategories);

    const tabs = {
        cognitive: SYMPTOM_SELECTOR[language].category.cognitive,
        sleep: SYMPTOM_SELECTOR[language].category.sleep,
        physical: SYMPTOM_SELECTOR[language].category.physical,
        emotional: SYMPTOM_SELECTOR[language].category.emotional,
        speech: SYMPTOM_SELECTOR[language].category.speech,
        vision: SYMPTOM_SELECTOR[language].category.vision,
    };

    const colors = {
        cognitive: "#edebf3",
        sleep: "#ffdadb",
        physical: "#ffdcaf",
        emotional: "#fff3c4",
        speech: "#cef3c4",
        vision: "#c1fdf7",

        // for sdoh
        wellness: "#dab7ec",
        travel: "#ffdadb",
        "screen time": "#ffdcaf",
        environmental: "#fff3c4",
        Dietary: "#cef3c4",
        situational: "#c1fdf7",

        // for therapies
        Alternative: "#dab7ec",
        Applied: "#ffdadb",
        Chiropractic: "#ffdcaf",
        Vision: "#fff3c4",
        "Mental Health": "#c1fdf7",

        Other: "#f4f3ee",
    };

    return (
        <Container>
            <h6>{title}</h6>
            <Tab.Container id="left-tabs-example" defaultActiveKey="cognitive">
                <Row style={{ marginTop: "0.5rem", fontWeight: "normal", fontSize: "15px" }}>
                    <Col sm={3} style={{ paddingRight: '0px' }}>
                        <Nav fill justify variant="tabs" className="flex-column">
                            {uniqueSubcategoryArray.map((subcategory, i) => (
                                <Nav.Item key={i}
                                >
                                    <Nav.Link className="navLink" eventKey={subcategory}
                                        style={{
                                            background: `${colors[subcategory]}`,
                                            border: '1px solid #106995',
                                        }}> {tabs[subcategory]}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Col>

                    <Col sm={7} style={{
                        marginLeft: '1rem', paddingRight: '0px'
                    }}>
                        <Tab.Content >
                            {
                                uniqueSubcategoryArray.map((subcategory, i) => (
                                    <Tab.Pane eventKey={subcategory} key={i}>
                                        <h6 className="d-inline">{messages[subcategory]}</h6>
                                        <ul>
                                            {pausedFactorObj
                                                .filter((symptom) => symptom.subcategory === subcategory)
                                                .map((filteredSymptom, j) => (
                                                    <li key={j}>
                                                        {filteredSymptom.factor}{" "}
                                                        <button
                                                            onClick={() => {
                                                                removePauseF(filteredSymptom.factor)
                                                                addTracking(filteredSymptom.factor)
                                                            }}
                                                            style={{ background: '#d5f1c8' }}
                                                        >
                                                            {UNPAUSE[language]}
                                                        </button>
                                                    </li>
                                                ))}
                                        </ul>
                                    </Tab.Pane>
                                ))
                            }
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container >
        </Container >
    )
}

export default UlPausedList
