import { format } from "date-fns";

const getLoggedDataToday = (trackedData, category, date) => {
  return trackedData.filter((factor) => {
    if (
      factor.symptom_date &&
      factor.category === category &&
      factor.factor !== "Feeling Today"
    ) {
      let today = format(date, "yyyy-MM-dd");
      let dateOnly = factor.symptom_date.slice(0, 10);
      return today === dateOnly;
    }
    return false;
  });
};

export default getLoggedDataToday;
