import styled, { css } from 'styled-components'
import Button from 'react-bootstrap/Button'
// a rounded bootstrap button with extra margin

const PillButton = styled(Button)`
  border-radius: 20px;
  margin: 0.1rem ${props => (props.autoMargin ? 'auto' : '0.25rem')};
  background-color: ${props => (props.variant === 'gray' ? 'gray' : '#106995')};
`;



export default PillButton;
