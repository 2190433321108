import axios from "axios";
import apiUrl from "./apiConfig";

export const createCheckoutSession = (data) => {
    return axios.post(`${apiUrl}/createCheckoutSession`, data);
};

export const getPaymentRecord = (data) => {
    return axios.post(`${apiUrl}/getPaymentRecord`, data);
};

export const getPaymentStatus = (data) => {
    return axios.post(`${apiUrl}/getPaymentStatus`, data);
};

export const verifyLicenseKey = (data) => {
    return axios.post(`${apiUrl}/verifyLicenseKey`, data);
};