import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { PreferredLanguageContext } from '../../lib/main-context'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Sallie from '../Sallie'
import SubmitButton from '../StyledComponents/SubmitButton'

import futureView from '../../images/FutureView.jpg'

import {
  SALLIE_GREETING,
  INTRODUCTIONS,
  INTRO_STEPS,
  START_BUTTON_TEXT,
  FUTURE_DASHBOARD
} from '../../constants/OnboardingTranslation'

const Register = () => {

  const history = useHistory()
  const handleClick = () => {
    history.push('/BasicInfo')
  }

  const { language } = useContext(PreferredLanguageContext)

  return (
    <Row>
      <Col md={6} className="order-last order-md-first">
        <Sallie text={SALLIE_GREETING[language]} />
        <p>
          {INTRODUCTIONS[language].part_one}
        </p>
        <p>
          {INTRODUCTIONS[language].part_two}
        </p>

        <h6>{INTRO_STEPS[language].header}</h6>
        <p>{INTRO_STEPS[language].three_steps}</p>

        <ol style={{ listStylePosition: 'inside', paddingLeft: 0 }}>
          <li>{INTRO_STEPS[language].step_one}</li>
          <li>{INTRO_STEPS[language].step_two}</li>
          <li>{INTRO_STEPS[language].step_three}</li>
        </ol>
        <Row>
          <Col className="justify-content-center d-flex justify-content-md-start">
            <SubmitButton type="button" onClick={handleClick}>
              {START_BUTTON_TEXT[language]} &gt;
            </SubmitButton>
          </Col>
        </Row>
      </Col>
      <Col md={6} className="order-first order-md-last">
        <div>
          <h6 className="text-center">{FUTURE_DASHBOARD[language]}</h6>
          <img className="w-100" src={futureView} alt="future view" />
        </div>
      </Col>
    </Row>
  )
}

export default Register
