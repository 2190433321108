import React, { useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";

import { Modal, Form } from "react-bootstrap";
import SubmitButton from "../StyledComponents/SubmitButton";

import exportToPdf from "../../lib/exportToPdf";
import exportToPdfReport from "../../lib/exportToPdfReport";
import { EXPORT_REPORT } from "../../constants/DashboardTranslation";

const ModalPdf = ({
  isReport,
  show,
  handleClose,
  chart,
  data,
  anotherDataForComparasion,
  additionalNotes,
  days,
}) => {
  const [withoutNote, setWithoutNote] = useState(false);
  const { patient } = useContext(PatientContext);
  const { language } = useContext(PreferredLanguageContext);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{EXPORT_REPORT[language].header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {`${EXPORT_REPORT[language].body[0]} ${days} ${EXPORT_REPORT[language].body[1]}`}
        </p>
        <Form.Check
          name="withoutNote"
          type="checkbox"
          label={EXPORT_REPORT[language].exclude_symptoms}
          checked={withoutNote}
          style={{ fontWeight: "400", fontSize: "small" }}
          onChange={() => setWithoutNote(!withoutNote)}
        />
      </Modal.Body>
      <Modal.Footer>
        <SubmitButton
          onClick={() => {
            isReport ? exportToPdfReport(
              chart,
              data,
              days,
              anotherDataForComparasion,
              additionalNotes,
              patient,
              withoutNote
            ) :
              exportToPdf(
                chart,
                data,
                days,
                additionalNotes,
                patient,
                withoutNote
              );
            handleClose();
          }}
        >
          {EXPORT_REPORT[language].button}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPdf;
