import React, { useState, useContext } from 'react';
import { PreferredLanguageContext } from '../../lib/main-context';
import { LICENSE } from "../../constants/OnboardingTranslation"

import SubmitButton from "../StyledComponents/SubmitButton"
import { Col, Row } from "react-bootstrap";
import { verifyLicenseKey } from '../../api/paymentRequest';

const LicenseKeyInput = ({ user, setVerified, setLicenseEndDate }) => {
    const { language } = useContext(PreferredLanguageContext);
    const [licenseKey, setLicenseKey] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const handleInputChange = (event) => {
        setLicenseKey(event.target.value);
    };

    const handleVerifyKey = async () => {
        try {
            setLoading(true); // Set loading to true before making the API call

            const response = await verifyLicenseKey({ userId: user.userId, licenseKey });

            if (response.status === 200) {
                setVerified(true);
            }

            if (response.data?.endDate) {
                setLicenseEndDate(response.data?.endDate);
            }

            setMessage(response.data.message);
        } catch (error) {
            setMessage('Error verifying license key.');
        } finally {
            setLoading(false); // Set loading back to false regardless of success or failure
        }
    };

    return (
        <div className='mt-4'>
            <h4>{LICENSE[language].label}</h4>
            <h6>{LICENSE[language].info}</h6>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col sm={10}>
                    <input
                        type="text"
                        value={licenseKey}
                        onChange={handleInputChange}
                        placeholder={LICENSE[language].enter}
                        style={{ width: '100%', padding: '0.5rem' }}
                    />
                </Col>
                <Col sm={2}>
                    <SubmitButton centered={true} disabled={loading} onClick={handleVerifyKey}>
                        {LICENSE[language].verify}
                    </SubmitButton>
                </Col>
            </Row>
            <p>{message}</p>
        </div>
    );
};

export default LicenseKeyInput;
