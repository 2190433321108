import styled from "styled-components";
import theme from "../../variables.scss";

const SmLinkButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color: ${theme.teal};
  font-weight: bold;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export default SmLinkButton;
