import styled from 'styled-components'
import theme from '../../variables.scss'
// a button styled as a link -- used over actual anchor tag for when
// we don't want to change the route for accessibility reasons

const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color: ${theme.teal};

  &:hover,
  &:focus {
    text-decoration: none;
  }
`

export default LinkButton
