import * as yup from 'yup';

const followUpFormSchema = yup.object().shape({
    changeMedication:
        yup.string().nullable().required("This question is required."),
    lessMedication:
        yup.string().nullable().required("This question is required."),
    medicineChangeDetails:
        yup.string().when("lessMedication", {
            is: "yes",
            then: yup.string().required("This question is required."),
        }),
    overallProgramPercentage:
        yup.number()
            .min(0, 'Minimum value is 0')
            .max(100, 'Maximum value is 100')
            .required('This question is required.'),
    primitiveReflex:
        yup.string().nullable().required("This question is required."),
    primitiveReflexDaily:
        yup.number().when("primitiveReflex", {
            is: "yes",
            then: yup
                .number('')
                .nullable()
                .typeError("Please fill in the blank with a valid number.")
                .required("This question is required."),
        }),
    primitiveReflexWeekly:
        yup.number().when("primitiveReflex", {
            is: "yes",
            then: yup.number().nullable().required("This question is required."),
        }),
    hemisphericSensory:
        yup.string().nullable().required("This question is required."),
    hemisphericSensorySpecification:
        yup.array()
            .of(
                yup.string()
                    .notOneOf([''], 'Please fill in the blank.')
            )
            .when('hemisphericSensory', {
                is: "yes",
                then: schema => schema.required('This question is required.'),
            }),
    hemisphericSensoryDaily:
        yup.number().when("hemisphericSensory", {
            is: "yes",
            then: yup
                .number('')
                .nullable()
                .typeError("Please fill in the blank with a valid number.")
                .required("This question is required."),
        }),
    hemisphericSensoryWeekly:
        yup.number().when("hemisphericSensory", {
            is: "yes",
            then: yup.number().nullable().required("This question is required."),
        }),
    specialDiet:
        yup.string().nullable().required("This question is required."),
    describeSpecialDiet:
        yup.string().when("specialDiet", {
            is: "yes",
            then: yup.string().required("This question is required."),
        }),
    vitamins:
        yup.string().nullable().required("This question is required."),
    functionLoss:
        yup.string().nullable().required("This question is required."),
    describeFunctionLoss:
        yup.string().when("functionLoss", {
            is: "yes",
            then: yup.string().required("This question is required."),
        }),
    skills:
        yup.string().nullable().required("This question is required."),
    describeSkills:
        yup.string().when("skills", {
            is: "yes",
            then: yup.string().required("This question is required."),
        }),
    nonVerbalCommunication:
        yup.number()
            .min(0, 'Minimum value is 0')
            .max(100, 'Maximum value is 100')
            .required('This question is required.'),
    verbalCommunication:
        yup.number()
            .min(0, 'Minimum value is 0')
            .max(100, 'Maximum value is 100')
            .required('This question is required.'),
    academicSkills:
        yup.number()
            .min(0, 'Minimum value is 0')
            .max(100, 'Maximum value is 100')
            .required('This question is required.'),
    behavior:
        yup.number()
            .min(0, 'Minimum value is 0')
            .max(100, 'Maximum value is 100')
            .required('This question is required.'),
    physicalChange:
        yup.number()
            .min(0, 'Minimum value is 0')
            .max(100, 'Maximum value is 100')
            .required('This question is required.'),
    overallProgress:
        yup.number()
            .min(0, 'Minimum value is 0')
            .max(100, 'Maximum value is 100')
            .required('This question is required.'),
    additionalComments:
        yup.string(),
    followUpAppointment:
        yup.string().nullable().required("This question is required."),
});

export default followUpFormSchema;