import React, { useContext } from 'react'
import { PreferredLanguageContext } from '../lib/main-context'
import Form from 'react-bootstrap/Form'
import capitalize from '../lib/capitalize'
// labelled input is a component meant to streamline creating simple multi-input forms
// it is meant to be used with the useForm hook on the outer form, passing
// errors,name, and inputRef as bare minimum arguments

const LabelledInput = ({
  name,
  placeholder,
  label,
  inputRef,
  type,
  defaultValue,
  errors,
  style,
  labelAs,
  inputAs,
  autoComplete,
  extraInfo,
  autoFocus,
  required,
  onChange,
  ...rest
}) => {
  const { language } = useContext(PreferredLanguageContext);
  return (
    <Form.Group style={style} controlId={name}>
      <Form.Label className={labelAs === 'h6' ? 'h6-label' : ''}>
        {label || capitalize(name)}
        {required && <span className="required">*</span>}
      </Form.Label>
      {extraInfo && (
        <div className="mb-2">
          <small>{extraInfo}</small>
        </div>
      )}
      <Form.Control
        autoFocus={autoFocus}
        isInvalid={errors[name]}
        name={name}
        placeholder={placeholder}
        type={type || 'text'}
        ref={inputRef}
        autoComplete={autoComplete || name}
        defaultValue={defaultValue || ''}
        as={inputAs}
        onChange={onChange}
        {...rest}
      />

      {language !== 'spanish' &&
        <Form.Control.Feedback type="invalid">
          {errors[name] && errors[name].message}
        </Form.Control.Feedback>
      }
    </Form.Group>
  )
}

export default LabelledInput
