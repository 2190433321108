import { update } from "lodash";
import React, { useState, useContext, useEffect } from "react";
import theme from "../../index.scss";
import { ThemeContext } from "../../lib/main-context";
import { updateReceivingPatientAlert, getReceivingPatientAlertStatus } from "../../api/TBIRequests"
import LoadingSpinner from "../../Components/LoadingSpinner";

const OnOffButtonAlert = ({ onTitle, offTitle, onColor, offColor }) => {
    const { dark } = useContext(ThemeContext);
    const [status, setStatus] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Set up a flag to track whether the component is still mounted.
        setLoading(true)
        let isComponentMounted = true;

        // Function to fetch receiving patient alert status
        const fetchAlertStatus = async () => {
            try {
                const response = await getReceivingPatientAlertStatus();
                if (isComponentMounted) {
                    // Only update state if the component is still mounted.
                    setStatus(response.data.receive_increase_alert);
                    setLoading(false);
                }
            } catch (error) {
                if (isComponentMounted) {
                    console.error('Error fetching alert status:', error);
                    setLoading(false);
                }
            }
        };
        // Call the fetchAlertStatus function
        fetchAlertStatus();
        // Cleanup function to set isComponentMounted to false when unmounted
        return () => {
            isComponentMounted = false;
        };
    }, []); // Empty dependency array to run this effect only once


    const onClick = () => {
        setLoading(true)
        updateReceivingPatientAlert({ updateReceivingPatientAlert: !status })
            .then(() => {
                setStatus(!status)
                setLoading(false)
            }).catch((err) => {
                console.log("updateReceivingPatientAlert err");
                setLoading(false)
            })
    }
    return (
        <div className="d-flex justify-content-center pt-2 pb-2 pl-2 pr-2">
            <h6
                className="pt-2 pb-2 pl-3 pr-3"
                style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "20px",
                    backgroundColor: dark ? theme.darkModePrimary : (status ? "#3EB1A6" : "#ef5866"),
                    color: dark ? theme.darkModeText : "white",
                    textAlign: "center",
                    overflowWrap: "break-word",
                    cursor: "pointer"
                }}
                onClick={() => onClick()}
            >
                {status ? (
                    <div>
                        {loading ? <LoadingSpinner /> : onTitle}
                    </div>
                ) : (
                    <div>
                        {loading ? <LoadingSpinner /> : offTitle}
                    </div>
                )}

            </h6>
        </div>
    )
}

export default OnOffButtonAlert