import React, { useContext } from "react";
import { ThemeContext, PreferredLanguageContext } from "../../lib/main-context";
import { REFER_PATIENT } from "../../constants/DashboardTranslation";

import { useHistory } from "react-router-dom";

import theme from "../../index.scss";

const ReferPatientButton = ({ userType }) => {
    const history = useHistory();
    const { dark } = useContext(ThemeContext);
    const { language } = useContext(PreferredLanguageContext);
    return (
        <div className="d-flex justify-content-center">
            <h6
                className="pt-2 pb-2 pl-3 pr-3"
                style={{
                    width: "75%",
                    borderRadius: "20px",
                    backgroundColor: dark ? theme.darkModePrimary : "#f7bc12",
                    color: dark ? theme.darkModeText : "white",
                    textAlign: "center",
                    overflowWrap: "break-word",
                    cursor: "pointer"
                }}
                onClick={() => history.push("/ReferPatientSelection")}
            >
                {REFER_PATIENT[language]}
            </h6>
        </div>
    );
};

export default ReferPatientButton;
