/////////////////
// HelpPage.js //
/////////////////

export const SALLIE_HELP = {
    english: {
        default: "Please enter contact info and a description of the problem you're having below OR Tell us what you would like added.",
        error: "We weren't able to process your request. If you would still like to request assistance you may email help@powerofpatients.com.",
        submitted: 'Successfully submitted your ticket! Please give up to two business days for a reply.'
    },
    spanish: {
        default: "Por favor ingrese su información de contacto y una descripción del problema que esta enfrentando o díganos lo que quisiera añadir. ",
        error: "No pudimos procesar su solicitud. Si aún desea solicitar asistencia, puede enviar un correo electrónico a help@powerofpatients.com.",
        submitted: '¡Ticket enviado con éxito! Por favor, espere hasta dos días hábiles para recibir una respuesta.'
    }
}

export const HELP_PAGE = {
    english: {
        email: 'Email',
        name: 'Name',
        textarea_label: 'What do you need help with?',
        textarea_info: 'Please include as much information as possible, including your Operating System and Browser',
        go_back: 'Go Back',
        submit: 'Submit'
    },
    spanish: {
        email: 'Correo electrónico',
        name: 'Nombre',
        textarea_label: '¿Cómo le podemos ayudar?',
        textarea_info: 'Favor de incluir toda la información que pueda, incluyendo su Sistema de Operación y Navegador.',
        go_back: 'Ir hacia atrás',
        submit: 'Someter'
    }
}

/////////////
// Oops.js //
/////////////
export const OOPS = {
    english: {
        header: 'Oops!',
        subheading: 'Something went wrong on our end.',
        body: 'Sorry this page did not load correctly. Go back to the previous page or if the problem continues, please submit a request for help. If you encountered this while registering, it may be a result of a session interruption, please reinitiate the registration process.',
        request_help: 'Request Help'
    },
    spanish: {
        header: '¡Ups!',
        subheading: 'Algo salió mal de nuestro lado.',
        body: 'Lo sentimos, esta página no se cargó correctamente. Vuelve a la página anterior o si el problema continúa, por favor envía una solicitud de ayuda. Si encontraste este error al registrarte, puede deberse a una interrupción de la sesión; por favor, reinicia el proceso de registro.',
        request_help: 'Solicitud de Ayuda.'
    }
}