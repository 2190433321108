import React, { useContext } from "react";
import { ThemeContext, PreferredLanguageContext } from "../lib/main-context";
import { SDOH_CATEGORY, CHARTS, GO_BACK } from "../constants/DashboardTranslation";

import { useHistory, useLocation } from "react-router-dom";

import { parse } from "date-fns";

import theme from "../index.scss";
import ComponentWrapper from "./DashboardComponents/ComponentWrapper";
import SubmitButton from "./StyledComponents/SubmitButton";

import getDaysAgo from "../lib/getDaysAgo";

import ReactECharts from 'echarts-for-react';

const SdohDetailsChart = ({ error, topSymptoms, data, retry }) => {
    const { dark } = useContext(ThemeContext);
    const { language } = useContext(PreferredLanguageContext);

    const history = useHistory();
    const location = useLocation();

    // Access the parameters from location.state if available,
    // otherwise provide default values
    const sdohType = location.state?.sdohType;
    const fromSdohDetailsChart = location.state?.fromSdohDetailsChart || false;
    const days = location.state?.days || 7;

    const filteredData = data?.filter(obj => obj.category == "SDOH" && (obj.subcategory == `${sdohType.toLowerCase()}` || obj.subcategory == `${sdohType}`)).filter(obj => parse(obj.symptom_date.slice(0, 10), "yyyy-MM-dd", new Date()) > getDaysAgo(days));
    const count = {};
    filteredData.forEach((item) => {
        const element = item.factor;
        count[element] = (count[element] || 0) + 1;
    });
    const result = Object.entries(count).map(([name, value]) => ({ name, value }));
    const sorted = result.sort((a, b) => b.value - a.value);

    const option = {
        title: {
            text: `${SDOH_CATEGORY[language][sdohType]} Details`,
            left: 'center',
            textStyle: {
                fontSize: 24,
                color: dark ? theme.darkModeText : theme.black,
            },
        },
        tooltip: {
            trigger: 'item',

        },
        xAxis: {
            type: 'value',
            axisLabel: {
                fontSize: 16, // Change the font size here
                color: dark ? theme.darkModeText : theme.black,
            },
        },
        grid: {
            left: '5%',   // Adjust the left padding here
            containLabel: true
        },
        yAxis: {
            type: 'category',
            axisLabel: {
                fontSize: 16, // Change the font size here
                color: dark ? theme.darkModeText : theme.black,
            },
            data: sorted.map(item => item.name).reverse() // reverse to show top elements at top
        },
        series: [
            {
                data: sorted.map(item => item.value).reverse(),
                type: 'bar'
            }
        ]
    };
    return (
        <>
            {error && (
                <ComponentWrapper>
                    {CHARTS[language].error[0]}
                    <SubmitButton onClick={retry}>{CHARTS[language].error[1]}</SubmitButton>
                </ComponentWrapper>
            )}

            <ComponentWrapper >
                <ReactECharts option={option} style={{ minHeight: "40vh" }} />
            </ComponentWrapper>

            <SubmitButton onClick={
                !fromSdohDetailsChart ?
                    () => history.push("/") : () => history.push({ pathname: "/charts", state: { days: days } })
            }>{GO_BACK[language]}</SubmitButton>
        </>

    )

};

export default SdohDetailsChart;
