import React, { useContext } from "react";
import { PreferredLanguageContext } from "../lib/main-context";
import { NOTIF_PREFS } from "../constants/OnboardingTranslation";

import { Col, Row, Form } from "react-bootstrap";

const TimeInput = ({ label, register, errors, className, disableStatus, checked }) => {
  const { language } = useContext(PreferredLanguageContext);
  return (
    <Row className={className} style={{ padding: "0px 15px 15px 15px" }}>
      <Form.Label
        as="h6"
        style={{ width: "100%", margin: "10px 0px 20px 0px" }}
      >
        {label}
      </Form.Label>
      <Form.Group controlId="dayOfWeek">
        {[
          { label: NOTIF_PREFS[language].day_of_week.mon, name: "monday" },
          { label: NOTIF_PREFS[language].day_of_week.tue, name: "tuesday" },
          { label: NOTIF_PREFS[language].day_of_week.wed, name: "wednesday" },
          { label: NOTIF_PREFS[language].day_of_week.thu, name: "thursday" },
          { label: NOTIF_PREFS[language].day_of_week.fri, name: "friday" },
          { label: NOTIF_PREFS[language].day_of_week.sat, name: "saturday" },
          { label: NOTIF_PREFS[language].day_of_week.sun, name: "sunday" },
        ].map((day) => (
          <Form.Check
            inline
            label={day.label}
            type="checkbox"
            name={day.name}
            id={day.name}
            ref={register}
            defaultChecked={checked}
            disabled={disableStatus}
            key={day.name}
            style={{ margin: "0 0.5rem" }}
          />
        ))}
        {errors.dayofweek && (
          <Form.Control.Feedback style={{ display: "block" }} type="invalid">
            {errors.dayofweek.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Row style={{ width: "100%" }}>
        <Col xs={5} md={4}>
          <Form.Control
            placeholder="HH"
            type="number"
            ref={register}
            name="hour"
            aria-label="hour"
            isInvalid={errors.hour}
            disabled={disableStatus}
          />
        </Col>
        <Col xs={5} md={4}>
          <Form.Control
            as="select"
            aria-label="partOfDay"
            ref={register}
            name="partOfDay"
            isInvalid={errors.partOfDay}
            disabled={disableStatus}
          >
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </Form.Control>
        </Col>
      </Row>
      {errors.hour && (
        <Form.Control.Feedback
          // display set to block to override display:none
          style={{ display: "block" }}
          type="invalid"
        >
          {errors.hour.message}
        </Form.Control.Feedback>
      )}
    </Row>
  );
};

export default TimeInput;
