///////////////
// Header.js //
//////////////
export const NAVBAR = {
    english: {
        register: 'Register',
        signin: 'Sign In',
        logout: 'Log Out',
        home: 'Home',
        media: 'Media',
        report: 'Report',
        case_report: 'Case Report',
        account_settings: 'Account Settings',
        membership_payment: 'Membership Payment',
        help: 'Help'
    },
    spanish: {
        register: 'Regístrese',
        signin: 'Iniciar sesión',
        logout: 'Cerrar sesión',
        home: 'Inicio',
        media: 'Medios',
        report: 'Informe',
        case_report: 'Reporte del caso',
        account_settings: 'Configuraciones de la cuenta',
        membership_payment: 'Pago de membresía',
        help: 'Ayuda'
    }
}

///////////////////////////
// OnboardingProgress.js //
///////////////////////////
export const CREATE_ACCOUNT = {
    english: 'Create your account',
    spanish: 'Crear su cuenta'
}

export const PROGRESS = {
    english: 'Progress',
    spanish: 'Progreso'
}