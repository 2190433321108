import React, { useEffect, useContext } from "react";
import { ThemeContext, PreferredLanguageContext } from "../../lib/main-context";
import { REFERRAL_RECORD_HEADER, REFERRAL_RECORD_TABLE } from "../../constants/DashboardTranslation";

import { useHistory } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import theme from "../../index.scss";
import ComponentWrapper from "../DashboardComponents/ComponentWrapper";
import SubmitButton from "../StyledComponents/SubmitButton";

import useWindowSize from "../../lib/useWindowSize";

import { getReferralRecord, createReferralConnection } from "../../api/referralRequest";

const PatientReferralRecord = ({ user, setReferralInfo, referralInfo }) => {
    const { dark } = useContext(ThemeContext);
    const { language } = useContext(PreferredLanguageContext);

    const history = useHistory();
    const [width] = useWindowSize();

    const fetchReferralRecord = async () => {
        try {
            const record = await getReferralRecord();
            setReferralInfo(record.data);
        } catch (error) {
            console.log("getReferralRecord err", error);
        }
    };

    useEffect(() => {
        fetchReferralRecord();
    }, [language]);

    const handleAccept = async (row) => {
        console.log("row", row)
        try {
            await createReferralConnection({ row, user });
            // Update the has_been_accepted value in the referral record object or fetch the updated record again
            await fetchReferralRecord();
        } catch (error) {
            console.log("handleAccept err", error);
        }
    };

    const rowStyle2 = (row, rowIndex) => {
        const style = {};
        style.overflowWrap = "break-word";
        if (row.has_been_accepted) {
            style.backgroundColor = '#FFFFCC';
        } else {
            style.backgroundColor = '#cfe8e6';
        }
        return style;
    };

    const columns = [
        {
            dataField: 'patient_full_name',
            text: REFERRAL_RECORD_TABLE[language].patient_name,
            formatter: (cell, row) => {
                return row.patient_first_name + ' ' + row.patient_last_name;
            }
        },

        {
            dataField: 'sender_full_name',
            text: REFERRAL_RECORD_TABLE[language].sender_name,
            formatter: (cell, row) => {
                return row.sender_first_name + ' ' + row.sender_last_name;
            }
        },

        {
            dataField: 'recipient_full_name',
            text: REFERRAL_RECORD_TABLE[language].recipient_name,
            formatter: (cell, row) => {
                return row.recipient_first_name + ' ' + row.recipient_last_name;
            }
        },

        {
            dataField: 'date',
            text: REFERRAL_RECORD_TABLE[language].time
        },
        {
            dataField: 'has_been_accepted',
            text: REFERRAL_RECORD_TABLE[language].status.label,
            formatter: (has_been_accepted, row) => {
                if (has_been_accepted) {
                    return REFERRAL_RECORD_TABLE[language].status.have_accepted
                } else {
                    if (user.userId === row.recipient_id) {
                        return (
                            <SubmitButton centered onClick={() => handleAccept(row)}>
                                {REFERRAL_RECORD_TABLE[language].status.accept}
                            </SubmitButton>
                        );
                    } else {
                        return REFERRAL_RECORD_TABLE[language].status.waiting;
                    }
                }
            }
        }
    ];

    return (
        <ComponentWrapper style={{ padding: "60px" }}>
            <h1 className="text-center">{REFERRAL_RECORD_HEADER[language].header}</h1>
            <Row>
                <Col className={`d-flex flex-wrap align-items-end ${width >= 768 ? "justify-content-start" : "justify-content-center"}`}>
                    <h6
                        className="pt-2 pb-2 mb-4"
                        style={{
                            width: "90%",
                            borderRadius: "20px",
                            backgroundColor: dark ? theme.darkModePrimary : "#3EB1A6",
                            color: dark ? theme.darkModeText : "white",
                            textAlign: "center",
                            overflowWrap: "break-word",
                            cursor: "pointer"
                        }}
                        onClick={() => history.push("/")}
                    >
                        {REFERRAL_RECORD_HEADER[language].back_to_dashboard}
                    </h6>
                </Col>
                <Col className={`d-flex flex-wrap align-items-end ${width >= 768 ? "justify-content-start" : "justify-content-center"}`}>
                    <h6
                        className="pt-2 pb-2 mb-4"
                        style={{
                            width: "90%",
                            borderRadius: "20px",
                            backgroundColor: dark ? theme.darkModePrimary : "#3EB1A6",
                            color: dark ? theme.darkModeText : "white",
                            textAlign: "center",
                            overflowWrap: "break-word",
                            cursor: "pointer",
                        }}
                        onClick={() => history.push("/ReferPatientSelection")}
                    >
                        {REFERRAL_RECORD_HEADER[language].refer_patient}
                    </h6>
                </Col>
            </Row>
            {referralInfo && <BootstrapTable class="overflow-hidden" keyField='messageId' data={referralInfo} columns={columns} pagination={paginationFactory()} rowStyle={rowStyle2} />}
        </ComponentWrapper>
    )
}

export default PatientReferralRecord;