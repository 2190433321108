import React, { useContext } from "react";
import { PreferredLanguageContext } from "../lib/main-context";
import { NO_TRACKING_DATA, OVERALL_FEELINGS } from "../constants/DashboardTranslation";

import FeelingGood from "../images/feeling-good3.svg";
import FeelingNeutral from "../images/feeling-neutral3.svg";
import FeelingBad from "../images/feeling-bad3.svg";

const FeelingBar = ({ data, userName, splitView }) => {
    const { language } = useContext(PreferredLanguageContext);
    const feelingData = data?.filter(obj => obj.symptom_id == 53);
    const result = [
        { name: "Sad", value: 0 },
        { name: "Neutral", value: 0 },
        { name: "Happy", value: 0 }
    ];

    feelingData.forEach(item => {
        switch (item.severity) {
            case 10:
                result[0].value++;
                break;
            case 50:
                result[1].value++;
                break;
            case 100:
                result[2].value++;
                break;
        }
    });

    result.forEach(item => {
        item.value = ((item.value / feelingData.length) * 100).toFixed(1);
    });


    const imgStyle1 = {
        width: "80%", height: "6vh", backgroundColor: "#6c5082", padding: "0.5rem", fontSize: "1.5rem", borderRadius: "5rem", margin: "1rem 0",
    };
    const imgStyle2 = {
        width: "80%", height: "6vh", backgroundColor: "#31366b", padding: "0.5rem", fontSize: "1.5rem", borderRadius: "5rem", margin: "1rem 0",
    };
    const imgStyle3 = {
        width: "80%", height: "6vh", backgroundColor: "#2a6796", padding: "0.5rem", fontSize: "1.5rem", borderRadius: "5rem", margin: "1rem 0",
    };
    const fontStyle = {
        color: "white",
        textAlign: "center",
        paddingLeft: "1.5rem",
        fontSize: '1.2rem'
    }
    return (
        <div>
            <h3 style={{ fontSize: '1.2rem', fontStyle: "bold" }}>
                {OVERALL_FEELINGS[language]}
                {splitView ? (
                    // style={{ color: '#006a93' }}
                    <span >{` (${userName})`}</span>
                ) : (
                    ''
                )}
            </h3>

            {feelingData.length == 0 ? <h5 style={{ color: "grey" }}>{NO_TRACKING_DATA[language][0]}</h5> : ""}
            <div className='d-flex align-items-center' style={imgStyle1}>
                <img src={FeelingGood} alt="happy face" style={{ width: "30%", height: "5vh" }} />
                <span style={fontStyle}>{result[2].value}% </span>
            </div>
            <div className='d-flex align-items-center' style={imgStyle2}>
                <img src={FeelingNeutral} alt="neutral face" style={{ width: "30%", height: "5vh" }} />
                <span style={fontStyle}>{result[1].value}%</span>
            </div>
            <div className='d-flex align-items-center' style={imgStyle3}>
                <img src={FeelingBad} alt="bad face" style={{ width: "30%", height: "5vh" }} />
                <span style={fontStyle}>{result[0].value}%</span>
            </div>
        </div>
        // <ReactECharts option={option} style={{ height: '600px', width: '800px' }} />
    )
}
export default FeelingBar
