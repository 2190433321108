import * as yup from "yup";

const SignInSchema = yup.object().shape({
  email: yup.string().email("Enter valid email").required("Email is Required"),
  password: yup.string().required("Password is required"),
});

const ResetPw1Schema = yup.object().shape({
  email: yup.string().email("Enter valid email").required("Email is Required"),
});

const ResetPw2Schema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  passwordConfirmation: yup
    .string()
    .required("Password Confirmation is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export { SignInSchema, ResetPw1Schema, ResetPw2Schema };
