import React, { useEffect, useState, useContext } from "react";
import { PreferredLanguageContext } from "./../../lib/main-context";

import { useHistory, useLocation } from "react-router-dom";

import { Button, Row } from "react-bootstrap";
import ComponentWrapper from "./ComponentWrapper";

import { getTBIIncidents } from "../../api/TBIRequests";

import jsPDF from "jspdf";

// Function to calculate age from date of birth
const calculateAge = (dateOfBirth) => {
  if (!dateOfBirth) return null;
  const birthDate = new Date(dateOfBirth);
  const currentDate = new Date();
  let age = currentDate.getFullYear() - birthDate.getFullYear();
  if (
    currentDate.getMonth() < birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${month}-${day}-${year}`;
};

const CaseReport = ({
  patient,
  topSymptoms,
  user,
  userIdForTrackedData,
  error,
  retry,
  data,
}) => {
  const location = useLocation();
  const days = location.state?.days || 30;
  const history = useHistory();
  const [incident, setIncident] = useState(null);

  useEffect(() => {
    const fetchTbiIncidents = async () => {
      try {
        const response = await getTBIIncidents(patient.patientId);
        const incidentsData = response.data;
        incidentsData.sort(
          (a, b) =>
            new Date(a.tbi_incident_date) - new Date(b.tbi_incident_date)
        );
        if (incidentsData.length > 0) {
          setIncident(incidentsData[0]);
        }
      } catch (error) {
        console.error("Error fetching TBI incidents:", error);
      }
    };
    fetchTbiIncidents();
  }, [patient.patientId, history]);

  const age = calculateAge(patient.dateOfBirth);

  const caseBackground = (
    <Row>
      <div style={{ fontWeight: "normal", padding: "10px" }}>
        <strong>BACKGROUND:</strong>{" "}
        <p>
          This area needs to be used for background literature review &
          challenges providers face when treating an "unknown" case like this.
          Alternatively, this area can be used for a discovery of an anomaly of
          an expected presentation. See poster presentations for idea
          generation.
        </p>
        <p>There are limited cases of XXX available in the literature.</p>
      </div>
    </Row>
  );

  const casePatientHistory = (
    <Row>
      <div style={{ fontWeight: "normal", padding: "10px" }}>
        <strong>Patient History:</strong>{" "}
        <p>
          A {age} year old {patient.gender} presented{" "}
          {incident && formatDate(incident.tbi_incident_date)} with a{" "}
          {patient.patientType}. Patient's chief complaint consisted of{" "}
          {topSymptoms.length > 0 &&
            topSymptoms.map((symptom, index) => (
              <span key={index}>
                {index > 0 && index < topSymptoms.length && ", "}
                {symptom.factor}
              </span>
            ))}{" "}
          . Patient identified these symptoms as the most pressing symptoms{" "}
          {topSymptoms.length > 0 &&
            topSymptoms.map((symptom, index) => (
              <span key={index}>
                {index > 0 && index < topSymptoms.length && ", "}
                {symptom.factor}
              </span>
            ))}{" "}
          . This was the patient's {incident && incident.total_tbi} TBI.
        </p>
        <p>
          Patient was injured by {incident && incident.injury_from} and reported
          being hit in the{incident && incident.head_hit_location} and
          experiences these symptoms at the time of injury{" "}
          {incident && incident.immediate_symptoms_resulting}.
        </p>
        <p>
          Patient's severity scores for baseline ranged from [insert lowest
          overall severity score on DAY 1 of tracking – and the highest severity
          score].
        </p>
        <p>
          Suggest adding the narrative here for completeness. Suggest
          incorporating graphs. See poster presentations for idea generation.
        </p>
        <p></p>
      </div>
    </Row>
  );

  const caseMethods = (
    <>
      <Row>
        <div style={{ fontWeight: "normal", padding: "10px" }}>
          <strong>Methods:</strong>{" "}
          <p>
            The treatment protocol determined for this patient was [file in your
            treatment plan & length of time to treat]. Additional information
            here may include other therapies, supplements, at home exercises,
            etc. Include the charts and analysis from Sallie™. May need to
            retrieve this information from your EHR system. Suggest
            incorporating graphs.
          </p>
        </div>
      </Row>
    </>
  );

  const caseDiscussion = (
    <Row>
      <div style={{ fontWeight: "normal", padding: "10px" }}>
        <strong>Discussion:</strong>{" "}
        <p>
          Final discussion points should include findings, plans to progress
          further research.
        </p>
      </div>
    </Row>
  );

  const caseReferences = (
    <Row>
      <div style={{ fontWeight: "normal", padding: "10px" }}>
        <strong>References:</strong>
        <p>List citations for any references used.</p>
      </div>
    </Row>
  );
  let currentY = 40;

  const exportToPdfCaseReport = () => {
    const doc = new jsPDF("p", "pt", "a4");

    // Define the y-coordinate for each section
    currentY = 40;

    // Add content to PDF
    addSection(doc, "BACKGROUND", caseBackground);
    addSection(doc, "Patient History", casePatientHistory);
    addSection(doc, "Methods", caseMethods);
    addSection(doc, "Discussion", caseDiscussion);
    addSection(doc, "References", caseReferences);

    // Save the PDF
    doc.save("case_report.pdf");
  };

  const addSection = (doc, title, content) => {
    // Add title (bold) and content for each section
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text(title, 40, currentY);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    const textContent = getTextFromJSX(content);
    const textLines = doc.splitTextToSize(
      textContent,
      doc.internal.pageSize.width - 80
    );
    doc.text(textLines, 40, currentY + 20);
    currentY += doc.getTextDimensions(textLines).h + 40; // Adjust y-coordinate for the next section
  };

  const getTextFromJSX = (jsxElement) => {
    // Extract text content from JSX element
    if (typeof jsxElement === "string") {
      return jsxElement; // Return as is if already a string
    } else if (Array.isArray(jsxElement)) {
      return jsxElement.map(getTextFromJSX).join(""); // Recursively process arrays
    } else if (jsxElement && jsxElement.props && jsxElement.props.children) {
      return getTextFromJSX(jsxElement.props.children); // Process props.children
    } else {
      return ""; // Return empty string if no valid text content found
    }
  };

  return (
    <>
      <ComponentWrapper>
        <ComponentWrapper>
          <div id="case-report-content">{caseBackground}</div>
        </ComponentWrapper>
        <ComponentWrapper>
          <div>{casePatientHistory}</div>
        </ComponentWrapper>
        <ComponentWrapper>
          <div>{caseMethods}</div>
        </ComponentWrapper>
        <ComponentWrapper>
          <div>{caseDiscussion}</div>
        </ComponentWrapper>
        <ComponentWrapper>
          <div>{caseReferences}</div>
        </ComponentWrapper>
        <Button onClick={exportToPdfCaseReport}>Export as PDF</Button>
      </ComponentWrapper>
    </>
  );
};

export default CaseReport;
