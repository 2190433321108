////////////////////////
// AccountSetting.js //
///////////////////////
export const SALLIE_SETTINGS = {
    english: {
        default: 'Change your account settings here! Click on one of the links in the box below.',
        notification: "I'm scheduled to come get you every day.",
        success: 'The change has saved successfully!',
        error: "Oops! Something went wrong and we couldn't save your change. Please try again."
    },
    spanish: {
        default: '¡Cambia la configuración de tu cuenta aquí! Haz clic en uno de los enlaces en el cuadro a continuación.',
        notification: "Estoy programada para buscarte todos los días.",
        success: '¡El cambio se ha guardado con éxito!',
        error: "¡Vaya! Algo salió mal y no pudimos guardar tu cambio. Por favor, inténtalo de nuevo."
    }
}

export const SETTINGS = {
    english: {
        notification: 'Notification Preferences',
    },
    spanish: {
        notification: 'Preferencias de notificaciones',
    }
}

////////////////////////////
// ChangeNotification.js //
///////////////////////////
export const NOTIFICATION_SETTINGS = {
    english: {
        email: 'Email',
        reminder: 'Set your reminder time',
        save: 'Save',
        success: 'Successfully Changed!'
    },
    spanish: {
        email: 'Correo electrónico',
        reminder: 'Ponga su tiempo de recordatorio',
        save: 'Guardar',
        success: '¡Cambiado exitosamente!'
    }
}