import React, { useContext } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { PreferredLanguageContext } from '../../lib/main-context';
import { CREATE_ACCOUNT, PROGRESS } from '../../constants/HeaderTranslation';

const OnboardingProgress = ({ onboardingPercent }) => {
  const { language } = useContext(PreferredLanguageContext);
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <h5>{CREATE_ACCOUNT[language]}</h5>

      <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
        {PROGRESS[language]}: {onboardingPercent}%
      </div>
      <ProgressBar
        now={onboardingPercent}
        max={100}
        variant="info"
        srOnly
        label={`Step ${onboardingPercent} / 100`}
      />
    </div>
  )
}

export default OnboardingProgress
