/////////////////////////
// Common Translations //
/////////////////////////

export const NONE = {
    english: "None",
    spanish: "Ninguno"
}

export const CHOOSE = {
    english: {
        choose: "Choose",
        choose_all: "Choose all that apply",
        error: 'You must select one of each type of response.'
    },
    spanish: {
        choose: "Escoja",
        choose_all: "Escoja todos los que apliquen",
        error: 'Debes seleccionar uno de cada tipo de respuesta.'
    }
}

export const OTHER = {
    english: 'Other',
    spanish: 'Otro'
}

/////////////////
// Register.js //
/////////////////
export const SALLIE_GREETING = {
    english: "Hi, I'm Sallie! Welcome to Melillo Method by Power of Patients!",
    spanish: "Hola, soy Sallie. Bienvenido a Melillo Method por Power of Patients!"
}

export const INTRODUCTIONS = {
    english: {
        part_one: "Sign up now to effectively track the key factors and symptoms that influence functional developmental behavioral neuroimmunology.",
        part_two: "Your journey to better understanding starts here!",
    },
    spanish: {
        part_one: "Regístrese ahora para realizar un seguimiento eficaz de los factores y síntomas clave que influyen en la neuroinmunología conductual del desarrollo funcional.",
        part_two: "¡Tu viaje hacia una mejor comprensión comienza aquí!",
    },
};

export const INTRO_STEPS = {
    english: {
        header: "Let's get to know you!",
        three_steps: "There are three steps to set up your account",
        step_one: "Create an account",
        step_two: "Describe your most recent TBI",
        step_three: "Set up your dashboard",
    },
    spanish: {
        header: "¡Vamos a conocerte!",
        three_steps: "Hay tres pasos para configurar su cuenta",
        step_one: "Crea una cuenta",
        step_two: "Describa su lesión cerebral traumática más reciente",
        step_three: "Configura tu panel de control",
    },
};

export const START_BUTTON_TEXT = {
    english: 'Start',
    spanish: 'Inicio'
};

export const FUTURE_DASHBOARD = {
    english: "Here's your future dashboard",
    spanish: "Aquí está tu futuro tablero"
};

//////////////////
// Basicinfo.js //
//////////////////
export const STEP_1A = {
    english: 'Step 1: Account Information',
    spanish: 'Paso 1: Información de su cuenta'
};

export const BASIC_INFORMATION = {
    english: "Let's start with some basic information!",
    spanish: "¡Empecemos con información básica!"
};

export const UNDER_13 = {
    english: "You need your parents/caregiver's help to create account",
    spanish: "Necesitas la ayuda de tus padres/cuidador para crear una cuenta."
};

export const REGISTRATION_TYPE = {
    english: {
        label: 'Registration Type',
        patient: 'Patient',
        caregiver: 'Caregiver',
        provider: 'Melillo Method Provider',
    },
    spanish: {
        label: 'Tipo de registración',
        patient: 'Paciente',
        caregiver: 'Cuidador',
        provider: 'Melillo Method Provider',
    },
};

export const PRIMARY_DIAGNOSIS = {
    english: {
        label: 'Primary Diagnosis for Patient:',
        "TBI/Concussion": {
            category: "TBI/Concussion",
            items: ["Acute TBI", "Chronic TBI", "Post-Concussion Symptom (PCS)"]
        },
        Dementia: {
            category: "Dementia",
            items: ["Alzheimer's", "Lewy Body", "Parkinson's"]
        },
        Stroke: {
            category: "Stroke",
            items: [
                "Brain Stem",
                "Embolic",
                "Hemorrhagic",
                "Thrombotic",
                "TIA",
                "Unknown Cause"
            ]
        },
        Other: {
            category: "Other",
            items: [
                "Acquired Brain Injury (ABI)",
                "Autism",
                "COVID Long-Hauler",
                "Epilepsy",
            ]
        }
    },
    spanish: {
        label: 'Diagnóstico primario del paciente:',
        "TBI/Concussion": {
            category: "LCT/ conmoción cerebral",
            items: ["TCE Agudo", "LCT Crónica", "Síntomas Posconmoción Cerebral (PCS)"]
        },
        Dementia: {
            category: "Demencia",
            items: ["Alzheimer", "Cuerpo de Lewy", "Parkinson"]
        },
        Stroke: {
            category: "Accidente Cerebrovascular",
            items: [
                "Tronco Cerebral",
                "Embolia",
                "Hemorrágico",
                "Trombótico",
                "AIT",
                "Causa Desconocida"
            ]
        },
        Other: {
            category: "Otro",
            items: [
                "Lesión Cerebral Adquirida (LCA)",
                "Autismo",
                "COVID-19 de larga duración",
                "Epilepsia",
            ]
        }
    }
};

export const NAME = {
    english: {
        first: 'First Name',
        last: 'Last Name',
    },
    spanish: {
        first: 'Nombre',
        last: 'Apellido',
    },
};

export const RACE = {
    english: {
        label: 'Race',
        americanIndianOrAlaskanNative: 'American Indian or Alaskan Native',
        asianIndian: 'Asian Indian',
        blackOrAfricanAmerican: 'Black or African American',
        chamorroOrGuamanian: 'Chamorro or Guamanian',
        chinese: 'Chinese',
        filipino: 'Filipino',
        japanese: 'Japanese',
        korean: 'Korean',
        white: 'White',
        otherRace: 'Other Race',
        preferNotToAnswer: 'Prefer not to answer'
    },
    spanish: {
        label: 'Raza',
        americanIndianOrAlaskanNative: 'Indio Americano o Nativo de Alaska',
        asianIndian: 'Indio Asiático',
        blackOrAfricanAmerican: 'Negro o Afroamericano',
        chamorroOrGuamanian: 'Chamorro o Guamaniano',
        chinese: 'Chino',
        filipino: 'Filipino',
        japanese: 'Japonés',
        korean: 'Coreano',
        white: 'Blanco',
        otherRace: 'Otra Raza',
        preferNotToAnswer: 'Prefiero no responder'
    }
};

export const ETHNICITY = {
    english: {
        label: 'Ethnicity',
        yes: 'Yes, Hispanic, Latino/a/x, or of Spanish origin',
        no: 'No, not of Hispanic origin',
        other: 'Some other ethnicity or origin',
        preferNotToAnswer: 'Prefer not to answer'
    },
    spanish: {
        label: 'Etnicidad',
        yes: 'Sí, hispano, latino/a/x u origen español',
        no: 'No, no de origen hispano',
        other: 'Algunas otras etnias u origen',
        preferNotToAnswer: 'Prefiero no responder'
    }
};

export const VETERAN = {
    english: {
        label: 'Veteran Status',
        yes: 'Yes',
        no: 'No',
        preferNotToAnswer: 'Prefer not to answer'
    },
    spanish: {
        label: 'Condición de veterano',
        yes: 'Sí',
        no: 'No',
        preferNotToAnswer: 'Prefiero no responder'
    }
};

export const GENDER = {
    english: {
        label: 'Gender',
        male: 'Male',
        female: 'Female',
        other: 'Other'
    },
    spanish: {
        label: 'Género',
        male: 'Masculino',
        female: 'Femenino',
        other: 'Otro'
    }
};

export const PHONE = {
    english: 'Mobile Phone Number',
    spanish: 'Número de teléfono móvil'
};

export const EMAIL = {
    english: 'Email',
    spanish: 'Correo electrónico'
};

export const DOB = {
    english: 'Date of Birth (MM/DD/YYYY)',
    spanish: 'Fecha de nacimiento (mm/dd/aaaa)'
};

export const COUNTRY = {
    english: 'Country',
    spanish: 'País'
};

export const ZIPCODE = {
    english: 'Zipcode',
    spanish: 'Código Postal'
};

export const PASSWORD = {
    english: {
        password: 'Password',
        confirm_password: 'Confirm Password',
    },
    spanish: {
        password: 'Contraseña',
        confirm_password: 'Confirmar contraseña',
    },
};

export const PROVIDER_TYPE = {
    english: {
        label: 'Provider Type',
        melilloMethodProvider: 'Melillo Method Provider',
    },
    spanish: {
        label: 'Tipo de proveedor',
        melilloMethodProvider: 'Melillo Method Provider',
    }
};

export const MESSAGES_AND_ALERTS = {
    english: {
        messages: 'Are you willing to receive messages from patients?',
        alerts: "Are you open to receiving alerts regarding the increase in severity of your patients' conditions?",
        yes: 'Yes',
        no: 'No'
    },
    spanish: {
        messages: 'Esta dispuesto a recibir mensajes del paciente?',
        alerts: 'Esta dispuesto a recibir alertas sobre el aumento de la gravedad de las condiciones de su paciente?',
        yes: 'Sí',
        no: 'No'
    }
};

export const TERMS_AND_CONDITIONS = {
    english: {
        accept: 'Please accept the ',
        terms_and_conditions: 'terms and conditions'
    },
    spanish: {
        accept: 'Por favor acepte los ',
        terms_and_conditions: 'términos y condiciones'
    }
}

export const SAVE_AND_CONTINUE = {
    english: 'Save & Continue',
    spanish: 'Guardar y continuar'
}

export const SAVE_AND_GO_TO_DASHBOARD = {
    english: 'Save & Go To Dashboard',
    spanish: 'Guardar y Ir al Tablero'
}

////////////////////
// PatientInfo.js //
////////////////////
export const PATIENT_INFO = {
    english: {
        header: 'Step 1-B: Patient Information',
        sallie: "We need the patient's information too!",
        id: 'ID',
        id_info: '(This field is OPTIONAL. It can be used as a medical record number to link to your EMR in the future.)'
    },
    spanish: {
        header: 'Paso 1-b: Información del paciente',
        sallie: "¡Necesitamos la información del paciente también!",
        id: 'Número de identificación',
        id_info: '(Este campo es OPCIONAL. Puede usarse como un número de expediente médico para vincularlo a su EMR en el futuro.)'
    }
}

export const RELATIONSHIP = {
    english: {
        label: 'Who are you tracking for? What is your relationship with the patient?',
        parent: 'Parent',
        spouse: 'Spouse',
        friend: 'Friend',
        child: 'Child',
        other: 'Other',
        error: 'Type of relationship is required',
        relationship: 'Relationship:'
    },
    spanish: {
        label: '¿Para quién está realizando seguimiento de síntomas? ¿Cuál es su relación con el paciente?',
        parent: 'Padre',
        spouse: 'Cónyuge',
        friend: 'Amigo',
        child: 'Menor',
        other: 'Otro',
        error: 'Se requiere tipo de relación',
        relationship: 'Relación:'
    }
}

export const CONFIRM = {
    english: 'Confirm',
    spanish: 'Confirmar'
}

export const COMMENTS = {
    english: 'Comments:',
    spanish: 'Comentarios:'
}

////////////////////////////
// SelectLaterSymptoms.js //
////////////////////////////
// Step 4 in the DB
export const MEDICAL_SYMPTOMS = {
    english: 'Step 2: Medical Symptoms',
    spanish: 'Paso 2: Síntomas Médicos'
}

export const SALLIE_BRAIN = {
    english: {
        tbiPatient: "Let's gather your symptoms so we can internalize some data.",
        caregiver: "Let's gather the patient's symptoms so we can internalize some data.",
    },
    spanish: {
        tbiPatient: "Recolectemos sus síntomas para internalizar los datos.",
        caregiver: "Recolectemos los síntomas del paciente para internalizar los datos.",
    }
};

export const SYMPTOM_SELECTOR = {
    english: {
        category_header: 'Go through each category below to see the list of symtoms',
        category: {
            cognitive: 'Cognitive Symptoms',
            sleep: 'Sleep Symptoms',
            emotional: 'Emotional Symptoms',
            physical: 'Physical Symptoms',
            speech: 'Speech Symptoms',
            vision: 'Vision Symptoms',
        },
        list_header: 'Your list of symptoms',
        errors: {
            duplicate: "Please only select unique symptoms",
            no_symptom: {
                patient: 'Please select TBI symptoms you have experienced',
                caregiver: 'Please select TBI symptoms the patient has experienced',
                neutral: 'Please select a TBI symptom'
            },
            headache_location: 'Please select the location of the headaches caused by close work'
        }
    },
    spanish: {
        category_header: "Vaya por cada categoría para ver la lista de síntomas",
        category: {
            cognitive: 'Síntomas Cognitivos',
            sleep: 'Síntomas de Sueño',
            emotional: 'Síntomas Emocionales',
            physical: 'Síntomas Físicos',
            speech: 'Síntomas del Habla',
            vision: 'Síntomas de la Visión',
        },
        list_header: "Su lista de síntomas",
        errors: {
            duplicate: "Por favor, seleccione solo síntomas únicos",
            no_symptom: {
                patient: 'Por favor, seleccione los síntomas de TBI que ha experimentado',
                caregiver: 'Por favor, seleccione los síntomas de TBI que el paciente ha experimentado',
                neutral: 'Por favor seleccione un síntoma de TBI'
            },
            headache_location: "Por favor, seleccione la ubicación de los dolores de cabeza causados por trabajo cercano"
        }
    }
}

export const SYMPTOM_LIST = {
    english: {
        cognitive: {
            "Brain Fog, Lack of Focus": "Brain Fog, Lack of Focus",
            "Short Term Memory Loss": "Short Term Memory Loss",
            "Poor Concentration": "Poor Concentration",
            "Slow Thinking or Processing": "Slow Thinking or Processing",
            "Can't find the right words": "Can't find the right words",
            "Dyslexia": "Dyslexia",
            "Stuttering": "Stuttering",
            "Long Term Memory Loss": "Long Term Memory Loss",
            "None": "None"
        },
        sleep: {
            "Excessive Sleep": "Excessive Sleep",
            "Poor/Little Sleep": "Poor/Little Sleep",
            "Fatigue": "Fatigue",
            "Excessive Exhaustion": "Excessive Exhaustion",
            "Extreme Yawning": "Extreme Yawning",
            "Drowsiness": "Drowsiness",
            "Narcolepsy": "Narcolepsy",
            "Sleep Apnea": "Sleep Apnea",
            "Shaking": "Shaking",
            "None": "None"
        },
        emotional: {
            "Depression": "Depression",
            "Anxiety": "Anxiety",
            "Mood Swings": "Mood Swings",
            "Unexplained Sadness/Crying": "Unexplained Sadness/Crying",
            "No Motivation": "No Motivation",
            "Angry": "Angry",
            "Very nervous": "Very nervous",
            "Panic attacks": "Panic attacks",
            "Irritability": "Irritability",
            "Impulsive": "Impulsive",
            "None": "None"
        },
        physical: {
            "Headaches and/or Migraines": "Headaches and/or Migraines",
            "Loss of Balance/Dizziness": "Loss of Balance/Dizziness",
            "Loss of Smell": "Loss of Smell",
            "Light Sensitivity": "Light Sensitivity",
            "Ringing in Ears": "Ringing in Ears",
            "Noise Sensitivity": "Noise Sensitivity",
            "Vomiting/Digestion Issues": "Vomiting/Digestion Issues",
            "Heart Sensations": "Heart Sensations",
            "Lack of Coordination": "Lack of Coordination",
            "Unexplained Dropping Objects": "Unexplained Dropping Objects",
            "Loss of Hand Control": "Loss of Hand Control",
            "Repeating Oneself": "Repeating Oneself",
            "Loss of Appetite": "Loss of Appetite",
            "Always Hungry": "Always Hungry",
            "Loss of Taste": "Loss of Taste",
            "Tremors in hands": "Tremors in hands",
            "Constipation": "Constipation",
            "Extremities of toes and hands often cold": "Extremities of toes and hands often cold",
            "None": "None"
        },
        speech: {
            "Slurred Speech": "Slurred Speech",
            "Trouble staying organized": "Trouble staying organized",
            "Trouble managing daily tasks": "Trouble managing daily tasks",
            "Less responsive to the environment": "Less responsive to the environment",
            "Limited social engagement": "Limited social engagement",
            "Difficulty expressing needs": "Difficulty expressing needs",
            "Limited communication": "Limited communication",
            "Trouble remembering names": "Trouble remembering names",
            "Challenging to count to 10": "Challenging to count to 10",
            "Struggles to multitask": "Struggles to multitask",
            "Trouble with self-discipline": "Trouble with self-discipline",
            "Difficult to understand abstract ideas": "Difficult to understand abstract ideas",
            "Difficult to plan in advance": "Difficult to plan in advance",
            "Difficulty Swallowing": "Difficulty Swallowing",
            "None": "None"
        },
        vision: {
            "Blurred Vision": "Blurred Vision",
            "Words jump": "Words jump",
            "Uncomfortable eyes": "Uncomfortable eyes",
            "Double vision": "Double vision",
            "Bothered by light": "Bothered by light",
            "Trouble perceiving depth": "Trouble perceiving depth",
            "Distorted side vision": "Distorted side vision",
            "Dry eyes": "Dry eyes",
            "Irritated by visually-busy places": "Irritated by visually-busy places",
            "Words move when reading": "Words move when reading",
            "Chronically Itchy": "Chronically Itchy",
            "Line skipping: when reading text (paper or computer) I skip lines": "Line skipping: when reading text (paper or computer) I skip lines",
            "Eyes get tired (fatigued) very easily or quickly: when doing close work": "Eyes get tired (fatigued) very easily or quickly: when doing close work",
            "Headaches caused by close work: computers, reading, gaming": "Headaches caused by close work: computers, reading, gaming",
            "None": "None"
        }
    },
    spanish: {
        cognitive: {
            "Brain Fog, Lack of Focus": "Niebla del cerebro. Falta de enfoque",
            "Short Term Memory Loss": "Pérdida de memoria a corto plazo",
            "Poor Concentration": "Concentración pobre.",
            "Slow Thinking or Processing": "Pensamiento o procesamiento lento",
            "Can't find the right words": "No encontrar palabras correctas",
            "Dyslexia": "Dislexia",
            "Stuttering": "Tartamudez",
            "Long Term Memory Loss": "Pérdida de memoria a largo plazo",
            "None": "Ninguno"
        },
        sleep: {
            "Excessive Sleep": "Sueño Excesivo",
            "Poor/Little Sleep": "Poco/Pobre Sueño",
            "Fatigue": "Fatiga",
            "Excessive Exhaustion": "Agotamiento Excesivo",
            "Extreme Yawning": "Bostezos extremos",
            "Drowsiness": "Somnolencia",
            "Narcolepsy": "Narcolepsia",
            "Sleep Apnea": "Apnea del sueño",
            "Shaking": "Temblorosidad",
            "None": "Ninguno"
        },
        emotional: {
            "Depression": "Depresión",
            "Anxiety": "Ansiedad",
            "Mood Swings": "Cambios de humor",
            "Unexplained Sadness/Crying": "Tristeza/llanto sin razón",
            "No Motivation": "Desmotivación",
            "Angry": "Molestia",
            "Very nervous": "Nerviosismo",
            "Panic attacks": "Ataques de pánico",
            "Irritability": "Irritabilidad",
            "Impulsive": "Impulsividad",
            "None": "Ninguno"
        },
        physical: {
            "Headaches and/or Migraines": "Dolor de cabeza/Migrañas",
            "Loss of Balance/Dizziness": "Pérdida de balance/Mareos",
            "Loss of Smell": "Pérdida de olfato",
            "Light Sensitivity": "Sensibilidad a la luz",
            "Ringing in Ears": "Pitido en el oído",
            "Noise Sensitivity": "Sensibilidad al ruido",
            "Vomiting/Digestion Issues": "Vómitos/Problemas digestivos",
            "Heart Sensations": "Sensaciones del corazón",
            "Lack of Coordination": "Falta de coordinación",
            "Unexplained Dropping Objects": "Caída de objetos inexplicable",
            "Loss of Hand Control": "Pérdida de control de manos",
            "Repeating Oneself": "Repetirse uno mismo",
            "Loss of Appetite": "Pérdida de apetito",
            "Always Hungry": "Siempre hambriento",
            "Loss of Taste": "Pérdida del gusto",
            "Tremors in hands": "Tremores en las manos",
            "Constipation": "Constipación",
            "Extremities of toes and hands often cold": "Extremidades de los dedos de los pies y manos frías",
            "None": "Ninguno"
        },
        speech: {
            "Slurred Speech": "Habla arrastrada",
            "Trouble staying organized": "Problemas manteniéndose organizado",
            "Trouble managing daily tasks": "Problemas manejando tareas diarias. ",
            "Less responsive to the environment": "Menos responsivo al ambiente",
            "Limited social engagement": "Interacción social limitada",
            "Difficulty expressing needs": "Dificultad expresando necesidades",
            "Limited communication": "Comunicación limitada",
            "Trouble remembering names": "Problemas recordando nombres",
            "Challenging to count to 10": "Contar hasta 10 es desafiante ",
            "Struggles to multitask": "Manejar múltiples tareas a la vez es desafiante",
            "Trouble with self-discipline": "Problemas con la disciplina propia",
            "Difficult to understand abstract ideas": "Dificultad para entender ideas abstractas",
            "Difficult to plan in advance": "Dificultad para planificar con anticipación",
            "Difficulty Swallowing": "Dificultad tragando",
            "None": "Ningundo"
        },
        vision: {
            "Blurred Vision": "Visión borrosa",
            "Words jump": "Palabras brincan",
            "Uncomfortable eyes": "Ojos incómodos",
            "Double vision": "Visión doble",
            "Bothered by light": "La luz molesta",
            "Trouble perceiving depth": "Problemas percibiendo profundidad",
            "Distorted side vision": "Visión lateral distorsionada",
            "Dry eyes": "Ojos secos",
            "Irritated by visually-busy places": "Irritado por lugares visualmente ocupados",
            "Words move when reading": "Las palabras se mueven al leer",
            "Chronically Itchy": "Picor",
            "Line skipping: when reading text (paper or computer) I skip lines": "Brincar líneas: al leer texto (en papel o computadora) brinco líneas",
            "Eyes get tired (fatigued) very easily or quickly: when doing close work": "Ojos se cansan (fatigan) fácilmente o rápido: cuando hace trabajo cercano",
            "Headaches caused by close work: computers, reading, gaming": "Dolores de cabeza causados por trabajo cercano: computadoras, leer, jugar",
            "None": "Ninguno "
        }
    }
}

export const HEADACHE_LOCATION = {
    english: {
        label: 'Headaches from close work appear in the:',
        fore: "Forehead",
        backtop: "Back of head (top)",
        right: "Right side of head",
        left: "Left side of head",
        behind: "Behind",
        eyes: "Eyes",
        backbottom: "Back of head (bottom)",
        all: "All over Headache",
    },
    spanish: {
        label: 'Los dolores de cabeza por trabajar de cerca aparecen en:',
        fore: "Frente",
        backtop: "Parte superior de la cabeza",
        right: "Lado derecho de la cabeza",
        left: "Lado izquierdo de la cabeza",
        behind: "Detrás",
        eyes: "Ojos",
        backbottom: "Parte inferior de la cabeza",
        all: "Dolor de cabeza en todo el área",
    }
}


//////////////////////////
// SelectTopSymptoms.js //
//////////////////////////

export const TOP_SYMPTOMS = {
    english: {
        header: 'Step 3: Top Symptoms',
        body: 'You will be able to track ALL these symptoms.'
    },
    spanish: {
        header: 'Paso 3: Síntomas Principales',
        body: 'Podrá darle seguimiento todos estos síntomas.'
    }
}

export const SALLIE_TOP_SYMPTOMS = {
    english: "We'd like to know what 3 WORST SYMPTOMS bother you the most.",
    spanish: "Nos gustaría saber cuáles son los 3 PEORES SÍNTOMAS que más te molestan."
}

/////////////////////////////////
// SelectSocialDeterminants.js //
/////////////////////////////////

export const DAILY_TRIGGER = {
    english: {
        header: 'Step 4: DAILY TRIGGERS',
    },
    spanish: {
        header: 'Paso 4: DETONANTES DIARIOS',
    }
}

export const SALLIE_TRIGGER = {
    english: {
        patient: "It's important to know what you do every day. The more I know about your DAILY ACTIVITIES, the better I become trained to help you. Check the boxes below that you NORMALLY do any day.",
        caregiver: "It's important to know what the patient does every day. The more I know about your DAILY ACTIVITIES, the better I become trained to help you. Check the boxes below that the patient NORMALLY does on any given day."
    },
    spanish: {
        patient: "Es importante conocer lo que pasa en su día a día. Mientras más conozca sobre sus ACTIVIDADES DIARIAS, mejor entreno para ayudarle. Seleccione las actividades que NORMALMENTE realiza con frecuencia.",
        caregiver: "Es importante saber lo que el paciente hace cada día. Mientras más conozca sobre sus ACTIVIDADES DIARIAS, mejor entrenaré para ayudarle. Seleccione las actividades que el paciente NORMALMENTE realiza en un día cualquiera."
    }
}

export const TRIGGER_SELECTOR = {
    english: {
        category_header: 'Go through each category below to see the list of triggers',
        messages: {
            wellness: "Health triggers: ",
            travel: "Travel triggers: ",
            screen_time: "Screen time triggers: ",
            environmental: "Environmental triggers: ",
            dietary: "Dietary triggers: ",
            situational: "Family or Life events: ",
            other: "Other triggers: ",
        },
        tabs: {
            wellness: "Wellness",
            travel: "Travel",
            screen_time: "Screen time",
            environmental: "Environmental",
            dietary: "Dietary",
            situational: "Situational",
            other: "Other",
        },
        list_header: 'Your list of triggers',
        error: 'Please select at least 1 trigger'
    },
    spanish: {
        category_header: "¿Qué actividades realizó hoy?",
        messages: {
            wellness: 'Detonantes relacionados a salud y bienestar: ',
            travel: 'Detonantes relacionados a viajes y transportación: ',
            screen_time: 'Detonantes relacionados a Tiempo de Pantalla/ Screentime: ',
            environmental: 'Detonantes ambientales: ',
            dietary: 'Detonantes relacionados a dietas: ',
            situational: 'Eventos familiares o diarios: ',
            other: 'Otros detonantes: ',
        },
        tabs: {
            wellness: 'Bienestar',
            travel: 'Viajes',
            screen_time: 'Tiempo de Pantalla',
            environmental: 'Ambientales',
            dietary: 'Dietas',
            situational: 'Situacionales',
            other: 'Otros',
        },
        list_header: 'Su lista de detonantes',
        error: 'Seleccione al menos 1 activador'
    }
}

export const TRIGGER_LIST = {
    english: {
        wellness: {
            'Menstrual Cycle': 'Menstrual Cycle',
            Dehydration: 'Dehydration',
            Caffeine: 'Caffeine',
            Exercise: 'Exercise',
            Stress: 'Stress',
            Medication: 'Medication',
            Hydration: 'Hydration',
            "Relaxation/Rest": "Relaxation/Rest",
            Anxious: 'Anxious',
            Sick: 'Sick',
            "Muscle Pain": 'Muscle Pain',
            None: 'None'
        },
        travel: {
            Car: 'Car',
            Bus: 'Bus',
            Plane: 'Plane',
            Boat: 'Boat',
            "Metro/Subway": 'Metro/Subway',
            "Cab/Uber/Lyft": 'Cab/Uber/Lyft',
            'Motorized scooter/bike': 'Motorized scooter/bike',
            Hiking: 'Hiking',
            Traffic: 'Traffic',
            Bike: 'Bike',
            Walking: 'Walking',
            'Inability to drive': 'Inability to drive',
            None: 'None'
        },
        "screen time": {
            Computer: 'Computer',
            TV: 'TV',
            Phone: 'Phone',
            Tablet: 'Tablet',
            "Smart watch": 'Smart watch',
            Reading: 'Reading',
            "Video Calling": 'Video Calling',
            "Online Education": 'Online Education',
            "School Work/Paper work": 'School Work/Paper work',
            None: 'None'
        },
        environmental: {
            "High Temperature": 'High Temperature',
            "Low Temperature": 'Low Temperature',
            Storm: 'Storm',
            Rain: 'Rain',
            "Bright Sun": 'Bright Sun',
            "Large Crowds": 'Large Crowds',
            "Loud Noises": 'Loud Noises',
            Humidity: 'Humidity',
            "Barometric pressure": 'Barometric pressure',
            Wind: 'Wind',
            "Busy Surroundings": 'Busy Surroundings',
            Overstimulated: 'Overstimulated',
            "Poor air quality": 'Poor air quality',
            Allergies: 'Allergies',
            Altitude: 'Altitude',
            None: 'None'
        },
        Dietary: {
            "Loss of appetite": 'Loss of appetite',
            Undereating: 'Undereating',
            "Lack of access to food": 'Lack of access to food',
            Hunger: 'Hunger',
            "Food poisoning": 'Food poisoning',
            "Increased weight": 'Increased weight',
            "Decreased weight": 'Decreased weight',
            "Increased appetite": 'Increased appetite',
            Overeating: 'Overeating',
            Sugar: 'Sugar',
            "Food allergy": 'Food allergy',
            Gluten: 'Gluten',
            None: 'None'
        },
        situational: {
            "Family Members": 'Family Members',
            "Significant Other": 'Significant Other',
            Kids: 'Kids',
            "Work Meeting/Presentation": 'Work Meeting/Presentation',
            "Grand Kids": 'Grand Kids',
            Friends: 'Friends',
            "Practicing/Listening to Music": 'Practicing/Listening to Music',
            "Co-workers": 'Co-workers',
            "Doing tasks": 'Doing tasks',
            "Planning/Organizing": 'Planning/Organizing',
            Arguments: 'Arguments',
            "Therapy/Medical Appointment": 'Therapy/Medical Appointment',
            Finances: 'Finances',
            None: 'None'
        },
        Other: {
            None: 'None'
        }
    },
    spanish: {
        wellness: {
            'Menstrual Cycle': 'Ciclo menstrual',
            Dehydration: 'Deshidratación',
            Caffeine: 'Cafeína',
            Exercise: 'Ejercicios',
            Stress: 'Estrés',
            Medication: 'Medicamentos',
            Hydration: 'Hidratación',
            "Relaxation/Rest": "Relajación/descanso",
            Anxious: 'Ansiedad',
            Sick: 'Enfermedad',
            "Muscle Pain": 'Dolor muscular',
            None: 'Ninguno'
        },
        travel: {
            Car: 'Auto',
            Bus: 'Autobús/guagua',
            Plane: 'Avión',
            Boat: 'Bote',
            "Metro/Subway": 'Tren',
            "Cab/Uber/Lyft": 'Taxi/Uber/Lyft',
            'Motorized scooter/bike': 'Bicicleta o scooter motorizada',
            Hiking: 'Caminatas',
            Traffic: 'Tráfico',
            Bike: 'Bicicleta',
            Walking: 'Caminar',
            'Inability to drive': 'Inhabilidad para conducir',
            None: 'Ninguno'
        },
        "screen time": {
            Computer: 'Computadora',
            TV: 'Televisor/TV ',
            Phone: 'Celular',
            Tablet: 'Tableta',
            "Smart watch": 'Reloj inteligente',
            Reading: 'Leer',
            "Video Calling": 'Video llamadas',
            "Online Education": 'Educación en línea',
            "School Work/Paper work": 'Trabajos escolares/universitarios',
            None: 'Ninguno'
        },
        environmental: {
            "High Temperature": 'Calor (altas temperaturas)',
            "Low Temperature": 'Frío (bajas temperaturas)',
            Storm: 'Tormenta',
            Rain: 'Lluvia',
            "Bright Sun": 'Sol brillante',
            "Large Crowds": 'Multitud/muchedumbre',
            "Loud Noises": 'Sonidos altos',
            Humidity: 'Humedad',
            "Barometric pressure": 'Presión barométrica',
            Wind: 'Viento',
            "Busy Surroundings": 'Ruidoso',
            Overstimulated: 'Sobreestimulación',
            "Poor air quality": 'Calidad de aire baja',
            Allergies: 'Alergias',
            Altitude: 'Alturas',
            None: 'Ninguno'
        },
        Dietary: {
            "Loss of appetite": 'Pérdida de apetito',
            Undereating: 'Comer en pocas cantidades',
            "Lack of access to food": 'Falta de acceso a alimentos',
            Hunger: 'Hambre',
            "Food poisoning": 'Indigestión',
            "Increased weight": 'Aumento de peso',
            "Decreased weight": 'Bajo peso',
            "Increased appetite": 'Aumento de apetito',
            Overeating: 'Comer en exceso',
            Sugar: 'Consumo de azúcar',
            "Food allergy": 'Alergias a los alimentos',
            Gluten: 'Consumo de gluten',
            "None": 'Ninguno'
        },
        situational: {
            "Family Members": 'Miembros familiares',
            "Signifcant Other": 'Pareja',
            Kids: "Hijos/niños",
            "Work Meeting/Presentation": 'Reunión o presentación del trabajo',
            "Grand Kids": 'Nietos',
            Friends: 'Amigos',
            "Practicing/Listening to Music": 'Practicar/escuchar música',
            "Co-workers": 'Compañeros del trabajo',
            "Doing tasks": 'Realizar tareas',
            "Planning/Organizing": 'Planificar/organizar',
            Arguments: 'Argumentos/discusiones',
            "Therapy/Medical Appointment": 'Citas médicas/terapias',
            Finances: 'Finanzas',
            None: 'Ninguno'
        },
        Other: {
            None: 'Ninguno'
        }
    }
}

////////////////////////
// SelectTherapies.js //
////////////////////////

export const THERAPIES = {
    english: {
        header: 'Step 7a: Therapies',
        sallie: 'What therapies have you tried?'
    },
    spanish: {
        header: 'Paso 7a: Terapias',
        sallie: '¿Qué terapias ha intentado?'
    }
}

export const THERAPY_SELECTOR = {
    english: {
        category_header: 'Go through each category below to see the list of therapies',
        category: {
            alternative: 'Alternative',
            applied: 'Applied',
            chiropractic: 'Chiropractic',
            vision: 'Vision',
            mental_health: 'Mental Health',
            other: 'Other'
        },
        list_header: 'Your list of therapies'
    },
    spanish: {
        category_header: 'Vaya por cada categoría para ver la lista de terapias',
        category: {
            alternative: 'Alternativas',
            applied: 'Aplicadas',
            chiropractic: 'Quiropráctica',
            vision: 'Visión',
            mental_health: 'Salud Mental',
            other: 'Otros'
        },
        list_header: 'Su lista de terapias'
    }
}

export const THERAPY_LIST = {
    english: {
        Alternative: {
            Acupuncture: 'Acupuncture',
            "Massage Therapy": 'Massage Therapy',
            'Pain Management': 'Pain Management',
            None: 'None'
        },
        Applied: {
            'Physical Therapy': 'Physical Therapy',
            'Occupational Therapy': 'Occupational Therapy',
            'Speech Language Therapy': 'Speech Language Therapy',
            'Music Therapy': 'Music Therapy',
            None: 'None'
        },
        Chiropractic: {
            'Functional Neurology': 'Functional Neurology',
            'Upper Cervical': 'Upper Cervical',
            'Regular Chiropractic Care': 'Regular Chiropractic Care',
            None: 'None'
        },
        Vision: {
            'Developmental Vision Therapy': 'Developmental Vision Therapy',
            'Telehealth Vision Therapy': 'Telehealth Vision Therapy',
            Syntonics: 'Syntonics',
            'Virtual Therapy': 'Virtual Therapy',
            None: 'None'
        },
        "Mental Health": {
            'Cognitive Behavioral Therapy': 'Cognitive Behavioral Therapy',
            Psychology: 'Psychology',
            'Therapy for PTSD/Trauma': 'Therapy for PTSD/Trauma',
            Counselling: 'Counselling',
            Psychiatry: 'Psychiatry',
            'Neurologist ': 'Neurologist',
            None: 'None'
        },
        Other: {
            None: 'None'
        }
    },
    spanish: {
        Alternative: {
            Acupuncture: 'Acupuntura',
            "Massage Therapy": 'Manejo de dolor',
            'Pain Management': 'Terapia de masaje',
            None: 'Ninguno'
        },
        Applied: {
            'Physical Therapy': 'Terapia física',
            'Occupational Therapy': 'Terapia ocupacional',
            'Speech Language Therapy': 'Terapia del habla y lenguaje',
            'Music Therapy': 'Terapia de música',
            None: 'Ninguno'
        },
        Chiropractic: {
            'Functional Neurology': 'Neurología funcional',
            'Upper Cervical': 'Cervical superior',
            'Regular Chiropractic Care': 'Cuidado quiropráctico regular',
            None: 'Ninguno'
        },
        Vision: {
            'Developmental Vision Therapy': 'Terapia Visual del Desarrollo',
            'Telehealth Vision Therapy': 'Terapia Visual por Teleterapia',
            Syntonics: 'Syntonics (Fototerapia)',
            'Virtual Therapy': 'Terapia Virtual',
            None: 'Ninguno'
        },
        "Mental Health": {
            'Cognitive Behavioral Therapy': 'Terapia cognitiva conductual',
            Psychology: 'Psicología',
            'Therapy for PTSD/Trauma': 'Terapia para PTSD/trauma',
            Counselling: 'Consejería',
            Psychiatry: 'Psiquiatría',
            'Neurologist ': 'Neurólogo',
            None: 'Ninguno'
        },
        Other: {
            None: 'Ninguno'
        }
    }
}

////////////////////
// PaymentPage.js //
////////////////////
export const FINAL_STEP = {
    english: 'Final Step: Membership Payment',
    spanish: 'Paso final: Pago de membresía'
}

export const SALLIE_PAYMENT = {
    english: {
        default: 'Please click to submit your payment, and you will be redirected to the payment page immediately after this.'
    },
    spanish: {
        default: 'Haga clic para enviar su pago y será redirigido a la página de pago inmediatamente después de esto.'
    }
}

export const PLAN = {
    english: {
        name: 'Standard Plan',
        description: 'Full Access to Sallie\nUnlimited user connections\nClinical Decision Support Models\nMarketing Posters and Brochures Included',
        month: 'mo.',
        minimum: '(minimum 12-month contract)',
        from: 'From:',
        to: 'To:'
    },
    spanish: {
        name: 'Plan estándar',
        description: 'Acceso completo a la sala\nConexiones de usuario ilimitadas\nModelos de apoyo a la decisión clínica\nPósteres y folletos de mercadeo incluidos',
        month: 'mes.',
        minimum: '(contrato mínimo 12 meses)',
        from: 'Desde:',
        to: 'Hasta:'
    }
}

export const PAYMENT = {
    english: {
        interest: 'Thank you for your interest in purchasing Sallie. Click the SUBMIT PAYMENT button to continue.',
        submit: 'Submit Payment'
    },
    spanish: {
        interest: 'Gracias por su interés en comprar Sallie. Haga clic en el botón ENVIAR PAGO para continuar.',
        submit: 'Enviar pago'
    }
}

export const LICENSE = {
    english: {
        label: 'License Key Input',
        info: 'If you hold an assigned license key, please verify it to proceed without making a payment.',
        enter: 'Enter your license key',
        verify: 'Verify'
    },
    spanish: {
        label: 'Entrada de clave de licencia',
        info: 'Si tiene una clave de licencia asignada, verifíquela para continuar sin realizar ningún pago.',
        enter: 'Ingrese su clave de licencia',
        verify: 'Verificar'
    }
}

//////////////////////////
// NotificationPrefs.js //
//////////////////////////
export const NOTIF_PREFS = {
    english: {
        header: 'Step 5: Notification Preferences',
        sallie: "I'm scheduled to come get you every day.",
        dashboard: 'You can always change this on your dashboard or here.',
        reminder: 'Set your reminder time',
        week_of_month: {
            label: 'Week of month:',
            first: '1st',
            second: '2nd',
            third: '3rd',
            fourth: '4th',
            fifth: '5th'
        },
        day_of_week: {
            label: 'Day of week:',
            mon: 'Mon',
            tue: 'Tue',
            wed: 'Wed',
            thu: 'Thu',
            fri: 'Fri',
            sat: 'Sat',
            sun: 'Sun'
        }
    },
    spanish: {
        header: 'Paso 5: Preferencias de Notificaciones',
        sallie: 'Estoy programado para buscarte todos los días.',
        dashboard: 'Siempre puede cambiar esto en su panel o aquí.',
        reminder: 'Ponga su tiempo de recordatorio',
        week_of_month: {
            label: 'Semana del mes:',
            first: '1ra',
            second: '2da',
            third: '3ra',
            fourth: '4ta',
            fifth: '5ta'
        },
        day_of_week: {
            label: 'Día de la semana:',
            mon: 'Lun',
            tue: 'Mar',
            wed: 'Mie',
            thu: 'Jue',
            fri: 'Vie',
            sat: 'Sáb',
            sun: 'Dom'
        }
    }
}

//////////////////
// EmailSent.js //
//////////////////
export const EMAIL_SENT = {
    english: {
        header: 'Final Step: Email Confirmation',
        email_sent_one: 'We have sent an email to',
        email_sent_two: 'to make sure you own it. Please check your inbox to verify your email address within 24 hours!'
    },
    spanish: {
        header: 'Paso Final: Confimación de Correo Electrónico',
        email_sent_one: 'Le hemos enviado un correo electrónico a',
        email_sent_two: 'para asegurarnos que usted es el dueño. ¡Favor de verificar su correspondencia para comprobar su correo electrónico dentro de 24 horas!'
    }
}

export const LOGIN = {
    english: 'Log In',
    spanish: 'Iniciar sesión'
}

export const PAYNOW = {
    english: 'Pay Now',
    spanish: 'Paga ahora'
}