import React from 'react';
import Select from 'react-select';



const MyCustomDropdown = ({ options, onChange, uniqueUnreadUserIds }) => {

    const customStyles = {
        option: (provided, state) => {
            const backgroundColor = state.data && uniqueUnreadUserIds.includes(state.data.value) ? '#cfe8e6' : 'transparent';
            const color = state.isSelected ? 'black' : 'inherit';
            return {
                ...provided,
                backgroundColor,
                color,
                '&:hover': {
                    backgroundColor: 'lightblue',
                },
            };
        },
    };
    const defaultValue = options.length > 0 ? options[0] : null;

    return (
        <Select
            options={options}
            styles={customStyles}
            onChange={onChange}
            defaultValue={defaultValue}
        />
    );
};

export default MyCustomDropdown;
