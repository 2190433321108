import React, { useContext } from "react";
import { PreferredLanguageContext } from "../lib/main-context";
import { HELP_PAGE, OOPS } from "../constants/HelpPageTranslation";

import { useHistory } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import theme from "../index.scss";
import Sallie from "../images/Sallie-Oops.png";
import SubmitButton from "./StyledComponents/SubmitButton";

const Oops = () => {
  const history = useHistory();
  const { language } = useContext(PreferredLanguageContext);
  return (
    <Row
      style={{ color: theme.teal }}
      className="d-flex justify-content-center text-center"
    >
      <Col>
        <Row>
          <Col>
            <h2>{OOPS[language].header}</h2>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col xs={8} md={4}>
            <img
              style={{ objectFit: "cover", maxWidth: "100%" }}
              alt="Apologetic Dog"
              src={Sallie}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>{OOPS[language].subheading}</h4>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col md={6}>
            <p style={{ color: "initial" }}>
              {OOPS[language].body}
            </p>
          </Col>
        </Row>
        <Row className="d-flex flex-column align-items-center">
          <SubmitButton onClick={history.goBack}>{HELP_PAGE[language].go_back}</SubmitButton>
          <SubmitButton onClick={() => history.push("/help")}>
            {OOPS[language].request_help}
          </SubmitButton>
        </Row>
      </Col>
    </Row>
  );
};

export default Oops;
