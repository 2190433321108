import React from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ComponentWrapper = ({ fillColumn, centerContents, children, style }) => {
  return (
    <Row className={fillColumn ? "h-100" : ""}>
      <Col className="font-weight-bold pt-3 pb-3">
        <Container
            className={`componentWrapper${fillColumn ? " h-100" : ""}
             d-flex flex-column
            ${centerContents ? " d-flex flex-wrap justify-content-around text-center align-content-center" : ""}`
            }
            style={{ ...style }}
        >
          {children}
        </Container>
      </Col>
    </Row>
  );
};

export default ComponentWrapper;
