import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import OnboardingStepHeader from "./OnboardingStepHeader";
import Sallie from "../Sallie";
import SubmitButton from "../StyledComponents/SubmitButton";
import LoadingSpinner from "../LoadingSpinner";

import {
  checkEmailToken,
  sendConfirmEmail,
  deleteEmailToken,
} from "../../api/helpReq";

const EmailConfirm = ({ setOnboardingPercent, user }) => {
  const location = useLocation();
  const history = useHistory();
  const url = new URLSearchParams(location.search);
  const token = url.get("token");

  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const reSendEmail = () => {
    const { firstName, lastName, email } = user;
    sendConfirmEmail({
      email: email,
      name: firstName + " " + lastName,
    });
    history.push("/Verification/EmailSent");
  };

  const clickHandler = async () => {
    try {
      setSubmitting(true);
      deleteEmailToken();
      history.push("/");
    } catch (err) {
      setSubmitting(false);
      history.push("/oops");
    }
  };

  let sallieMsg =
    "Your email is verified now. Your TBI symptom tracking dashboard has been successfully created!";
  let errorMsg =
    "Sorry we could not verify your email. Please click the button below to send verification email again.";

  useEffect(() => {
    setOnboardingPercent(90);

    checkEmailToken({ token: token })
      .then((res) => {
        if (res.status !== 200) {
          setError(true);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log("token is not matched", e);
        setError(true);
        setLoading(false);
      });
  }, []);

  if (error) {
    sallieMsg = errorMsg;
  }

  return (
    <>
      <OnboardingStepHeader text="Step 7-b: Email Confirmation" />
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <>
          <Sallie text={sallieMsg} sad="true" />
          <SubmitButton disabled={submitting} onClick={reSendEmail}>
            Resend Now
          </SubmitButton>
        </>
      ) : (
        <>
          <Sallie text={sallieMsg} />
          <SubmitButton disabled={submitting} onClick={clickHandler}>
            Go to Dashboard
          </SubmitButton>
        </>
      )}
    </>
  );
};

export default EmailConfirm;
