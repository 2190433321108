import React, { useEffect, useContext } from "react";
import { ThemeContext } from "../lib/main-context";
import { useHistory } from "react-router-dom";
import { signOut } from "../api/auth";

const SignOut = ({ resetApp }) => {
  const history = useHistory();
  const { dark, toggleTheme } = useContext(ThemeContext);
  useEffect(() => {
    // if (dark) {
    //   toggleTheme();
    // }
    resetApp();
    signOut();
    history.replace("/signIn");
  });

  return <></>;
};

export default SignOut;
