import * as dotenv from "dotenv";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "date-input-polyfill";
import "core-js";

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import * as serviceWorker from "./serviceWorker";
dotenv.config();

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
