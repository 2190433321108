///////////////
// SignIn.js //
///////////////
export const SIGNIN = {
    english: {
        email: 'Email',
        password: 'Password',
        login: 'Log In',
        wrong_credenials: "Oops! that login doesn't seem to be right.",
        forgot_password: 'Forgot password? click ',
        new_user: 'New user? register ',
        here: 'HERE',
        no_account: "Don't have an account? Create one today!"
    },
    spanish: {
        email: 'Correo electrónico',
        password: 'Contraseña',
        login: 'Iniciar sesión',
        wrong_credenials: "¡Ups! ese inicio de sesión no parece estar correcto",
        forgot_password: '¿Olvidaste tu contraseña? haz clic ',
        new_user: '¿Eres un usuario nuevo? regístrate ',
        here: 'aquí',
        no_account: '¿No tiene una cuenta? ¡Cree una hoy!'
    }
}

///////////////////////
// ResetPassword1.js //
///////////////////////
export const SALLIE_RESET_PASSWORD = {
    english: {
        reset_password: "Let me help you reset your password! Please type in your email address you used when you register.",
        email_sent: 'An email with instructions on how to reset your password has been sent to ',
        no_account: 'No account with the provided email. Please check your email and try again.',
        error: 'An error occurred while sending the password reset email. Please try again later.'
    },
    spanish: {
        reset_password: "¡Déjame ayudarte a restablecer tu contraseña! Por favor, escribe la dirección de correo electrónico que usaste cuando te registraste.",
        email_sent: 'Se ha enviado un correo electrónico con instrucciones sobre cómo restablecer tu contraseña a ',
        no_account: 'No se encontró ninguna cuenta con el correo electrónico proporcionado. Por favor, revisa tu correo electrónico e inténtalo de nuevo.',
        error: 'Ocurrió un error al enviar el correo electrónico para restablecer la contraseña. Por favor, inténtalo de nuevo más tarde.'
    }
}

export const CONTINUE = {
    english: 'Continue',
    spanish: 'Continuar'
}
