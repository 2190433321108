import React, { useContext } from "react";
import { ThemeContext, PreferredLanguageContext } from "../../lib/main-context";
import { DOGHOUSE } from "../../constants/DashboardTranslation";

import theme from "../../index.scss";
import Doghouseimg from "../../images/doghouse.png";
import SallieSleepingGif from "../../images/sallie-sleeping-doghouse-remove-loop.gif";

import { updateEmailNotification } from "../../api/auth";

const Doghouse = ({ sallieSleep, setSallieSleep }) => {
    const { language } = useContext(PreferredLanguageContext);
    const { dark } = useContext(ThemeContext);

    const handleUpdateEmailNotification = async (sallieSleepValue) => {
        try {
            const res = await updateEmailNotification({ sallieSleep: sallieSleepValue });
            if (res.status === 200) {
                console.log('Email notification updated successfully.');
                setSallieSleep(!sallieSleepValue);
            } else {
                console.error('Failed to update email notification.');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleClickPause = () => {
        handleUpdateEmailNotification(false);
    };

    const handleClickStart = () => {
        handleUpdateEmailNotification(true);
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <img
                    id="img1"
                    src={sallieSleep ? SallieSleepingGif : Doghouseimg}
                    style={{
                        width: '50%',
                        height: '30%',
                        cursor: 'pointer', // Change cursor on hover
                        opacity: 1, // Initial opacity
                        transition: 'opacity 0.2s', // Add a smooth transition on hover
                    }}
                    onClick={sallieSleep ? handleClickStart : handleClickPause}
                    onMouseOver={() => {
                        // Reduce opacity on hover
                        document.getElementById('img1').style.opacity = 0.8;
                    }}
                    onMouseOut={() => {
                        // Restore opacity on mouse out
                        document.getElementById('img1').style.opacity = 1;
                    }}
                />
            </div>
            <div className="d-flex justify-content-center">
                <h6
                    className="pt-2 pb-2 pl-3 pr-3"
                    style={{
                        width: "100%",
                        borderRadius: "20px",
                        backgroundColor: dark ? theme.darkModePrimary : "#f7bc12",
                        color: dark ? theme.darkModeText : "white",
                        textAlign: "center",
                        overflowWrap: "break-word",
                        cursor: "pointer"
                    }}
                    onClick={sallieSleep ? handleClickStart : handleClickPause}
                >
                    {sallieSleep ? DOGHOUSE[language].start : DOGHOUSE[language].pause}
                </h6>
            </div>
        </>
    );
    return null;
};
export default Doghouse