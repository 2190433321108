import React from "react";
import DayPicker from "react-day-picker";
import ComponentWrapper from "./ComponentWrapper";

const Calendar = ({ selectedDays, onDayClick, modifiers }) => {
  const modifiersStyles = {
    tracked: {
      color: "white",
      backgroundColor: "green",
    },
    untracked: {
      color: "white",
      backgroundColor: "red",
    },
  };
  return (
    <ComponentWrapper>
      <DayPicker
        modifiers={modifiers}
        onDayClick={onDayClick}
        selectedDays={selectedDays}
        modifiersStyles={modifiersStyles}
        disabledDays={(day) => day > new Date()}
      />
    </ComponentWrapper>
  );
};

export default Calendar;
