import * as yup from 'yup'

const helpSchema = yup.object().shape({
  email: yup
    .string()
    .email('Valid email required')
    .required('Valid email required'),
  name: yup.string().required('Name required'),
  ticket: yup
    .string()
    .required('Please enter a description of the problem you are having'),
})

export default helpSchema
