import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sallie from "../Sallie";
import SubmitButton from "../StyledComponents/SubmitButton";

const Register = ({ setUser, user }) => {
  useEffect(() => {
    user.isVerified = true;
    setUser(user);
  }, []);

  const history = useHistory();
  const handleClick = () => {
    history.push("/");
  };

  return (
    <Row>
      <Col>
        <Sallie
          text="Your TBI symptom tracking dashboard has
              been successfully created!"
        />

        <SubmitButton type="button" onClick={handleClick}>
          Go to Dashboard
        </SubmitButton>
      </Col>
    </Row>
  );
};

export default Register;
