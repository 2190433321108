import React, { useContext, useState, useEffect } from "react";
import { ThemeContext, PreferredLanguageContext } from "../../lib/main-context";

import MelilloLogo from "../../images/MelilloLogo.png"
import Form from 'react-bootstrap/Form';

const Brand = () => {
  const [isChecked, setIsChecked] = useState(false);
  const { dark } = useContext(ThemeContext);
  const { language, updateLanguage } = useContext(PreferredLanguageContext);

  useEffect(() => {
    setIsChecked(language === 'spanish');
  }, [language]);

  const handleChange = () => {
    const newLanguage = isChecked ? 'english' : 'spanish';
    updateLanguage(newLanguage);
    setIsChecked(!isChecked);
  };

  return (
    <div className="brand-wrapper">
      <img
        src={MelilloLogo}
        className="logo"
        alt="Power of Patients brand-img"
      />
      <Form>
        <Form.Check
          type="switch"
          id="custom-switch"
          label={isChecked ? "Switch to English?" : "¿Cambiar al español?"}
          checked={isChecked}
          onChange={handleChange}
        />
      </Form>
    </div>
  );
};

export default Brand;
