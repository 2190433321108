import axios from "axios";
import apiUrl from "./apiConfig";

// 11/22/21 Not sure what this is or if it's necessary, but I'm keeping it because it was in TBIRequests
axios.defaults.withCredentials = true;

export const sendReferral = (data) => {
    return axios.post(`${apiUrl}/sendReferral`, data);
};

export const createReferralConnection = (data) => {
    return axios.post(`${apiUrl}/createReferralConnection`, data);
};

export const getReferralRecord = () => {
    return axios.get(`${apiUrl}/getReferralRecord`);
};

export const checkNewReferral = () => {
    return axios.get(`${apiUrl}/checkNewReferral`);
};

export const updateReferralStatus = ({ messageId }) => {
    return axios.put(`${apiUrl}/updateReferralStatus`, {}, {
        params: { messageId }
    });
};

// export const deleteMessage = ({ messageId }) => {
//     return axios.delete(`${apiUrl}/deleteMessage`, { params: { messageId } });
// }
