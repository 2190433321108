import { useState } from "react";
import capitalize from "./capitalize";
// to be used with ListSelector.js component
// MUST PASS IN INITIAL DATA AND SET FUNCTION FOR INITIAL DATA
const useListSelector = ({ setInitialData, initialData }) => {
  const [selectedData, setSelectedData] = useState({});

  // **MUST BE CALLED WITH RESULT OF API CALL, IN ARRAY FORM **
  const initialize = (updatedData) => {
    const init = {};
    updatedData.forEach((item) => {
      init[item.category] = [];
    });
    setSelectedData(init);
  };
  // ALLOWS ITEMS TO BE TOGGLED TO A MAX OF THEIR 'TYPE' ATTRIBUTE
  const toggleFromSelectedList = (
    clickedItem,
    list,
    category,
    max = Infinity
  ) => {
    const tmp = { ...selectedData };

    if (!selectedData[category].includes(clickedItem)) {
      if (list.length < max) {
        tmp[category].push(clickedItem);
        setSelectedData(tmp);
      }
    } else {
      tmp[category].splice(tmp[category].indexOf(clickedItem), 1);
      setSelectedData(tmp);
    }
  };
  // IF type # is '1', this function will be used
  const switchSingleSelection = (item, category) => {
    const tmp = { ...selectedData };
    tmp[category].push(item);
    tmp[category] = tmp[category].filter((selection) => {
      return selection === item;
    });
    setSelectedData(tmp);
  };
  // add to the original list, thus expanding the rendered components
  const addOther = (clickedItem, index) => {
    let added = false;
    const trimmedFormattedItem = capitalize(clickedItem.trim().toLowerCase());
    const trimmedUpperCaseItem = trimmedFormattedItem.toUpperCase();
    if (trimmedFormattedItem !== "") {
      const tmp = [...initialData];
      const workingList = tmp[index].items;
      const upperCaseWorkingList = workingList.map((item) =>
        item.toUpperCase()
      );
      if (!upperCaseWorkingList.includes(trimmedUpperCaseItem)) {
        workingList.push(trimmedFormattedItem);
        added = true;
      }

      setInitialData(tmp);
      if (added) return trimmedFormattedItem;
    }
  };

  return {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  };
};

export default useListSelector;
