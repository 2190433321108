import React, { useState } from 'react'
import Header from './Header'
import Brand from './Brand'


const HeaderWithBrand = ({ user, routeGroup, onboardingPercent }) => {
  return (
    <>

      <Brand />

      <Header
        user={user}
        onboardingPercent={onboardingPercent}
        routeGroup={routeGroup}
      />
    </>
  )
}

export default HeaderWithBrand
