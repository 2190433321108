import React, { useContext } from 'react';
import { Col, Form } from 'react-bootstrap';

import { PreferredLanguageContext } from '../../lib/main-context';
import { MESSAGES_AND_ALERTS } from '../../constants/OnboardingTranslation';

const PolicyComponent = ({ label, radioName, idYes, idNo, policyValue, setPolicyValue }) => {
    const { language } = useContext(PreferredLanguageContext);

    return (
        <Col md={12}>
            <Form.Group className="message-policy">
                <Form.Row>
                    <Col md={9}>
                        <Form.Label>{label}</Form.Label>
                    </Col>
                    <Col md={3}>
                        <div>
                            <Form.Check
                                inline
                                label={MESSAGES_AND_ALERTS[language].yes}
                                type="radio"
                                name={radioName}
                                id={idYes}
                                checked={policyValue === true}
                                onChange={() => setPolicyValue(true)}
                            />
                            <Form.Check
                                inline
                                label={MESSAGES_AND_ALERTS[language].no}
                                type="radio"
                                name={radioName}
                                id={idNo}
                                checked={policyValue === false}
                                onChange={() => setPolicyValue(false)}
                            />
                        </div>
                    </Col>
                </Form.Row>
            </Form.Group>
        </Col>
    );
};

export default PolicyComponent;