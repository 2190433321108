import React, { useContext, useState, useEffect } from "react";
import { ThemeContext, PreferredLanguageContext } from "../lib/main-context";
import { MESSAGES_HEADER, MESSAGES_TABLE, CLOSE } from "../constants/MessagesTranslation";

import { useHistory } from "react-router-dom";

import theme from "../index.scss";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import SubmitButton from "./StyledComponents/SubmitButton";
import ComponentWrapper from "./DashboardComponents/ComponentWrapper";
import SendMessage from "./DashboardComponents/SendMessage";
import MyCustomDropdown from './MyCustomDropdown'

import { updateMessageRead } from "../api/messageRequests";

import useWindowSize from "../lib/useWindowSize";

const Messages = ({ user, messages, setMessages, loadingMessages }) => {
    const { language } = useContext(PreferredLanguageContext);
    const { dark } = useContext(ThemeContext);

    const [curReceiverId, setCurReceiverId] = useState(user.messageRecipient[0].receiver_id)

    // provider can only view current dashboard patient's message
    let messagesForCurPatient = messages;
    messagesForCurPatient = messages?.filter((m) => {
        if (m.otherUserId == curReceiverId) {
            return true;
        } else {
            return false;
        };
    })

    const tenMessages = messagesForCurPatient;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const history = useHistory();
    const [width] = useWindowSize();

    const setMessageAsRead = ({ messageId }) => {
        updateMessageRead({ messageId })
            .then(() => {
                setMessages((originalMessages) => {
                    // Clone the array
                    const newMessages = JSON.parse(JSON.stringify(originalMessages));
                    const messageIndex = originalMessages.findIndex((message) => message.messageId === messageId);
                    newMessages[messageIndex].hasBeenRead = true;
                    return newMessages;
                });
            })
            .catch((err) => {
                console.error(err);
                history.push("/oops");
            });
    };

    let messagesDisplay;

    const otherUserName = message => message.otherUserFirstName + " " + message.otherUserLastName;

    // https://stackoverflow.com/questions/4833651/javascript-array-sort-and-unique
    // (Slightly modified)

    // function sortUnique(arr) {
    //     if (arr.length === 0) return arr;
    //     arr = arr.sort();
    //     var ret = [arr[0]];
    //     for (var i = 1; i < arr.length; i++) { //Start loop at 1: arr[0] can never be a duplicate
    //         if (arr[i - 1] !== arr[i]) {
    //             ret.push(arr[i]);
    //         }
    //     }
    //     return ret;
    // }

    const columns = [
        { dataField: 'messageId', hidden: "true" }, {
            dataField: 'sentOrReceived',
            text: MESSAGES_TABLE[language].sent_or_received.label,
            formatter: (value) => MESSAGES_TABLE[language].sent_or_received[value]
        }, {
            dataField: 'otherUserLastName',
            text: MESSAGES_TABLE[language].other_user,
            formatter: (value, row) => (
                row.otherUserFirstName + " " + row.otherUserLastName
            )
        }, {
            dataField: 'body',
            text: MESSAGES_TABLE[language].message
        }, {
            dataField: 'date',
            text: MESSAGES_TABLE[language].time
        }, {
            dataField: 'hasBeenRead',
            text: MESSAGES_TABLE[language].status.label,
            formatter: (hasBeenRead, row) => {
                return !hasBeenRead ? <SubmitButton centered onClick={() => {
                    setMessageAsRead({ messageId: row.messageId });
                }}>
                    {MESSAGES_TABLE[language].status.mark_as_read}
                </SubmitButton> : MESSAGES_TABLE[language].status.have_read
            }
        }
    ];

    const rowStyle2 = (row, rowIndex) => {
        const style = {};
        style.overflowWrap = "break-word";
        if (!row.hasBeenRead) {
            style.backgroundColor = '#cfe8e6';
        }
        return style;
    };

    if (width >= 768) {
        messagesDisplay = (
            tenMessages ? <BootstrapTable class="overflow-hidden" keyField='messageId' data={tenMessages} columns={columns} pagination={paginationFactory()} rowStyle={rowStyle2} /> : null
        )
    } else {
        messagesDisplay = tenMessages && tenMessages.map((message) => (
            <Row className="font-weight-normal">
                <Col className={`mb-3 border border-dark rounded${!message.hasBeenRead ? " table-info font-weight-bold" : ""}`}>
                    <h4 className="text-center">{otherUserName(message)}</h4>
                    <h4 className="text-center">{message.sentOrReceived === "sent" ? "Sent" : "Received"}</h4>
                    <h4 className="text-center">{message.date}</h4>
                    <p>{message.body}</p>
                    {!message.hasBeenRead &&
                        <SubmitButton centered onClick={() => {
                            setMessageAsRead({ messageId: message.messageId });
                        }}>
                            {MESSAGES_TABLE[language].status.mark_as_read}
                        </SubmitButton>
                    }
                </Col>
            </Row>
        ));
    }

    const handleDropdownChange = selectedOption => {
        const value = selectedOption ? selectedOption.value : '';
        setCurReceiverId(value);
    };

    const options = user?.messageRecipient?.map(r => ({
        value: r.receiver_id,
        label: `${r.first_name} ${r.last_name}`,
    }));

    const unreadUserIds = messages?.filter(message => !message.hasBeenRead)
        .map(message => message.otherUserId);

    // Deduplicate the unreadUserIds array using Set
    const uniqueUnreadUserIds = [...new Set(unreadUserIds)];

    return (
        <ComponentWrapper style={{ padding: "60px" }}>
            <h1 className="text-center">{MESSAGES_HEADER[language].header}</h1>
            <>
                <Modal className="d-flex align-items-center" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title> </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            {user.messageRecipient ?
                                <SendMessage
                                    curReceiverId={curReceiverId}
                                    messageRecipient={
                                        user.messageRecipient}
                                    userType={user.userType}
                                    fillColumn={true}
                                    loadMessages={loadingMessages}
                                /> : null}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {CLOSE[language]}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            <Row>
                <Col className={`d-flex flex-wrap align-items-end ${width >= 768 ? "justify-content-start" : "justify-content-center"}`}>
                    <h6
                        className="pt-2 pb-2 mb-4"
                        style={{
                            width: "90%",
                            borderRadius: "20px",
                            backgroundColor: dark ? theme.darkModePrimary : "#3EB1A6",
                            color: dark ? theme.darkModeText : "white",
                            textAlign: "center",
                            overflowWrap: "break-word",
                            cursor: "pointer"
                        }}
                        onClick={() => history.push("/")}
                    >
                        {MESSAGES_HEADER[language].dashboard}
                    </h6>
                </Col>
                <Col className={`d-flex flex-wrap align-items-end ${width >= 768 ? "justify-content-start" : "justify-content-center"}`}>
                    <h6
                        className="pt-2 pb-2 mb-4"
                        style={{
                            width: "90%",
                            borderRadius: "20px",
                            backgroundColor: dark ? theme.darkModePrimary : "#3EB1A6",
                            color: dark ? theme.darkModeText : "white",
                            textAlign: "center",
                            overflowWrap: "break-word",
                            cursor: "pointer",
                        }}
                        onClick={handleShow}
                    >
                        {MESSAGES_HEADER[language].send_messages}
                    </h6>
                </Col>
                <Col className="align-items-end">
                    <div className="mb-4">
                        <h6>{MESSAGES_HEADER[language].select_receiver}</h6>
                        <MyCustomDropdown options={options} onChange={handleDropdownChange} uniqueUnreadUserIds={uniqueUnreadUserIds} />
                    </div>

                    {/* <Form.Group controlId="formBasicSelect" className="mb-4">
                        <Form.Label>Select Receiver</Form.Label>
                        <Form.Control
                            className="message-form"
                            as="select"
                            onChange={e => {
                                const selectedOption = e.target.selectedOptions[0];
                                const value = selectedOption.getAttribute('otherUserId');
                                setCurReceiverId(value);
                            }}
                        >
                            {user.messageRecipient.map((r, index) => {
                                return (
                                    <option otherUserId={r.receiver_id} key={index} className="option-bg" >
                                        {r.first_name + " " + r.last_name}
                                    </option>
                                );
                            })}
                        </Form.Control>
                    </Form.Group> */}

                </Col>
            </Row>
            {messagesDisplay}
        </ComponentWrapper >
    )
}

export default Messages;