const splitVideoGroup = (videoData) => {
  const splitedVideoGroup = [[], []];
  if (videoData) {
    videoData.map((video, index) => {
      if (index % 2 === 0) {
        splitedVideoGroup[0].push(video);
      } else {
        splitedVideoGroup[1].push(video);
      }
    });
  }
  return splitedVideoGroup;
};

export default splitVideoGroup;
