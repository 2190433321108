import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div>
      404: Not found, click <Link to="/">here</Link> to go home
    </div>
  )
}

export default PageNotFound
