import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import SubmitButton from "../StyledComponents/SubmitButton";

const Warning = ({ user }) => {
  const location = useLocation();
  const history = useHistory();
  const symptom = location.state.symtom;

  if (!user || !symptom) history.goBack();
  return (
    <>
      <h3>Hi {user.firstName},</h3>
      <p>
        I noticed that your last 3 logs have had a decrease in your {symptom}{" "}
        score of at least 30%. Because we are helping you track your symptoms
        and triggers as you recover from head trauma, we want to be sure you are
        aware of this change.
      </p>
      <p>
        If you have noticed your symptoms are getting worse, you may want to
        inform your caregiver and maybe contact your doctor or healthcare
        provider.
      </p>
      <p>
        Head trauma is a serious condition and if any of your symptoms are
        becoming more extreme or excessive, we recommend that you immediately be
        evaluated by a medical professional.
      </p>
      <p>
        Know that you can call 911 or your local emergency number if you are
        experiencing any of the following signs or symptoms as they may indicate
        something more serious is occurring.
      </p>

      <h3>Adults</h3>
      <ul>
        <li> Severe headache</li>
        <li> Change in level of consciousness for more than a few seconds</li>
        <li> Black-and-blue discoloration below the eyes or behind the ears</li>
        <li>Cessation of breathing</li>
        <li>Confusion</li>
        <li>Loss of balance</li>
        <li>Weakness or an inability to use an arm or leg</li>
        <li>Unequal pupil size</li>
        <li>Slurred speech</li>
        <li>Seizures</li>
        <li>Severe head or facial bleeding</li>
        <li>Bleeding or fluid leakage from the nose or ears</li>
      </ul>

      <h3>Children</h3>
      <span>Any of the signs or symptoms for adults above, and </span>
      <ul>
        <li>Persistent crying</li>
        <li>Refusal to eat</li>
        <li>Bulging in the soft spot on the front of the head (infants)</li>
        <li>Repeated vomiting</li>
      </ul>
      <SubmitButton onClick={() => history.push("/")}>Go Back</SubmitButton>
    </>
  );
};

export default Warning;
