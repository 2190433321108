import React, { useContext } from "react";
import { ThemeContext, PreferredLanguageContext } from "../lib/main-context";
import { NO_TRACKING_DATA, TOP_SYMPTOMS } from "../constants/DashboardTranslation";

import theme from "../index.scss";

import ReactECharts from 'echarts-for-react';

const MedicalBarChart = ({ data, userName, splitView }) => {
    const { language } = useContext(PreferredLanguageContext);
    const { dark } = useContext(ThemeContext);
    const result = [];
    const map = new Map();
    const medicalData = data?.filter(obj => obj.symptom_id !== 53)
    medicalData.forEach(item => {
        if (!map.has(item.factor)) {
            map.set(item.factor, { name: item.factor, value: 0, count: 0 });
        }
        const factor = map.get(item.factor);
        factor.value += item.severity;
        factor.count++;
    });
    map.forEach((value, key) => {
        value.value = (value.value / value.count).toFixed(1);
        result.push(value);
    });
    const sorted = result.sort((a, b) => b.value - a.value);
    const top5 = sorted.slice(0, 5);
    const allZeros = top5.every(item => item.value === 0);

    const option = {
        title: {
            text: TOP_SYMPTOMS[language],
            left: 'center',
            textStyle: {
                fontSize: '1.2rem',
                color: dark ? theme.darkModeText : theme.black,
            },
            subtext: allZeros ? NO_TRACKING_DATA[language][0] : `${splitView ? `(${userName})` : ""}`,
            subtextStyle: {
                fontSize: '1.3rem',
                color: dark ? theme.darkModeText : theme.primary,
            },
        },
        tooltip: {
            trigger: 'item',

        },
        xAxis: {
            type: 'value',
            axisLabel: {
                fontSize: 16, // Change the font size here
                color: dark ? theme.darkModeText : theme.black,
            },
        },
        grid: {
            left: '5%',   // Adjust the left padding here
            containLabel: true
        },
        yAxis: {
            type: 'category',
            axisLabel: {
                fontSize: 16, // Change the font size here
                color: dark ? theme.darkModeText : theme.black,
            },
            // offset: '20',
            // axisLabel: { interval: 0, rotate: 10 },
            data: top5.map(item => item.name).reverse() // reverse to show top elements at top
        },
        series: [
            {
                data: top5.map(item => item.value).reverse(),
                type: 'bar'
            }
        ]
    };
    return (
        <ReactECharts option={option} style={{ minHeight: "25vh" }} />
    )
}

export default MedicalBarChart;
