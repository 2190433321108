const stringifyDayOfWeek = (data) => {
  let weekArr = new Array(7);
  weekArr[0] = data.sunday;
  weekArr[1] = data.monday;
  weekArr[2] = data.tuesday;
  weekArr[3] = data.wednesday;
  weekArr[4] = data.thursday;
  weekArr[5] = data.friday;
  weekArr[6] = data.saturday;

  const weekString = weekArr.toString();

  return weekString;
};

export default stringifyDayOfWeek;
