import { jsPDF } from "jspdf";
import "jspdf-autotable";
import getDaysAgo from "./getDaysAgo";
import { parse, format } from "date-fns";
import logo from "../images/Logo.png";
import html2canvas from 'html2canvas';


// This function is for creating pdf file using jsPDF.
// Currently we include a chart as a image and all the symptom notes in a table format based on selected chart.
// For more info about jsPDF, please visit jsPDF documentation: http://raw.githack.com/MrRio/jsPDF/master/docs/index.html

const exportToPdfReport = async (
    chart,
    data,
    days,
    anotherDataForComparasion,
    additionalNotes,
    patient,
    withoutNote
) => {

    // getting symptom data based on selected table (3 day, 7 day, or 30 day)
    // if the withoutNote option is true, omit the symptom description.
    const dataForDays = data
        .filter((item) => new Date(item.symptom_date) > getDaysAgo(days))
        .map((item) => {
            return {
                ...item,
                date: format(new Date(item.symptom_date), "MM-dd-yyyy"),
                description: withoutNote ? null : item.description,
            };
        });

    let tableBody = dataForDays.slice();
    const dataForDaysAnother = anotherDataForComparasion.length != 0
        ? anotherDataForComparasion
            .filter(item => new Date(item.symptom_date) > getDaysAgo(days))
            .map(item => ({
                ...item,
                date: format(new Date(item.symptom_date), "MM-dd-yyyy"),
                description: withoutNote ? null : item.description,
            }))
        : [];

    let tableBodyAnother = dataForDaysAnother.slice();

    if (!withoutNote) {
        // this if block for appending additional notes under each date's symptom data.
        // if withoutNote is true, omit additional notes.
        dataForDays.forEach((data, i) => {
            let tempDate = data.symptom_date;
            if (
                i === dataForDays.length - 1 ||
                tempDate !== dataForDays[i + 1].symptom_date
            ) {
                let notesForTempDate = additionalNotes.filter(
                    (item) => item.additional_notes_date === tempDate
                );
                notesForTempDate.forEach((item) => {
                    const additionalNoteDate = format(
                        new Date(item.additional_notes_date),
                        "MM-dd-yyyy"
                    );
                    let transformedNote = {
                        date: additionalNoteDate,
                        factor: "Additional Comments for " + additionalNoteDate,
                        severity: null,
                        description: item.note,
                    };
                    tableBody.splice(
                        i + 1 + tableBody.length - dataForDays.length,
                        0,
                        transformedNote
                    );
                    if (tableBodyAnother.length !== 0) {
                        const insertIndex = i + 1 + tableBodyAnother.length - dataForDays.length;
                        tableBodyAnother.splice(insertIndex, 0, transformedNote);
                    }

                });
            }
        });
    }


    // converting html to png type
    // initiating pdf file
    const pdf = new jsPDF("l", "pt");

    const pageWidth = pdf.internal.pageSize.getWidth();
    // Y index where chart will be placed
    let chartStartY = 125;

    pdf.setFont("helvetica", "bold");
    pdf.setTextColor(33, 33, 33);
    pdf.setFontSize(12);

    const inputArray = chart.map((className, index) => {
        const elements = window.document.getElementsByClassName(className);
        if (elements.length === 0) {
            return; // Skip this element
        } else {
            return elements[0].lastChild;
        }
    }).filter(element => element !== undefined);

    const canvasArray = await Promise.all(inputArray.map(async (item) => {
        return await html2canvas(item);
    }));

    const imgArray = canvasArray.map((canvas, index) => canvas.toDataURL('image/png'));
    // Calculate the available height for content on each page
    let availableHeight = pdf.internal.pageSize.getHeight() - chartStartY;

    let curY = chartStartY;
    let remainingCharts = imgArray.length;
    let currentPage = 1;

    imgArray.forEach((item, index) => {
        const chartWidth = inputArray[index].clientWidth * 0.7;
        const chartHeight = inputArray[index].clientHeight * 0.7;

        // Check if the current chart can fit on the current page
        if (chartHeight <= availableHeight) {
            pdf.addImage(
                item,
                'png',
                (pageWidth - chartWidth) / 2,
                currentPage == 1 ? curY + 20 : curY,
                chartWidth,
                chartHeight
            );

            curY += chartHeight;
            remainingCharts--;
        } else {
            // Move to the next page if the chart cannot fit
            pdf.addPage();
            currentPage++;
            curY = chartStartY;
            availableHeight = pdf.internal.pageSize.getHeight() - chartStartY + 150;

            // Check if the chart can fit on the new page
            if (chartHeight <= availableHeight) {
                pdf.addImage(
                    item,
                    'png',
                    (pageWidth - chartWidth) / 2,
                    curY,
                    chartWidth,
                    chartHeight
                );

                curY += chartHeight;
                remainingCharts--;
            }
        }

        // Add a new page if there are remaining charts and the current page is full
        if (remainingCharts > 0 && curY + chartHeight + 30 > pdf.internal.pageSize.getHeight()) {
            pdf.addPage();
            currentPage++;
            curY = chartStartY - 40;
            availableHeight = pdf.internal.pageSize.getHeight() - chartStartY - 100;
        }
    });


    //detail table config
    const dataWithDesc = {
        columns: [
            { header: "Date", dataKey: "date" },
            { header: "Symptom", dataKey: "factor" },
            { header: "Severity", dataKey: "severity" },
            { header: "Description", dataKey: "description" },
        ],
        body: tableBody,
        startY: 80,
        margin: { top: 140 },
    };

    const dataWithDescAnother = {
        columns: [
            { header: "Date", dataKey: "date" },
            { header: "Symptom", dataKey: "factor" },
            { header: "Severity", dataKey: "severity" },
            { header: "Description", dataKey: "description" },
        ],
        body: tableBodyAnother,
        startY: 80,
        margin: { top: 140 },
    };

    //adding table
    pdf.setFont("helvetica", "bold");
    pdf.addPage();
    pdf.text("DETAILS", 40, 60);
    pdf.autoTable(dataWithDesc);
    (anotherDataForComparasion.length != 0) && pdf.addPage();
    (anotherDataForComparasion.length != 0) && pdf.text("COMPARISON DETAILS", 40, 60);
    (anotherDataForComparasion.length != 0) && pdf.autoTable(dataWithDescAnother);

    //header and footer for each page
    var totalPage = pdf.internal.getNumberOfPages();
    for (var i = 1; i <= totalPage; i++) {
        pdf.setPage(i);
        pdf.addImage(logo, "png", 710, 10, 120, 50);

        pdf.setFillColor(61, 37, 102);
        // pdf.rect(30, 60, 800, 3, "F");
        // pdf.rect(30, 60, 800, 1, "F");

        pdf.setFontSize(17);

        //header
        pdf.text(
            "Summary report for " +
            format(getDaysAgo(days - 1), "MMM dd") +
            " - " +
            format(new Date(), "MMM dd, yyyy"),
            30,
            40
        );

        //footer
        pdf.setFontSize(12);
        pdf.textWithLink("powerofpatients.com", 40, 570, {
            url: "https://www.powerofpatients.com",
        });
        pdf.text("page: " + i + " / " + totalPage, 750, 570);
    }

    // saving pdf to local machine
    pdf.save(`${chart}.pdf`);
};

export default exportToPdfReport;
