import React, { useState, useContext, useEffect } from "react";
import { ThemeContext, PreferredLanguageContext } from "../../lib/main-context";
import { REFERRAL_RECORD_BUTTON } from "../../constants/DashboardTranslation";

import { useHistory } from "react-router-dom";

import theme from "../../index.scss";
import LoadingSpinner from "../../Components/LoadingSpinner";

import { checkNewReferral } from "../../api/referralRequest"

const OnOffButtonReferralRecord = () => {
    const { dark } = useContext(ThemeContext);
    const { language } = useContext(PreferredLanguageContext);
    const [loading, setLoading] = useState(false);
    const [newReferral, setNewReferral] = useState(false);
    const history = useHistory();

    useEffect(() => {
        // Set up a flag to track whether the component is still mounted.
        setLoading(true)
        let isComponentMounted = true;

        // Function to check for new referrals
        const checkForNewReferral = async () => {
            try {
                const response = await checkNewReferral();
                if (isComponentMounted) {
                    // Only update state if the component is still mounted.
                    setNewReferral(response.data.hasNewReferral === true);
                    setLoading(false);
                }
            } catch (error) {
                if (isComponentMounted) {
                    console.error('Error checking for new referral:', error);
                    setLoading(false);
                }
            }
        };

        // Call the checkForNewReferral function
        checkForNewReferral();

        // Cleanup function to set isComponentMounted to false when unmounted
        return () => {
            isComponentMounted = false;
        };
    }, []); // Empty dependency array to run this effect only once

    const onClick = () => {
        setLoading(true)
        history.push("./PatientReferralRecord")
    }
    return (
        <div className="d-flex justify-content-center pt-2 pb-2 pl-1 pr-1">
            <h6
                className="pt-2 pb-2 pl-3 pr-3"
                style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "20px",
                    backgroundColor: dark ? theme.darkModePrimary : (newReferral ? "#3EB1A6" : "#ef5866"),
                    color: dark ? theme.darkModeText : "white",
                    textAlign: "center",
                    overflowWrap: "break-word",
                    cursor: "pointer"
                }}
                onClick={() => onClick()}
            >
                {newReferral ? (
                    <div>
                        {loading ? <LoadingSpinner /> : REFERRAL_RECORD_BUTTON[language].new_referred}
                    </div>
                ) : (
                    <div>
                        {loading ? <LoadingSpinner /> : REFERRAL_RECORD_BUTTON[language].check_referred}
                    </div>
                )}
            </h6>
        </div>
    )
}

export default OnOffButtonReferralRecord