import React, { useEffect, useState, useContext } from "react";
import { PreferredLanguageContext } from "../lib/main-context";
import { MEDIA_PAGE, GO_BACK } from "../constants/DashboardTranslation";

import { useHistory } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap/";

import Sallie from "./Sallie";
import SubmitButton from "./StyledComponents/SubmitButton";

import useWindowSize from "../lib/useWindowSize";
import splitVideoGroup from "../lib/splitVideoGroup";

import { getYoutubeVideos } from "../api/helpReq";

const MediaPage = () => {
  const { language } = useContext(PreferredLanguageContext);
  const history = useHistory();
  const [width] = useWindowSize();
  const [videos, setVideos] = useState([]);

  let SallieText = MEDIA_PAGE[language].sallie;

  useEffect(() => {
    getYoutubeVideos().then((res) => {
      const splitedVideos = splitVideoGroup(res.items);
      setVideos(splitedVideos);
    });
  }, []);

  const youtubeVideoFormat = (videoId, size, title, description) => {
    let width = size === "sm" ? "300" : "380";
    let height = size === "sm" ? "180" : "230";
    return (
      <>
        <iframe
          width={width}
          height={height}
          src={"https://www.youtube.com/embed/" + videoId}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <span>
          <h5>{title}</h5>
          <p
            style={{
              overflow: "hidden",
              width: width + "px",
              height: "50px",
            }}
          >
            {description}
          </p>
        </span>
      </>
    );
  };

  return (
    <>
      <Col>
        <Sallie text={SallieText} />
      </Col>
      <Col>
        <h4 className="mt-4 mb-4">{MEDIA_PAGE[language].media}</h4>
      </Col>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={width >= 1000 ? 5 : 6}>
            {videos.length > 0 && videos[0].length > 0 ? (
              videos[0].map((video) => {
                return youtubeVideoFormat(
                  video.id.videoId,
                  width >= 1000 ? "md" : "sm",
                  video.snippet.title,
                  video.snippet.description
                );
              })
            ) : (
              <>
                {youtubeVideoFormat(
                  "sMb3Ad8IUwU",
                  width >= 1000 ? "md" : "sm",
                  MEDIA_PAGE[language].videos.webinar_two.title,
                  MEDIA_PAGE[language].videos.webinar_two.description
                )}

                {youtubeVideoFormat(
                  "IPsQY8CEX54",
                  width >= 1000 ? "md" : "sm",
                  MEDIA_PAGE[language].videos.tbi_pcs.title,
                  MEDIA_PAGE[language].videos.tbi_pcs.description
                )}
                {youtubeVideoFormat(
                  "1gi9RRGbybU",
                  width >= 1000 ? "md" : "sm",
                  MEDIA_PAGE[language].videos.PoP_jul15.title,
                  MEDIA_PAGE[language].videos.PoP_jul15.description
                )}
                {youtubeVideoFormat(
                  "kK0kdgU3ws4",
                  width >= 1000 ? "md" : "sm",
                  MEDIA_PAGE[language].videos.important_questions.title,
                  MEDIA_PAGE[language].videos.important_questions.description
                )}
              </>
            )}
          </Col>

          <Col md={width >= 1000 ? 5 : 6}>
            {videos.length > 0 && videos[1].length > 0 ? (
              videos[1].map((video) => {
                return youtubeVideoFormat(
                  video.id.videoId,
                  width >= 1000 ? "md" : "sm",
                  video.snippet.title,
                  video.snippet.description
                );
              })
            ) : (
              <>
                {youtubeVideoFormat(
                  "pqnG68YdgsI",
                  width >= 1000 ? "md" : "sm",
                  MEDIA_PAGE[language].videos.webinar_one.title,
                  MEDIA_PAGE[language].videos.webinar_one.description
                )}
                {youtubeVideoFormat(
                  "wS0zkMmYgcA",
                  width >= 1000 ? "md" : "sm",
                  MEDIA_PAGE[language].videos.PoP_dashboard_demo.title,
                  MEDIA_PAGE[language].videos.PoP_dashboard_demo.description
                )}
                {youtubeVideoFormat(
                  "ucYTiqxpYG8",
                  width >= 1000 ? "md" : "sm",
                  MEDIA_PAGE[language].videos.clinical_trial_5min.title,
                  ""
                )}
                {youtubeVideoFormat(
                  "2nEqKcSNfJ0",
                  width >= 1000 ? "md" : "sm",
                  MEDIA_PAGE[language].videos.clinical_trial.title,
                  MEDIA_PAGE[language].videos.clinical_trial.description
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
      <SubmitButton onClick={() => history.push("/")}>{GO_BACK[language]}</SubmitButton>
    </>
  );
};

export default MediaPage;
