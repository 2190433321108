import React, { useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { FEELING_TODAY } from "../../constants/DashboardTranslation";

import { Link } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ComponentWrapper from "./ComponentWrapper";
import FeelingGood from "../../images/feeling-good.svg";
import FeelingNeutral from "../../images/feeling-neutral.svg";
import FeelingBad from "../../images/feeling-bad.svg";

import { postFeelingToday } from "../../api/TBIRequests";

const FeelingToday = ({ setDoneSimpleTracking }) => {
  const { language } = useContext(PreferredLanguageContext);
  const { patient } = useContext(PatientContext);

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const date = new Date();

  const submitFeeling = (num) => {
    setSubmitting(true);
    const feelingData = {
      severity: num,
      date: date,
    };
    postFeelingToday({
      patientId: patient.patientId,
      data: feelingData,
    })
      .then(() => {
        setDoneSimpleTracking(true);
        setError(false);
        setSubmitting(false);
      })
      .catch(() => {
        setError(true);
        setSubmitting(false);
      });
  };

  const handleKeyPress = (e, num) => {
    if (e.key === "Enter" || e.key === " ") {
      submitFeeling(num);
    }
  };

  const imgStyle = { cursor: "pointer" };

  return submitting ? (
    <ComponentWrapper>{FEELING_TODAY[language].submit}</ComponentWrapper>
  ) : (
    <ComponentWrapper>
      <Row>
        <Col md={6} className="d-flex  text-center align-items-lg-center">
          {error && (
            <>
              <div>
                {FEELING_TODAY[language].error[0]}{" "}
                <Link to="/help">{FEELING_TODAY[language].error[1]}</Link>
                {FEELING_TODAY[language].error[2]}
              </div>
            </>
          )}
          <div className="ml-2">
            {FEELING_TODAY[language].label[0]}{" "}
            {/* {patient &&
              (patient.userPatientRelationshipType === "self"
                ? "are you"
                : "is " + patient.firstName)}{" "} */}
            {patient && patient.firstName}{" "}
            {FEELING_TODAY[language].label[1]}
          </div>
        </Col>
        <Col xs={4} md={2}>
          <div
            role="button"
            tabIndex="0"
            className="text-center"
            onClick={() => submitFeeling(100)}
            onKeyPress={(e) => handleKeyPress(e, 0)}
          >
            <img src={FeelingGood} style={imgStyle} alt="happy face" />
            <div>{FEELING_TODAY[language].mood.good}</div>
          </div>
        </Col>
        <Col xs={4} md={2}>
          <div
            role="button"
            tabIndex="0"
            className="text-center"
            onClick={() => submitFeeling(50)}
            onKeyPress={(e) => handleKeyPress(e, 0)}
          >
            <img src={FeelingNeutral} alt="neutral face" style={imgStyle} />
            <div>{FEELING_TODAY[language].mood.neutral}</div>
          </div>
        </Col>
        <Col xs={4} md={2}>
          {/* role, tabindex, and keypress are to preserve accessibility */}
          <div
            role="button"
            tabIndex="0"
            className="text-center"
            onKeyPress={(e) => handleKeyPress(e, -1)}
            onClick={() => submitFeeling(10)}
          >
            <img src={FeelingBad} alt="sad face" style={imgStyle} />
            <div>{FEELING_TODAY[language].mood.bad}</div>
          </div>
        </Col>
      </Row>
    </ComponentWrapper>
  );
};

export default FeelingToday;
