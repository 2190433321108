/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect } from "react";
import { PatientContext } from "../lib/main-context";
import { Route, Redirect, useHistory } from "react-router-dom";
import { getPaymentStatus } from "../api/paymentRequest";

const AuthenticatedRoute = ({ user, children, ...rest }) => {
  const { patient } = useContext(PatientContext);
  const history = useHistory();

  // Redirect to "patientControl" if the user is a "provider" (not "noPatient")
  // or if the user is a "caregiver" without a patient
  useEffect(() => {
    if (
      user &&
      !patient &&
      ((user.userType === "provider" &&
        user.noPatientProvider !== "noPatient") ||
        user.userType === "caregiver")
    ) {
      const currentLocation = history.location.pathname;
      if (currentLocation !== "/patientControl") {
        history.push("/patientControl");
      }
    }

    const checkPaymentStatus = async () => {
      if (user) {
        if (user.userType === "provider") {
          const status = await getPaymentStatus({ userId: user.userId });
          if (!status.data.isPaid && !status.data.isVerified) {
            history.push("/PaymentPage");
          }
        }
      }
    };

    checkPaymentStatus();
  }, [user, patient]);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: "/signIn",
                state: { from: location },
              }}
            />
          );
        }
        return children;
      }}
    />
  );
};

export default AuthenticatedRoute;
