import React, { useContext, useState } from "react";
import { PreferredLanguageContext } from "../../lib/main-context";
import { SEND_MESSAGES, MESSAGES_TABLE } from "../../constants/MessagesTranslation";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import sendMessageSchema from "./validations/sendMessageSchema";

import { Col, Form, Row } from "react-bootstrap";
import ComponentWrapper from "./ComponentWrapper";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";
import Button2 from "../StyledComponents/Button2";

import { sendMessage } from "../../api/messageRequests";

// messageRecipientType only has meaning for providers
const SendMessage = ({ fillColumn, messageRecipient, messageRecipientType, userType, loadMessages, curReceiverId }) => {
  const filteredRecipients = messageRecipient.filter((recipient) => {
    return recipient.receiver_id === curReceiverId;
  });

  const { language } = useContext(PreferredLanguageContext);
  const [patientMessageRecipient, setPatientMessageRecipient] = useState("");
  const [name, setName] = useState("");
  const [inputVisible, setInputVisible] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(sendMessageSchema),
  });

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    data.recipientId = patientMessageRecipient
    sendMessage(data)
      .then(() => {
        setMessageSent(true);
        setInputVisible(false);
        // loadMessages();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  return (
    <ComponentWrapper fillColumn={fillColumn}>
      <Row className="p-2">
        <Col>
          <span className="text-center">
            {SEND_MESSAGES[language].label}
          </span>
        </Col>
      </Row>
      <Row className={`p-2 ${fillColumn && !inputVisible ? " h-100" : ""}`}>
        <Col className="d-flex flex-column flex-wrap justify-content-around text-center">
          <div
          >
            {filteredRecipients.map((recipient) => {
              return <Button2
                onClick={() => {
                  setInputVisible(true);
                  setMessageSent(false);
                  setPatientMessageRecipient(recipient.receiver_id);
                  setName(recipient.first_name + ' ' + recipient.last_name);
                }}
              >
                {SEND_MESSAGES[language].send_message_to}{recipient.first_name + " " + recipient.last_name}
              </Button2>
            })}
          </div>
          {patientMessageRecipient ? <Button2
            onClick={() => {
              setPatientMessageRecipient("");
              setInputVisible(false);
            }}
          >
            {SEND_MESSAGES[language].back_to_selection}
          </Button2>
            : null}


        </Col>
      </Row>
      {inputVisible &&
        <Row className="p-2">
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="p-2">
                <Col>
                  <LabelledInput
                    required
                    label={MESSAGES_TABLE[language].message}
                    name="body"
                    errors={errors}
                    inputRef={register}
                    inputAs="textarea"
                    rows={3}
                  />
                  <SubmitButton
                    type="submit"
                    disabled={submitDisabled}
                  >
                    {SEND_MESSAGES[language].send}
                    {/* message to {userType === "provider" ? "caregiver" : "HCP"} */}
                  </SubmitButton>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      }
      {messageSent &&
        <Row className="p-2">
          <Col className="text-center">
            {SEND_MESSAGES[language].message_sent} {name}.
          </Col>
        </Row>
      }
    </ComponentWrapper>
  );
};

export default SendMessage;
