import React, { useState, useContext } from "react"
import { PreferredLanguageContext } from "../lib/main-context";
import { PRIVACY_POLICY } from "../constants/PrivacyPolicyTranslation";
import spanishTOS from "../constants/PofPTermsOfUseSpanish.pdf";

import SubmitButton from "./StyledComponents/SubmitButton";
const PrivacyPagePatient = ({ onClick, style, fromPaymentpage, firstName, lastName, goback }) => {
    const [signed, setSigned] = useState(false);
    const { language } = useContext(PreferredLanguageContext);
    return (
        <>
            {language == 'spanish' ?
                (<div>
                    <iframe
                        src={spanishTOS}
                        type="application/pdf"
                        width="100%"
                        style={{ height: '63rem' }}
                    />
                    <div style={{ border: '1px solid #000', padding: '10px', margin: '10px 0' }}>
                        <h4>{PRIVACY_POLICY[language].signBoxHeading}</h4>
                        <p>
                            {PRIVACY_POLICY[language].signBoxText}
                        </p>
                        {!fromPaymentpage && <div style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <SubmitButton onClick={() => { setSigned(true) }} style={{ fontSize: '1.25rem' }}>{PRIVACY_POLICY[language].signButton}</SubmitButton>
                            <div style={{
                                borderBottom: '1px solid #000',
                                marginBottom: '0px',
                                paddingBottom: '5px', // Adjust as needed
                                paddingLeft: '10px',
                                width: '150px',
                            }}>{firstName + " " + lastName}</div>
                        </div>}
                        {!fromPaymentpage && (firstName || lastName) && <SubmitButton onClick={onClick} disabled={!signed} greyout={!signed} style={{ fontSize: '1.25rem' }}>{PRIVACY_POLICY[language].agreeButton}</SubmitButton>}
                        {!fromPaymentpage && !firstName && !lastName && <SubmitButton onClick={goback} style={{ fontSize: '1.25rem' }}>{PRIVACY_POLICY[language].goBackButton}</SubmitButton>}
                    </div >
                </div>)
                :
                (<div style={style}>
                    <h3>
                        Power of Patients <br />
                        ONLINE USER AGREEMENT <br />
                        (Patients and Caregiver)
                    </h3>
                    <p>Last Updated: Nov 13, 2023</p>
                    <p> Welcome!</p>
                    <h4>1.  INTRODUCTION</h4>
                    <p>
                        This Online User Agreement (the "Agreement") applies to your use of <strong>Product</strong>.  The term " <strong>Product</strong>" includes any Power of Patients (Company”) branded materials and apps that we offer, as well as any related Product such as third-party apps and products that we make available to members including any updates that may be delivered to the Subscriber by the Company. Subject to the terms of this Agreement, the Company will provide to you online use via the Internet of the Product as more fully set forth below.
                    </p>
                    <p>
                        By agreeing to this Agreement and/or by using the Product, you are also agreeing to the terms of the Privacy Policy www powerofpatients.com/privacy and the Terms of Use www.powerofpatients.com /terms, both of which are incorporated into and deemed a part of this Agreement. The same rules that apply regarding changes and revisions of this agreement also apply to changes and revisions of the Privacy Policy and the Terms of Use.  It is important that you read and understand both the Privacy Policy and the Terms of Use.
                    </p>

                    <h4>
                        2. GRANT OF LICENSE; NOTICES  </h4>
                    <p>
                        2.1	Subject to the terms and conditions of this Agreement and the Terms of Use, during the Term, the Company hereby grants you a non-exclusive, non-transferable license right to access and use the Product.  No other rights are granted unless expressly set forth herein.
                    </p>

                    <p>
                        2.2	NOTICES.
                    </p>
                    <p>
                        <strong>IF YOU ARE THINKING ABOUT SUICIDE OR CONSIDERING HARMING YOURSELF OR OTHERS OR IF YOU BELIEVES OR HAS DETERMINED THAT ANY OTHER PERSON MAY BE IN ANY DANGER OR IF YOU HAVE ANY MEDICAL EMERGENCY, YOU MUST IMMEDIATELY CALL THE EMERGENCY SERVICE NUMBER (911 IN THE USA) </strong> AND NOTIFY THE RELEVANT AUTHORITIES. SEEK IMMEDIATE IN PERSON ASSISTANCE. THE PRODUCT IS NOT DESIGNED FOR USE IN ANY OF THE AFOREMENTIONED CASES AND CANNOT PROVIDE THE ASSISTANCE REQUIRED IN ANY OF THE AFOREMENTIONED CASES.
                    </p>
                    <p>
                        <strong>THE PRODUCT IS NOT INTENDED FOR THE PROVISION OF CLINICAL DIAGNOSIS.</strong>.THE PRODUCT IS NOT INTENDED TO BE A SUBSTITUTE FOR PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. IT IS ALSO NOT INTENDED FOR ANY INFORMATION REGARDING WHICH DRUGS OR MEDICAL TREATMENT MAY BE APPROPRIATE IN ANY GIVEN SITUATION AND YOU SHOULD DISREGARD ANY SUCH ADVICE IF DELIVERED THROUGH THE PRODUCT.
                    </p>
                    <p>
                        <strong>
                            ALWAYS SEEK THE ADVICE OF YOUR PHYSICIAN OR OTHER QUALIFIED HEALTH PROVIDER WITH ANY QUESTIONS YOU MAY HAVE REGARDING A MEDICAL CONDITION.  DO NOT DISREGARD, AVOID, OR DELAY IN OBTAINING IN-PERSON CARE FROM A DOCTOR OR OTHER QUALIFIED PROFESSIONAL BECAUSE OF INFORMATION RECEIVED THROUGH THE PRODUCT.
                        </strong>
                    </p >
                    <p>
                        <strong>
                            WE ARE COMMITTED TO IMPROVING HEALTHCARE.  THEREFORE, BY USING OUR PRODUCT YOU AGREE TO THE TERMS OF USE AND THE PRIVACY POLICY INCLUDING WITHOUT LIMITATION THE SECTION ON HOW WE MAY SHARE YOUR ANONYMIZED DATA BASED ON THE INFORMATION COLLECTED THROUGH THE PRODUCT.
                        </strong>
                    </p>
                    <p>
                        <strong>
                            ANY TRANSFER OF YOUR RECORDS TO ANY FAMILY MEMBER, GUARDIAN, MEDICAL PROFESSIONAL(S) OR OTHER THIRD PARTY OR GRANTING PERMISSION TO SUCH PERSONS TO ADD TO SUCH RECORDS OR SHARE WITH OTHER THIRD PARTIES, MAY REQUIRE ONE OR MORE CONSENTS SIGNED BY YOU.  IF YOU ARE A GUARDIAN WHO IS USING THIS PRODUCT ON BEHALF OF A PERSON FOR WHOM YOU MAY BE PROVDING CARE, YOU MAY BE REQUIRED TO PROVIDE EVIDENCE OF SUCH GUARDIANSHIP SATISFACTORY TO THE COMPANY IN ADDITION TO OBTAINING A WRITTEN CONSENT OF FROM THE A PERSON FORM WHOM YOU MAY BE PROVIDING CARE.
                        </strong>
                    </p>

                    <h4>3. LICENSE FEE AND PAYMENT  </h4>
                    <p>
                        3.1	In consideration for the license granted herein, if you are required to pay a fee hereunder, you agree to pay the Company the applicable Fee, as specified in on the Site using the application licensed to provide such payments, which is currently Stripe but may be chnaged in the future without notice.   The Fee shall be paid in accordance with the payment terms specified by the Company as described herein. If you have provided Company or its authorized representative a credit card for purposes of payment hereunder, then Company or its authorized representative may, and you authorize Company or its authorized representative to, charge all amounts owed to Company hereunder to such credit card as such amounts become due. <strong>If you are getting the right to access the Product as a result of the authorization granted to you by a third party who is permitted by the Company (“Authorized Third Party”) to grant you authorization, then this Section 3.1 and 3.2 may not apply to you unless you are paying for the Product as well.</strong>
                    </p>
                    <p>
                        3.2	All payments due hereunder shall be net of any applicable sales, use, and other taxes, and you agree to pay (or reimburse Company or its authorized representative, as the case may be) any taxes due in connection with this Agreement, excluding taxes on Company’s or its authorized representative’s income; provided, however, that Company and its authorized representatives shall not invoice you for any taxes for which you have provided an appropriate exemption certificate for the applicable delivery jurisdiction.
                    </p>
                    <p>
                        3.3	If you, or if applicable the Authorized Third Party, fails to pay any sum for access to this Product as and when due, then the Company shall be free to suspend or discontinue performance under this Agreement, at its discretion, and access to the Product without any further notice.
                    </p>

                    <h4>4. 	AVAILABILITY </h4>

                    <p>
                        4.1	While Company shall use reasonable efforts to make the Product accessible 24 hours, seven days a week, the Company shall have <u>no liability</u> whatsoever in the event you are unable to access the Product or any part thereof at any time.  From time to time, Company and/or its service providers may perform scheduled or unscheduled maintenance as may be necessary to maintain the proper operation of the Product and access to the Product and the data you have inputted into the Product that is stored by Company and its service providers (“<u>Hosted Data</u>”) may be impaired or interrupted while such maintenance is being performed.  Company and its service providers may conduct scheduled maintenance from time to time.
                    </p>
                    <p>
                        4.2	You are solely responsible for your internet or data connection (the speed of which may have a significant impact on the responsiveness of the Product), including all internet or data service provider connection charges. Company recommends access to a second internet connection backup for redundancy.  You are solely responsible for all computers, other hardware, and software necessary to allow you to access the Product.  The Product site may specify certain minimum requirements that your equipment or connection must meet in order for you to be able to properly use the Product.
                    </p>
                    <p>
                        4.3	Only you are authorized to use this Product. NEITHER THE COMPANY NOR OUR SUPPLIERS ARE RESPONSIBLE FOR UNAUTHORIZED ACCESS THAT OCCURS AS A RESULT OF FAILURE TO SAFEGUARD YOUR CREDENTIALS OR FOLLOW ANY OTHER REQUIREMENTS IN THIS AGREEMENT.
                    </p>

                    <h4>5.	USE OF THE PRODUCT; SECURITY; PERMITTED USES</h4>

                    <p>
                        5.1 You shall use the Product solely for its internal purposes and in accordance with any usage limitations or guidelines provided by the Company.
                    </p>
                    <p>
                        5.2	Company has implemented certain security measures (such as password protection and encryption) and maintain such other safeguards (including virus protection safeguards) that it deems in its sole discretion are reasonably intended to preserve the confidentiality, integrity, and availability of Hosted Data.  You acknowledge and agree that Company may monitor, record, and audit its use of the Product in order to protect the security of any Hosted Data and the security of the Company’s information systems.  You agree that the Company may suspend or terminate your access if necessary to protect the security of Hosted Data or the Company’s information systems or in the event of a violation of this Agreement, the Terms of Use, or the Privacy Policy.  You expressly recognize that, although Company shall take such reasonable steps, or cause such reasonable steps to be taken, to prevent security breaches, it is impossible to maintain flawless security.  You are solely responsible for any damage caused by unauthorized destruction, loss, interception, or alteration of the Hosted Data by unauthorized persons.
                    </p>
                    <p>
                        5.3	Company may use and disclose the Hosted Data to provide the Product (including submitting such Hosted Data to other health care providers, third party service providers, insurance companies, and other persons as directed by you and in accordance with the Agreement), (b) to aggregate information relating to transactions for statistical analysis and business measures of the performance of the Product, (c) to monitor you use of the Product for security purposes, and (d) to enforce the terms of this Agreement.
                    </p>


                    <h4>6. CONTENT. </h4>

                    <p>
                        6.1	You need to keep backup copies of all of the subscriber’s content. "Content" means (a) any data or information that you enter or store in connection with a Product, (b) any data or information that you upload to, download from, send, or receive, or enter in connection with a Service, or (c) any data or information that is obtained from these things (such as calculations, reports, and files that you create using our software). In some cases, the Company or our Suppliers may provide data storage, data visualization or other processing capabilities or services. NEVERTHELESS, YOU AGREE THAT NEITHER THE COMPANY NOR ITS SUPPLIERS ARE RESPONSIBLE FOR ANY LOSS OF CONTENT OR HOSTED DATA.
                    </p>
                    <p>
                        6.2	In order to provide Product functionality, the Company must have access to the Content that you store or process using this Product. You grant to the Company, its Suppliers, and each of its affiliate’s permission to collect, send, receive, process, store, alter, and create new information based on your Content, Credentials, and other information. You further grant the Company and its Suppliers permission to use information about you or your experience to provide the Product, or to enhance the Product. In addition, you agree that the Company, or its Suppliers (when working on their behalf), or the Third-Party Product and Services as defined below, may (but are not obligated to) monitor any Content and record any transactions and actions while using the Product. You agree that the Company may disclose any information that the Company obtains in this manner if necessary to satisfy our legal obligations, to protect us, or to prevent harm or fraud against customers or third parties. The Company or our Suppliers may, in our sole discretion, refuse to process, remove, or refuse to allow any Content that the Company think is illegal, inappropriate, or in violation of this Agreement, the Terms if Use or the Privacy Policy or other applicable terms. You hereby waive any of your moral rights in any of your Content, Credentials, and other information in favor of the Company, its Suppliers and each of its affiliates
                    </p>


                    <h4>7.	INTELLECTUAL PROPERTY; UPDATES</h4>

                    <p>
                        7.1 	The Company retains all rights, title, and interests in and to the Product, including any intellectual property rights therein this Agreement.  The right to use this Product hereunder does not provide you with title to or ownership of the Product, the intellectual property rights embidied herein or any copies or modifications thereof, but only a right of limited remote use under the terms and conditions of this Agreement.
                    </p>
                    <p>
                        7.2	The Company reserves the right to change this Agreement at any time, and the changes will be effective when posted on our website or when the Company notify you by other means. Your continued use of a Product or Service following notice indicates your agreement to the changes.
                    </p>
                    <p>
                        7.3	You hereby grant to the Company a non-exclusive, sublicensable, royalty free, fully paid up, assignable license and right to use any feedback, suggestions, or improvements provided by you for the purpose of improving or enhancing the Product
                    </p>
                    <p>
                        7.4	The Company retains all rights, title, and interest in and to any Anonymized Data and you agree that you have no rights therein and waive all moral rights thereto.
                    </p>


                    <h4>8.   ACCESS AND CONTENT</h4>
                    <p>8.1	You shall not, nor shall you permit anyone else to, do any of the following:</p>
                    <ul>
                        <li>Use the Product in a manner that violates any law, regulation, this Agreement, or the Terms of Use or the Privacy Policy. You will not remove or modify any copyright or proprietary notices.</li>
                        <li>Give, sell, loan, or distribute this Product to any third party, or attempt to transfer your license to any other party.</li>
                        <li>Not assist, directly or indirectly, anybody else to gain unauthorized access to the Product or related systems.</li>
                        <li>Permit any third party to benefit from the use or functionality of the Product via a rental, lease, timesharing, service bureau, hosting service, or other arrangement, or offer them on any file-sharing or application hosting service.</li>
                    </ul>

                    <p>

                        8.2	Neither the Company nor its Suppliers assume any responsibility or liability for any aspect of your Content.

                    </p>
                    <p>
                        8.3	Without limiting the Terms of Use or the Privacy Policy. you agree that you will not, whether directly or indirectly, upload, process, share, or link to any of the following:
                    </p>
                    <ul>
                        <li>Content that is illegal, fraudulent, inappropriate, or objectionable, or that creates criminal or civil liability under any law.</li>
                        <li>You are responsible for periodically checking your Content and other data (including any Content or data that have been uploaded, downloaded, sent to, or received from Third-Party Product and Services) for such items.</li>
                        <li>Any information, software, or Content which is not legally yours without permission from the copyright owner or intellectual property rights owner.</li>
                    </ul>
                    <p>
                        8.4	The Company retains the right to block any content or other information or data that the Company may deem in our sole discretion to be harmful or not meeting the standards of behavior.
                    </p>


                    <h4>10.	WARRANTY DISCLAIMER</h4>
                    <p>
                        10.1	THE PRODUCT IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT.
                    </p>
                    <p>
                        10.2	COMPANY MAKES NO REPRESENTATION OR WARRANTY THAT THE PRODUCT OR ANY THIRD-PARTY SERVICES SHALL PERFORM ACCURATELY OR RELIABLY, THAT ANY INFORMATION DELIVERED TO YOU, OR TO THIRD PARTIES ON YOUR BEHALF, BY OR THROUGH THE PRODUCT OR ANY THIRD-PARTY SERVICES WILL BE CORRECT OR COMPLETE, OR THAT USE OF THE PRODUCT OR ANY THIRD-PARTY SERVICES WILL OBTAIN FOR YOU ANY CERTAIN RESULTS.  THE COMPANY DISCLAIMS ANY AND ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, BY OPERATION OF LAW OR OTHERWISE, REGARDING OR RELATING TO THE PRODUCT OR ANY THIRD-PARTY SERVICES OR ANY OTHER SERVICES PRODUCTS OR SERVICES DELIVERED UNDER THIS AGREEMENT.  THE COMPANY SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE (IRRESPECTIVE OF ANY PREVIOUS COURSE OF DEALING BETWEEN THE PARTIES OR CUSTOM OR USAGE OF TRADE), NON-INFRINGEMENT, OR THAT THE PRODUCT OR ANY THIRD-PARTY SERVICES WILL BE UNINTERRUPTED OR ERROR FREE.
                    </p>


                    <h4>11. LIMITATION OF LIABILITY </h4>
                    <p>
                        11.1	TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, COMPANY AND ITS SUPPLIERS AND LICENSORS WILL NOT BE LIABLE FOR ANY LOSS OF REVENUE, PROFITS OR GOODWILL OR FOR ANY SPECIAL, INCIDENTAL, INDIRECT, CONSEQUENTIAL OR PUNITIVE DAMAGES OR LOSSES RESULTING FROM COMPANY’S OR THE PRODUCT’S OR ANY THIRD PARTY SERVICES’ PERFORMANCE OR FAILURE TO PERFORM PURSUANT TO THE TERMS OF THIS AGREEMENT, FROM THE FURNISHING, PERFORMANCE OR LOSS OF USE OF SUCH PRODUCTS OR SERVICES, INCLUDING, WITHOUT LIMITATION, FROM ANY INTERRUPTION OF BUSINESS, WHETHER RESULTING FROM BREACH OF CONTRACT OR OTHER LEGAL LIABILITY WHATSOEVER, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                    </p>
                    <p>
                        11.2	THE MAXIMUM AGGREGATE LIABILITY OF THE COMPANY OR ANY OF ITS SUPPLIERS ARISING OUT OR RELATING TO THIS AGREEMENT SHALL NOT EXCEED THE TOTAL FEES PAID BY YOU TO COMPANY HEREUNDER DURING THE TWELVE MONTHS PREVIOUS TO THE EVENTS GIVING RISE TO SUCH CLAIM.
                    </p>


                    <h4>12. TERM AND TERMINATION </h4>

                    <p>
                        12.1	This Agreement shall commence on the date that your fees have been paid, whether by you or a third party, and, unless suspended or terminated early, shall continue for the duration of the subscription period listed on your order form subject to any automatic renewals as described therein (the “Term”).   If your right to access the Product has been suspended or if you do not have access to the Product, for any reason, the period during which your account has been suspended or which you do not have access shall not increase the length of the Term.
                    </p>
                    <p>
                        12.2	You may terminate this Agreement for any or no reason by providing Company at least thirty (30) days prior written notice.  If any Fees become due before the effective date of termination (regardless of whether notice of termination has already been delivered), you shall be required to pay such fees in full.
                    </p>
                    <p>
                        12.3	In the event this Agreement is terminated or not renewed for any reason, then all rights and licenses granted to you hereunder to the Product shall terminate and you shall cease to use and access the Product and the Content.  Upon notice to Company within thirty (30) days after the  termination of the rights to the Product as described above, you may  request the Company to transfer an electronic copy of the Hosted Data (in the format in which it is stored by Company) to you in exchange for the payment  of a fee set by the Company  If you do not provide the Company with notice within the period described above or pay the fee within ten (10) days upon demand by the Company, the Company shall have no obligation to provide you the Content thereafter.
                    </p>
                    <p>
                        12.4	In addition, the following terms and conditions shall survive any termination or expiration of this Agreement: Sections 2.2, 3.2, 3.3, 4.3 and Articles 7, 8, 9, 10, 11, 12, 13, and 14
                    </p>




                    <h4>13. GOVERNING LAW; ARBITRATION</h4>
                    <p>
                        This Agreement shall be governed by and construed in accordance with the laws of the Commonwealth of Massachusetts without regard to its conflict of laws principles.  Other than as necessary to enforce any final judgment, award or determination, any action brought pursuant to or in connection with this Agreement shall be brought in accordance with to the venue and arbitration provisions in the Terms of Use.  In any such action, both parties submit to the personal jurisdiction of the courts of the Commonwealth of Massachusetts and waive any objections to venue of such courts.
                    </p>
                    <p>
                        NOTWITHSTANDING ANYTHING TO THE CONTRARY, THE COMPANY MAY AT ANY TIME SEEK INJUNCTIONS OR OTHER FORMS OF EQUITABLE RELIEF FROM ANY COURT OF COMPETENT JURISDICTION. ANY AND ALL DISPUTES MUST BE BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. YOU AGREE THAT YOU WAIVE THE RIGHT TO FILE A LAWSUIT AND THE RIGHT TO A TRIAL BY JURY. IN ADDITION, YOU AGREE TO WAIVE THE RIGHT TO PARTICIPATE IN A CLASS ACTION OR LITIGATE ON A CLASS-WIDE BASIS. YOU AGREE THAT YOU HAVE EXPRESSLY AND KNOWINGLY WAIVED THESE RIGHTS.  COMPANY RESERVES THE RIGHT TO ENFORCE ITS INTELLECTUAL PROPERTY RIGHTS IN ANY APPLICABLE VENUE AND IN ANY JURISDICTION.
                    </p>
                    <p>ALL ARBITRATION PROCEEDINGS SHALL OCCUR IN BOSTON, MASSACHUSETTS.

                    </p>


                    <h4>14. MISCELLANEOUS </h4>

                    <p>
                        14.1	This Agreement constitutes the entire agreement between the Parties with respect to the subject matter hereof and supersedes all prior or contemporaneous agreements, representations, warranties, and understandings.
                    </p>
                    <p>
                        14.2	You shall not assign, delegate, sublicense, or transfer any of its obligations, responsibilities, rights, or interests under this Agreement without the written consent of the Company, except to a successor in a merger or a sale of all or substantially all of Subscriber’s capital stock and/or assets, as the case may be.</p><p>
                        14.3	In the event that the Company is unable to perform any of its obligations under this Agreement because of causes beyond its reasonable control or because of any Act of God, accident to equipment or machinery; any fire, flood, hurricane, tornado, storm or other weather condition; any war, act of war, act of public enemy, terrorist act, sabotage, riot, civil disorder, act or decree of any governmental body; any failure of communications lines, transportation, light, electricity or power; any earthquake, civil disturbance, commotion, lockout, strike or other labor or industrial disturbance; or any illness, pandemic, epidemic, quarantine, death or any other natural or artificial disaster (each, a “Force Majeure Event”) the Company shall give notice to you and shall devote commercially reasonable efforts to resume performance.  Upon receipt of such notice, all obligations under this Agreement shall be immediately suspended and performance times shall be considered extended for a period of time equivalent to the time lost because of any such delay.  Nothing provided herein shall excuse the delay of any payment that is validly due by you under this Agreement.</p><p>
                        14.4	Unless expressly stated otherwise herein, any notice, demand, request or delivery required or permitted to be given by a party pursuant to the terms of this Agreement shall be in writing and shall be deemed given (a) when delivered personally, (b) on the next business day after timely delivery to an overnight courier, (c) on the third business day after deposit in the U.S. mail (certified or registered mail return receipt requested, postage prepaid), (d) when delivered via email to the notified party’s email provider for delivery to such notified party, and (e) upon confirmation of receipt by facsimile transmission; in each case addressed to the party at such party’s address as set forth  below or as subsequently modified by the receiving party pursuant to written notice:
                    </p>

                    <p>
                        If to Company
                        info@powerofpatients.com

                    </p>
                    <p>
                        If to you:
                        At the address and email address that you have registered on the Product.
                    </p>
                    <div style={{ border: '1px solid #000', padding: '10px', margin: '10px 0' }}>
                        <h4>For Authorized User </h4>
                        <p>
                            By completing the information and clicking the square below, I, as an Authorized User, hereby agrees to these terms.
                        </p>
                        {!fromPaymentpage && <div style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <SubmitButton onClick={() => { setSigned(true) }} style={{ fontSize: '1.25rem' }}>Sign</SubmitButton>
                            <div style={{
                                borderBottom: '1px solid #000',
                                marginBottom: '0px',
                                paddingBottom: '5px', // Adjust as needed
                                paddingLeft: '10px',
                                width: '150px',
                            }}>{firstName + " " + lastName}</div>
                        </div>}
                        {!fromPaymentpage && (firstName || lastName) && <SubmitButton onClick={onClick} disabled={!signed} greyout={!signed} style={{ fontSize: '1.25rem' }}>I agree to the terms and conditions</SubmitButton>}
                        {!fromPaymentpage && !firstName && !lastName && <SubmitButton onClick={goback} style={{ fontSize: '1.25rem' }}>Go back to enter the name</SubmitButton>}
                    </div >
                </div >)
            }
        </>
    )
}

export default PrivacyPagePatient
