import React, { useContext } from "react";
import { ThemeContext } from "../../lib/main-context";

import Logo from "../../images/Logo.png";
import LogoWhite from "../../images/LogoWhite.png";

const Footer = () => {
    const { dark } = useContext(ThemeContext);

    return (
        <div className="footer">
            <img
                src={dark ? LogoWhite : Logo}
                className="logo"
                alt="Power of Patients brand-img"
            />
        </div >
    );
};

export default Footer;
