export const EDIT = {
    english: 'Edit',
    spanish: 'Editar'
}

export const GO_BACK = {
    english: 'Go Back',
    spanish: 'Ir hacia atrás',
}

export const OFF_DARK_MODE_EXPORT = {
    english: 'Please turn off dark mode to export.',
    spanish: 'Por favor, desactiva el modo oscuro para exportar.'
}

export const EXPORT_PDF = {
    english: 'Export as PDF',
    spanish: 'Exportar como PDF'
}

export const PAUSE = {
    english: 'pause',
    spanish: 'pausar'
}

export const UNPAUSE = {
    english: 'unpause',
    spanish: 'reanudar'
}

///////////////////
// Dashboard.js //
//////////////////
export const VERIFY_EMAIL = {
    english: {
        header: 'Verify your email address',
        body: ["OOPS! Looks like you need to verify your email address.",
            "Please go back to your inbox and look for an email from \"sallie@powerofpatients.com\". It was sent to",
            ". You may need to check your SPAM box too. Thank you!"]
    },
    spanish: {
        header: "Verifique su dirección de correo electrónico",
        body: [
            "¡UPS! Parece que necesita verificar su dirección de correo electrónico.",
            "Por favor, vuelva a su bandeja de entrada y busque un correo electrónico de \"sallie@powerofpatients.com\". Se envió a",
            ". También es posible que deba revisar su carpeta de SPAM. ¡Gracias!"
        ]
    }
}

export const SALLIE_DASHBOARD = {
    english: {
        patient: 'Welcome to your dashboard. For this to work properly you need to track your symptoms. Begin tracking below.',
        non_patient: 'Welcome to your dashboard.',
        patient_info: {
            provider: 'Viewing patient: ',
            non_provider: 'Tracking for patient: ',
            other: ' Other'
        }
    },
    spanish: {
        patient: 'Bienvenido a su panel. Para que esto funcione adecuadamente deberá monitorear sus síntomas. Comience a monitorearlos a continuación.',
        non_patient: 'Bienvenido a su panel.',
        patient_info: {
            provider: 'Viendo al paciente: ',
            non_provider: 'Monitoreando al paciente: ',
            other: ' Otro'
        }
    }
}

export const TOGGLE_MESSAGES = {
    english: {
        on: 'Receiving patient messages',
        off: 'Do not receive patient messages'
    },
    spanish: {
        on: 'Recibiendo mensajes del paciente',
        off: 'No recibir mensajes del paciente'
    }
}

export const TOGGLE_ALERTS = {
    english: {
        on: 'Receiving patient severity alert',
        off: 'Do not receive patient severity alert'
    },
    spanish: {
        on: 'Recibiendo alerta de severidad del paciente',
        off: 'No recibir alerta de severidad del paciente'
    }
}

export const MAILBOX = {
    english: {
        new: 'New Messages',
        send_or_read: 'SEND or READ Messages'
    },
    spanish: {
        new: 'Nuevos Mensajes',
        send_or_read: 'ENVIAR o LEER Mensajes'
    }
}

/////////////////////
// ThemeChanger.js //
/////////////////////
export const DARK_MODE = {
    english: {
        on: 'Turn Dark Mode On',
        off: 'Turn Dark Mode Off'
    },
    spanish: {
        on: 'Encender Modo Oscuro',
        off: 'Desactivar Modo Oscuro'
    }
}

///////////////////////
// CurrentPatient.js //
///////////////////////
export const CURRENT_PATIENT = {
    english: 'Add-Switch Patient',
    spanish: 'Agregar-Cambiar Paciente'
}

/////////////////////////
// EditNotification.js //
/////////////////////////
export const EDIT_NOTIFICATION = {
    english: {
        header: 'CURRENT Sallie Reminder Schedule',
        count_per_month: 'x/month',
        day_of_week: {
            Monday: 'Monday',
            Tuesday: 'Tuesday',
            Wednesday: 'Wednesday',
            Thursday: 'Thursday',
            Friday: 'Friday',
            Saturday: 'Saturday',
            Sunday: 'Sunday'
        }
    },
    spanish: {
        header: 'Horario de Recordatorios de Sallie ACTUAL',
        count_per_month: 'x/mes',
        day_of_week: {
            Monday: 'Lunes',
            Tuesday: 'Martes',
            Wednesday: 'Miércoles',
            Thursday: 'Jueves',
            Friday: 'Viernes',
            Saturday: 'Sábado',
            Sunday: 'Domingo '
        }
    }
}

/////////////////
// Doghouse.js //
/////////////////
export const DOGHOUSE = {
    english: {
        start: 'Start Tracking',
        pause: 'Pause Tracking'
    },
    spanish: {
        start: 'Comenzar a Monitorear',
        pause: 'Pausar Monitoreo'
    }
}

//////////////////////
// PatientStatus.js //
//////////////////////
export const PATIENT_STATUS = {
    english: {
        options: {
            active: 'Active',
            inactive: 'Inactive'
        },
        placeholder: 'Select a patient status'
    },
    spanish: {
        options: {
            active: 'Activo',
            inactive: 'Inactivo'
        },
        placeholder: 'Selecciona un estado de paciente'
    }
}

//////////////////////////////////
// OnOffButtonReferralRecord.js //
//////////////////////////////////
export const REFERRAL_RECORD_BUTTON = {
    english: {
        new_referred: 'New Referred Patients',
        check_referred: 'Check Referred Patients'
    },
    spanish: {
        new_referred: 'Nuevos Pacientes Referidos',
        check_referred: 'Consultar Pacientes Referidos'
    }
}

/////////////////////
// FeelingToday.js //
/////////////////////
export const FEELING_TODAY = {
    english: {
        label: ['How is', 'doing today?'],
        mood: {
            good: 'Good',
            neutral: 'Neutral',
            bad: 'Bad'
        },
        error: ['Sorry, something went wrong. Please try again or click', 'here', 'to request help'],
        submit: 'Submitting...'
    },
    spanish: {
        label: ['¿Cómo se siente', 'hoy?'],
        mood: {
            good: 'Bien',
            neutral: 'Neutral',
            bad: 'Mal'
        },
        error: ['Lo siento, algo salió mal. Por favor, inténtelo de nuevo o haga clic', 'aquí', 'para solicitar ayuda'],
        submit: 'Enviando...'
    }
}

///////////////////////
// SymptomTracker.js //
///////////////////////
export const TRACK_SYMPTOM = {
    english: {
        patient: 'Track your symptoms',
        non_patient: ['Track ', "'s symptoms"]
    },
    spanish: {
        patient: 'Monitorea sus síntomas',
        non_patient: ['Monitorear los síntomas de ', '']
    }
}

export const SYMPTOM_TRACKER = {
    english: {
        no_symptoms: [
            'You will need to select symptoms to track in order to access the symptom tracker. ',
            'Click here to select symptoms'
        ],
        intensity_level: {
            label: 'Intensity Level: ',
            no: 'No ',
            worst: 'Absolute worst '
        },
        messages: {
            big_improve: {
                patient: 'This is AWESOME! Tell me what you did to get even better.',
                caregiver: ['This is AWESOME! Tell me what ', ' did to get even better.']
            },
            improve: {
                patient: 'This is great. Tell me what you did to get better.',
                caregiver: ['This is great. Tell me what ', ' did to get even better.']
            },
            big_increase: {
                patient: [
                    'Since your last log, we noticed an increase in the severity of your ',
                    ' of at least 30%. '
                ],
                caregiver: [
                    'Since your last log, we noticed an increase in the severity of ',
                    "'s",
                    ' of at least 30%. '
                ],
                learn_more: 'Learn More'
            },
            increase: 'We noticed a 10% increase since your last log. Please provide a note: '
        },
        note: 'Notes and/or Questions',
        missing_note: 'Please enter a note',
        total_symptoms: ' Total Symptoms',
        skip: 'Skip',
        save: 'Save',
    },
    spanish: {
        no_symptoms: [
            'Deberás seleccionar los síntomas que deseas monitorear para acceder al rastreador de síntomas. ',
            'Haz clic aquí para seleccionar síntomas'
        ],
        intensity_level: {
            label: 'Nivel de Intensidad: ',
            no: 'No ',
            worst: 'La peor '
        },
        messages: {
            big_improve: {
                patient: '¡Esto es INCREÍBLE! Dime qué hiciste para mejorar aún más.',
                caregiver: ['¡Esto es INCREÍBLE! Dime qué hizo ', ' para mejorar aún más.']
            },
            improve: {
                patient: 'Esto es genial. Dime qué hiciste para mejorar.',
                caregiver: ['Esto es genial. Dime qué hizo ', ' para mejorar.']
            },
            big_increase: {
                patient: [
                    'Desde tu último registro, hemos notado un aumento en la gravedad de tu ',
                    ' de al menos un 30%. '
                ],
                caregiver: [
                    'Desde tu último registro, hemos notado un aumento en la gravedad de ',
                    "'s",
                    ' de al menos un 30%. '
                ],
                learn_more: 'Saber más'
            },
            increase: 'Hemos notado un aumento del 10% desde tu último registro. Por favor, proporciona una nota: '
        },
        note: 'Notas y/o Preguntas',
        missing_note: 'Por favor, ingrese una nota',
        total_symptoms: ' Síntomas Totales ',
        skip: 'Saltar',
        save: 'Guardar',
    }
}

/////////////////////
// SDOHSelector.js //
/////////////////////
export const TRACK_TRIGGERS = {
    english: {
        patient: 'Track your triggers',
        non_patient: ['Track ', "'s triggers"],
    },
    spanish: {
        patient: 'Monitorea sus síntomas',
        non_patient: ['Monitorear los síntomas de ', '']
    }
}

export const SDOH_SELECTOR = {
    english: {
        patient: 'What did you do today?',
        non_patient: ['What did ', ' do today?']
    },
    spanish: {
        patient: 'Qué hiciste hoy',
        non_patient: ['¿Qué hizo ', ' hoy?']
    }
}

///////////////
// Charts.js //
///////////////
export const CHARTS = {
    english: {
        trend_charts: 'Trend Charts',
        trigger_data: 'Trigger Data',
        detailed_reports: {
            label: 'Detailed Reports',
            overall_feelings: 'Overall Feelings',
            top_symptoms: 'Top Symptoms',
            medical_score: 'Medical Score',
            top_triggers: 'Top Triggers',
            trigger_data: 'Trigger Data',
            days_in_review: 'Days in Review'
        },
        case_report: 'Case Report',
        error: ['There was an error loading your data', 'Click here to try again']
    },
    spanish: {
        trend_charts: 'Gráficos de Tendencias',
        trigger_data: 'Datos de Desencadenantes',
        detailed_reports: {
            label: 'Informes Detallados',
            overall_feelings: 'Sentimientos Generales',
            top_symptoms: 'Síntomas Principales',
            medical_score: 'Puntuación Médica',
            top_triggers: 'Desencadenantes Principales',
            trigger_data: 'Datos de Desencadenantes',
            days_in_review: 'Días en Revisión'
        },
        case_report: 'Informe de Caso',
        error: ['Hubo un error al cargar sus datos', 'Haga clic aquí para intentarlo de nuevo']
    }
}

///////////////////////
// AdditionalNote.js //
///////////////////////
export const ADDITIONAL_NOTE = {
    english: {
        anything_else: 'Is there anything else you would like to report?',
        report_saved: 'Your report is saved!',
        save: 'Save'
    },
    spanish: {
        anything_else: '¿Hay algo más que te gustaría informar?',
        report_saved: '¡Tu informe ha sido guardado!',
        save: 'Guardar'
    }
}

////////////////////
// AddSymptoms.js //
////////////////////
export const ADD_SYMPTOMS = {
    english: {
        symptoms: 'SYMPTOMS',
        add_symptoms: "ADD or PAUSE SYMPTOMS",
        add_more_symptoms: 'ADD or PAUSE More SYMPTOMS',
        new_symptom: 'New symptom is added to your list to track.',
        currently_tracking: 'Currently Tracking Symptoms',
        symptom_paused: 'Symptoms Paused',
        unpausing: [
            'If you are done UNPAUSING symptoms click the close button.',
            'If you want to add more, select the symptoms below and click Save & Add when you are done.'
        ],
        add_more: 'Add more',
        close: 'Close',
        yes: 'Yes',
        save_and_add: 'Save & Add'
    },
    spanish: {
        symptoms: 'Síntomas',
        add_symptoms: "AÑADIR o PAUSAR SÍNTOMAS",
        add_more_symptoms: 'AÑADIR o PAUSAR Más SÍNTOMAS',
        new_symptom: 'Nuevo síntoma añadido a su lista para seguimiento.',
        currently_tracking: 'Síntomas en seguimiento',
        symptom_paused: 'Síntomas en pausa',
        unpausing: [
            'Si ha terminado de REANUDAR los síntomas, haga clic en el botón de cerrar.',
            'Si desea añadir más, seleccione los síntomas a continuación y haga clic en Guardar y Añadir cuando haya terminado.'
        ],
        add_more: 'Añadir más',
        close: 'Cerrar',
        yes: 'Sí',
        save_and_add: 'Guardar y Añadir'
    }
}

///////////////////////////
// ReferPatientButton.js //
///////////////////////////
export const REFER_PATIENT = {
    english: 'Refer Patient',
    spanish: 'Referir Paciente'
}

//////////////////////////////
// ReferPatientSelection.js //
//////////////////////////////
export const REFER_PATIENT_SELECTION = {
    english: {
        sallie: 'Hi! Which patient would you like to refer?',
        messages: {
            sent: 'Referral sent successfully!',
            no_email: ['Recipient email not found. Please enter a valid email address or ', 'send an invitation'],
            account_type: "Please enter an email of a provider user.",
            fail: "Failed to send referral due to server error. Please try again later.",
            error: 'An unexpected error occurred. Please try again.'
        },
        receiver_email: 'Receiver Email',
        enter_email: 'Please enter an email address',
        send_request: 'Send Request',
        switch_patient: 'Go to Switch Patient',
        no_patient: 'You have no patients registered.',
        choose_patient: 'Please choose the patient you wish to refer and enter the email address of the receiving provider.',
        patient_list: 'Patient List',
        caregiver_list: "Caregiver's Patient List"
    },
    spanish: {
        sallie: '¡Hola! ¿Qué paciente le gustaría referir?',
        messages: {
            sent: '¡Referencia enviada con éxito!',
            no_email: ['Correo electrónico del destinatario no encontrado. Por favor, ingrese una dirección de correo electrónico válida o ', 'envíe una invitación'],
            account_type: "Por favor, ingrese el correo electrónico de un usuario que sea proveedor.",
            fail: "Error al enviar la referencia debido a un error del servidor. Por favor, intente nuevamente más tarde.",
            error: 'Ocurrió un error inesperado. Por favor, intente nuevamente.'
        },
        receiver_email: 'Correo Electrónico del Receptor',
        enter_email: 'Por favor, ingrese una dirección de correo electrónico',
        send_request: 'Enviar Solicitud',
        switch_patient: 'Ir a Cambiar Paciente',
        no_patient: 'No tiene pacientes registrados.',
        choose_patient: 'Por favor, elija al paciente que desea referir e ingrese la dirección de correo electrónico del proveedor receptor.',
        patient_list: 'Lista de Pacientes',
        caregiver_list: 'Lista de Pacientes del Cuidador'
    }
}

//////////////////////////////
// PatientReferralRecord.js //
//////////////////////////////
export const REFERRAL_RECORD_HEADER = {
    english: {
        header: 'Referral Record',
        back_to_dashboard: 'Go back to dashboard',
        refer_patient: 'Refer patient'
    },
    spanish: {
        header: 'Registro de Referencias',
        back_to_dashboard: 'Volver al panel de control',
        refer_patient: 'Referir paciente'
    }
}

export const REFERRAL_RECORD_TABLE = {
    english: {
        patient_name: 'Patient Name',
        sender_name: 'Sender Name',
        recipient_name: 'Recipient Name',
        time: 'Time',
        status: {
            label: 'Status',
            have_accepted: 'Have Accepted',
            accept: 'Accept',
            waiting: 'Waiting for Acceptance'
        }
    },
    spanish: {
        patient_name: 'Nombre del Paciente',
        sender_name: 'Nombre del Remitente',
        recipient_name: 'Nombre del Destinatario',
        time: 'Hora',
        status: {
            label: 'Estado',
            have_accepted: 'Aceptado',
            accept: 'Aceptar',
            waiting: 'Esperando Aceptación'
        }
    }
}

///////////////////////
// PatientControl.js //
///////////////////////
export const PATIENT_CONTROL = {
    english: {
        sallie: {
            no_patient_error: 'Please select a patient first.',
            provider: 'Hi! Which patient would you like to view?',
            non_provider: 'Hi! Which patient would you like to track?'
        },
        patient_select: {
            no_patient_error: 'You have no patients registered.',
            provider: 'Please select the patient.',
            non_provider: 'Please select the patient you want to track for.'
        },
        patient_list: {
            label: 'Patient List',
        },
        patient_list_options: {
            select: 'Select your patient',
            other: ' Other',
            incomplete_registration: ' (Registration Incomplete)',
            released: ' (Released)'
        },
        caregiver_list: {
            label: "Caregiver List",
            select: "Select your caregiver",
            patient_list: (name) => `${name}'s Patient List`
        },
        add_patient: ['Would you like to add a new patient to the list? ', 'Add a Patient'],
        submit: (name) => `View ${name}'s Dashboard`
    },
    spanish: {
        sallie: {
            no_patient_error: 'Por favor, seleccione un paciente primero.',
            provider: '¡Hola! ¿Qué paciente te gustaría ver?',
            non_provider: '¡Hola! ¿Qué paciente te gustaría monitorear?'
        },
        patient_select: {
            no_patient_error: 'No tienes pacientes registrados.',
            provider: 'Por favor, selecciona al paciente.',
            non_provider: 'Por favor, selecciona al paciente para el que deseas realizar el seguimiento.'
        },
        patient_list: {
            label: 'Lista de Pacientes'
        },
        patient_list_options: {
            select: 'Selecciona a tu paciente',
            other: ' Otro',
            incomplete_registration: ' (Registro Incompleto)',
            released: ' (Liberado)'
        },
        caregiver_list: {
            label: "Lista de Cuidadores",
            select: "Selecciona a tu cuidador",
            patient_list: (caregiver) => `Lista de pacientes de ${caregiver}`
        },
        add_patient: ['¿Te gustaría agregar un nuevo paciente a la lista? ', 'Agregar un Paciente'],
        submit: (name) => `Ver la Tablero de ${name}`
    }
}

//////////////////
// MediaPage.js //
//////////////////
export const MEDIA_PAGE = {
    english: {
        sallie: "Below is a selection of videos for you to educate yourself about TBI's",
        media: 'Media',
        videos: {
            webinar_two: {
                title: 'Power of Patients VT Webinar 2 of 3',
                description: 'Power of Patients presents Take 2 Replay of Vision therapy series 2 of 3'
            },
            tbi_pcs: {
                title: 'TBI & PCS',
                description: 'Power of Patients addresses Post Concussive Syndrome, the risks, the triggers and how to track these symptoms on their dashboard.'
            },
            PoP_jul15: {
                title: 'Power of Patients Jul15 2020',
                description: 'Power of Patients - Take 2 presents TBI & The Vagus Nerve'
            },
            important_questions: {
                title: 'Three important questions a clinical trial participant needs to know about Covid-19',
                description: 'What is COVID-19 doing to clinical trials. Three important questions a clinical trial subject should have with their study coordinator.'
            },
            webinar_one: {
                title: 'Neuro Optometry webinar 1 of 3',
                description: 'Power of Patients hosted a Neuro-Optometry webinar. The first of 3. Our guest speaker was Dr Almouradi.',
            },
            PoP_dashboard_demo: {
                title: 'Power of Patients Dashboard Demo',
                description: 'Sep 23rd 2020 webinar demonstrating how to log in and use the dashboard'
            },
            clinical_trial_5min: {
                title: 'Clinical Trials 5min',
            },
            clinical_trial: {
                title: 'Clinical Trials',
                description: 'This video is an introductory session about clinical trials'
            }
        }

    },
    spanish: {
        sallie: "A continuación se muestra una selección de videos para que te eduques sobre las lesiones cerebrales traumáticas (TBI)",
        media: 'Medios',
        videos: {
            webinar_two: {
                title: 'Webinar de Terapia Visual 2 de 3 de Power of Patients',
                description: 'Power of Patients presenta la repetición de la serie de Terapia Visual 2 de 3'
            },
            tbi_pcs: {
                title: 'TBI & PCS',
                description: 'Power of Patients aborda el Síndrome Post-Conmocional, los riesgos, los desencadenantes y cómo rastrear estos síntomas en su tablero.'
            },
            PoP_jul15: {
                title: 'Power of Patients 15 de Julio 2020',
                description: 'Power of Patients - Take 2 presenta TBI y el Nervio Vago'
            },
            important_questions: {
                title: 'Tres preguntas importantes que un participante de un ensayo clínico debe saber sobre Covid-19',
                description: '¿Qué está haciendo COVID-19 con los ensayos clínicos? Tres preguntas importantes que un participante de un ensayo clínico debe hacer a su coordinador de estudio.'
            },
            webinar_one: {
                title: 'Webinar de Neuro-Optometría 1 de 3',
                description: 'Power of Patients organizó un webinar de Neuro-Optometría. El primero de 3. Nuestro orador invitado fue el Dr. Almouradi.',
            },
            PoP_dashboard_demo: {
                title: 'Demostración del Tablero de Power of Patients',
                description: 'Webinar del 23 de septiembre de 2020 demostrando cómo iniciar sesión y usar el tablero'
            },
            clinical_trial_5min: {
                title: 'Ensayos Clínicos en 5 Minutos',
            },
            clinical_trial: {
                title: 'Ensayos Clínicos',
                description: 'Este video es una sesión introductoria sobre ensayos clínicos'
            }
        }
    }
}

//////////////////////
// ExpandedChart.js //
//////////////////////
export const EXPANDED_CHART = {
    english: {
        days_in_review: 'Days in Review',
        days_since: 'Days in Review since',
        report_detail: {
            patient: ['This report is for patient ']
        },
        compare_tracking_data: {
            label: "Compare Patient's Tracking Data",
            text: ['You are comparing it with ', "'s report for this patient."]
        },
        error: 'An error occured while fetching data.'
    },
    spanish: {
        days_in_review: 'Días en Revisión',
        days_since: 'Resumen de los días desde el sábado',
        report_detail: {
            patient: ['Este informe es para el paciente ']
        },
        compare_tracking_data: {
            label: "Comparar datos de seguimiento del paciente",
            text: ['Lo está comparando con el informe de ', ' para este paciente.']
        },
        error: 'Ocurrió un error al recuperar los datos.'
    }
}
export const INTENSITY_LEVEL = {
    english: 'Intensity Level',
    spanish: 'Nivel de Intensidad'
}

/////////////////////
// DoughnutSdoh.js //
/////////////////////
export const DOUGHNUT_SDOH = {
    english: 'SDOH Categories (Click to Explore)',
    spanish: 'Categorías SDOH (Haga Clic para Explorar)'
}

export const SDOH_CATEGORY = {
    english: {
        Wellness: "Wellness",
        Travel: "Travel",
        'Screen time': "Screen time",
        Environmental: "Environmental",
        Dietary: "Dietary",
        Situational: "Situational",
        Other: "Other",
    },
    spanish: {
        Wellness: 'Bienestar',
        Travel: 'Viajes',
        'Screen time': 'Tiempo de Pantalla',
        Environmental: 'Ambientales',
        Dietary: 'Dietas',
        Situational: 'Situacionales',
        Other: 'Otros',
    }
}

export const NO_TRACKING_DATA = {
    english: ["No data available. ", "Charts will be displayed when tracking starts."],
    spanish: ["No hay datos disponibles. ", "Las gráficas se mostrarán cuando comience el seguimiento."]
}

////////////////////////
// DoughnutMedical.js //
////////////////////////
export const DOUGHNUT_MEDICAL = {
    english: 'Medical Domains (Click to Explore)',
    spanish: 'Documentos Médicos (Haga Clic para Explorar)'
}

export const SYMPTOM_CATEGORY = {
    english: {
        Cognitive: 'Cognitive',
        Sleep: 'Sleep',
        Emotional: 'Emotional',
        Physical: 'Physical',
        Speech: 'Speech',
        Vision: 'Vision',
        Other: 'Other'
    },
    spanish: {
        Cognitive: 'Cognitivos',
        Sleep: 'Sueño',
        Emotional: 'Emocionales',
        Physical: 'Físicos',
        Speech: 'Habla',
        Vision: 'Visión',
        Other: 'Otro'
    }
}

/////////////////////
// SdohBarChart.js //
/////////////////////
export const TOP_TRIGGERS = {
    english: 'Top Triggers',
    spanish: 'Detonantes Principales'
}

/////////////////////////
// MedicalBarChart.js //
/////////////////////////
export const TOP_SYMPTOMS = {
    english: 'Top Symptoms',
    spanish: 'Síntomas Principales'
}

///////////////////
// FeelingBar.js //
///////////////////
export const OVERALL_FEELINGS = {
    english: 'Overall Feelings',
    spanish: 'Sentimientos Generales'
}

/////////////////
// ModalPdf.js //
/////////////////
export const EXPORT_REPORT = {
    english: {
        header: 'Export Report',
        body: ['Your ', 'days report will be exported in PDF format including all the symptom notes.'],
        exclude_symptoms: 'exclude symptoms notes',
        button: 'Export'
    },
    spanish: {
        header: 'Exportar Informe',
        body: ['Su informe de ', ' días se exportará en formato PDF, incluyendo todas las notas de síntomas.'],
        exclude_symptoms: 'excluir notas de síntomas',
        button: 'Exportar'
    }
}
