import React, { useEffect, useState } from "react";
import MedicationHistory from "./MedicationHistory";

import { getHistoryMeds, updateHistoryMeds } from "../../../api/TBIRequests";

const MedicationHistoryContainer = ({ patientId }) => {
    const [loading, setLoading] = useState(true);
    const [medications, setMedications] = useState([]);
    const [initialMedications, setInitialMedications] = useState([]);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    // Fetch medication history to prepopulate the medication field
    useEffect(() => {
        const fetchMedicalHistory = async () => {
            try {
                const res = await getHistoryMeds({ patientId });
                if (res.data.length > 0) {
                    const medicationsData = res.data[0]?.medications || [];
                    const formattedMedicationsData = medicationsData.map((med) => ({
                        medication: med.medication,
                        curUsing: med.curUsing,
                        tolerance: med.tolerance
                    }));
                    setInitialMedications(JSON.parse(JSON.stringify(formattedMedicationsData))); // Deep copy so same reference is not shared.
                    setMedications(formattedMedicationsData);
                }
            } catch (error) {
                console.error("Failed to fetch history medications:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchMedicalHistory();
    }, []);

    const isMedicationsEqual = (medications1, medications2) => {
        return (
            medications1.length == medications2.length &&
            medications1.every((med, index) =>
                med.medication === medications2[index].medication &&
                med.curUsing === medications2[index].curUsing &&
                med.tolerance === medications2[index].tolerance
            )
        )
    }

    const handleAddMedication = () => {
        const updatedMedications = [...medications, { medication: '', curUsing: '', tolerance: '' }];
        setMedications(updatedMedications);
        setUnsavedChanges(!isMedicationsEqual(updatedMedications, initialMedications));
    };

    const handleDeleteMedication = (index) => {
        const updatedMedications = medications.filter((_, i) => i !== index);
        setMedications(updatedMedications);
        setUnsavedChanges(!isMedicationsEqual(updatedMedications, initialMedications));
    };

    const handleChange = (event, index, field) => {
        const updatedMedications = [...medications];
        updatedMedications[index][field] = event.target.value;
        setMedications(updatedMedications);
        setUnsavedChanges(!isMedicationsEqual(updatedMedications, initialMedications));
    };

    const handleSave = async () => {
        try {
            await updateHistoryMeds({ data: medications, patientId });
            setInitialMedications(medications);
            setUnsavedChanges(false);
        } catch (error) {
            console.error("Failed to save medications:", error);
        }
    };

    if (loading) { return <div className='mb-2 mt-n2'>Loading medications...</div> }
    return (
        <MedicationHistory
            medications={medications}
            onAddMedication={handleAddMedication}
            onDeleteMedication={handleDeleteMedication}
            onChange={handleChange}
            unsavedChanges={unsavedChanges}
            onSubmit={handleSave}
        />)
}

export default MedicationHistoryContainer;