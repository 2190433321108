import React, { useEffect, useState, useContext } from "react";
import { PreferredLanguageContext } from "../../lib/main-context";
import { SALLIE_SETTINGS, SETTINGS } from "../../constants/AccountSettingTranslation";

import {
  Switch,
  Route,
  NavLink,
  useRouteMatch,
  useLocation,
} from "react-router-dom";

import { Row, Col, Nav } from "react-bootstrap/";
import Sallie from "../Sallie";
import ChangeNotification from "./ChangeNotification";

const AccountSetting = () => {
  let { path, url } = useRouteMatch();
  const location = useLocation();
  const { language } = useContext(PreferredLanguageContext);
  let optionName = location.pathname.slice(16);
  const [sallieText, setSallieText] = useState("");
  const [isSuccess, setIsSuccess] = useState(undefined);

  useEffect(() => {
    if (isSuccess) {
      setSallieText(SALLIE_SETTINGS[language].success);
    } else if (isSuccess === false) {
      setSallieText(SALLIE_SETTINGS[language].error);
    } else if (optionName === "ChangeNotification" && isSuccess === undefined) {
      setSallieText(SALLIE_SETTINGS[language].notification);
    } else {
      setSallieText(SALLIE_SETTINGS[language].default);
    }
  }, [isSuccess, optionName, language]);

  return (
    <Row>
      <Col>
        <Sallie text={sallieText} />
        <Row className="d-flex justify-content-around">
          <Col md={3} className="accountSettingNav">
            <Nav variant="pills" className="flex-column">
              <Nav.Link
                eventKey={`${url}/ChangeNotification`}
                as={NavLink}
                to={`${url}/ChangeNotification`}
              >
                {SETTINGS[language].notification}
              </Nav.Link>
            </Nav>
          </Col>
          <Col
            md={8}
            className="d-flex justify-content-center align-items-center"
          >
            <Switch>
              <Route path={`${path}/ChangeNotification`}>
                <ChangeNotification setIsSuccess={setIsSuccess} />
              </Route>
            </Switch>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AccountSetting;
