import React, { useContext } from "react";
import { PreferredLanguageContext, ThemeContext } from "../../lib/main-context";
import { CURRENT_PATIENT } from "../../constants/DashboardTranslation";

import { useHistory } from "react-router-dom";
import theme from "../../index.scss";

const CurrentPatient = () => {
  const history = useHistory();
  const { language } = useContext(PreferredLanguageContext);
  const { dark } = useContext(ThemeContext);
  return (
    <div className="d-flex justify-content-center">
      <h6
        className="pt-2 pb-2 pl-3 pr-3"
        style={{
          width: "75%",
          borderRadius: "20px",
          backgroundColor: dark ? theme.darkModePrimary : "#60afa6",
          color: dark ? theme.darkModeText : "white",
          textAlign: "center",
          overflowWrap: "break-word",
          cursor: "pointer"
        }}
        onClick={() => history.push("/patientControl")}
      >
        {CURRENT_PATIENT[language]}
      </h6>
    </div>
  );
};

export default CurrentPatient;
