// Returns whether a list of symptoms includes a duplicate symptom across
// two or more different categories
// used in SelectSocialDeterminants and SelectLaterSymptoms

const hasDuplicatesAcrossCategories = data => {
  if (data[0].symptom) {
    // if we included a symptom on the dataset...
    return data.some(
      item =>
        data.filter(
          selectedItem => selectedItem.symptom.factor === item.symptom.factor
        ).length > 1
    )
  }
  return data.some(item => {
    let duplicateSet = data.filter(
      selectedItem => selectedItem.factor === item.factor
    )
    return duplicateSet.length > 1
  })
}
export default hasDuplicatesAcrossCategories
