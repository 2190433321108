import React from "react";
import UncheckedRadio from "../../images/radio-unchecked.svg";

const StyledCheckbox = ({ category, colors, isChecked }) => {
    const CheckedRadio = () => {
        return (
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>icon/action/check_ccircle_24px</title>
                <defs>
                    <path
                        d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z"
                        id="path-1"
                    ></path>
                </defs>
                <g id="icon/action/check_ccircle_24px" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <use id="-↳Color" fill={colors[category] || colors.other} href="#path-1"></use>
                    <mask id="mask-2" fill="white">
                        <use href="#path-1"></use>
                    </mask>
                </g>
            </svg>
        )
    }
    return (
        <>
            {isChecked ? <CheckedRadio /> : <img src={UncheckedRadio} />}
        </>
    );
}

export default StyledCheckbox;