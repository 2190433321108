const SdohChartOptions = {
    title: {
      display: true,
      text: "SDOH in a week",
      fontFamily: "quicksand",
      fontStyle: "bold",
    },
    barThickness: "flex",
    scales: {
      xAxes: [
        {
          gridLines: {
            offsetGridLines: true,
          },
          offset: true,
          type: "time",
          time: {
            unit: "day",
            tooltipFormat: "dddd",
          },
        //   stacked: true,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 1,
          },
        //   stacked: true,
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: (item, data) => {
          let pointData = data.datasets[item.datasetIndex].data[item.index].y;
          return pointData.detail.join(", ");
        },
      },
    },
  };
  
  export default SdohChartOptions;
  