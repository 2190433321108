import styled from "styled-components";
import Button from "react-bootstrap/Button";
import React from "react";
import Spinner from "react-bootstrap/Spinner";
// shared through the forms to allow easy modification across all submit buttons

export const FilledSubmitButton = styled(Button)`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;
// main button for forms in style guide is outline button. In order to
export const OutlineSubmitButton = ({
  disabled,
  onClick,
  children,
  greyout,
  type,
  style,
  className,
  centered,
  color,
}) => {
  // As of 12/1/2021 submitButtonBox was aligned right using flex-end, so override the style if desired
  return (
    <div className={`submitButtonBox${centered ? " justify-content-center" : ""}`}>
      {/* Couldn't find submitButton class doing anything in the CSS or DevTools 10/30/21 */}
      <FilledSubmitButton
        className={className ? className : "submitButton"}
        onClick={onClick}
        // Added for bug on mobile where buttons take 2 clicks
        // onTouchTap={onClick}
        type={type || "button"}
        variant={`outline-${color ? color : "primary"}`}
        disabled={disabled}
        style={style}
      >
        {(!greyout && disabled) ? (
          <>
            <Spinner
              aria-hidden="true"
              size="sm"
              as="span"
              animation="border"
              role="status"
            />
            Saving...
          </>
        ) : (
          children
        )}
      </FilledSubmitButton>
    </div>
  );
};

export default OutlineSubmitButton;
