import React, { useState, useEffect } from "react";
import FactorSelector from "./FactorSelector";

const FactorSelectorContainer = ({ onChange }) => {
    const factors = {
        Wellness: [
            'Menstrual Cycle',
            'Dehydration',
            'Caffeine',
            'Exercise',
            'Stress',
            'Medication',
            'Hydration',
            "Relaxation/Rest",
            'Anxious',
            'Sick',
            'Muscle Pain',
        ],
        Travel: [
            'Car',
            'Bus',
            'Plane',
            'Boat',
            'Metro/Subway',
            'Cab/Uber/Lyft',
            'Motorized Scooter/Bike',
            'Hiking',
            'Traffic',
            'Bike',
            'Walking',
            'Inability to Drive',
        ],
        "Screen Time": [
            'Computer',
            'TV',
            'Phone',
            'Tablet',
            "Smart Watch",
            'Reading',
            "Video Calling",
            "Online Education",
            "School/Paper Work",
        ],
        Environmental: [
            "High Temperature",
            "Low Temperature",
            'Storm',
            'Rain',
            "Bright Sun",
            "Large Crowds",
            "Loud Noises",
            'Humidity',
            "Barometric Pressure",
            'Wind',
            "Busy Surroundings",
            'Overstimulated',
            "Poor Air Quality",
            'Allergies',
            'Altitude',
        ],
        Dietary: [
            "Loss of Appetite",
            'Undereating',
            "Lack of Access to Food",
            'Hunger',
            "Food Poisoning",
            "Increased Weight",
            "Decreased Weight",
            "Increased Appetite",
            'Overeating',
            'Sugar',
            "Food Allergy",
            'Gluten',
        ],
        Situational: [
            "Family Members",
            "Significant Other",
            'Kids',
            "Work Meeting/Presentation",
            "Grand Kids",
            'Friends',
            "Practicing/Listening to Music",
            "Co-Workers",
            "Doing Tasks",
            "Planning/Organizing",
            'Arguments',
            "Therapy/Medical Appointment",
            'Finances',
        ],
        Other: []
    }

    const colors = {
        Wellness: '#dab7ec',
        Travel: '#ffdadb',
        'Screen Time': '#ffdcaf',
        Environmental: '#fff3c4',
        Dietary: '#cef3c4',
        Situational: '#c1fdf7',
        Other: '#d3d3d3'
    };

    const [selectedCategory, setSelectedCategory] = useState('Wellness');
    const [selectedFactors, setSelectedFactors] = useState([]);
    const [other, setOther] = useState('')

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    }

    const handleFactorSelect = (factor, category) => {
        setSelectedFactors((prevFactors) => {
            const exists = prevFactors.some(
                (item) => item.factor === factor && item.category === category
            );

            if (exists) {
                return prevFactors.filter(
                    (item) => !(item.factor === factor && item.category === category)
                );
            } else {
                return [...prevFactors, { factor, category }];
            }
        });
    }

    const handleOtherChange = (e) => {
        setOther(e.target.value);
    }

    const handleAddOther = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (other.trim() === '') return;
            handleFactorSelect(other, selectedCategory);
            handleOtherChange({ target: { value: '' } });
        }
    }

    const isChecked = (factor, category) => {
        return selectedFactors.some((item) =>
            item.factor === factor &&
            item.category === category
        )
    }

    // useEffect for updating the selected factors in parent component
    useEffect(() => {
        if (onChange) {
            onChange(selectedFactors);
        }
    }, [selectedFactors, onChange]);

    return (
        <FactorSelector
            factors={factors}
            colors={colors}
            selectedCategory={selectedCategory}
            selectedFactors={selectedFactors}
            onCategoryClick={handleCategoryClick}
            onFactorSelect={handleFactorSelect}
            isChecked={isChecked}
            other={other}
            onOtherChange={handleOtherChange}
            onAddOther={handleAddOther}
        />
    )
}

export default FactorSelectorContainer;