import React, { useState, useContext } from "react";
import { PreferredLanguageContext } from "../lib/main-context";
import { CHOOSE, OTHER } from "../constants/OnboardingTranslation";

import Form from "react-bootstrap/Form";
import PillButton from "./StyledComponents/PillButton";
import Check from "../images/checkWithinCircle.svg";
import doubleImage from "../images/double vision.png";
import wordMovingImage from "../images/saccades.png";

const ListSelector = ({
  category,
  list,
  maxSelectAmount,
  selectedList,
  toggleFromSelectedList,
  selectOne,
  addOther,
  index,
  noOther,
  noSuffix,
  setHeadacheParts,
  headacheParts,
  message,
}) => {
  const { language } = useContext(PreferredLanguageContext);

  // 'new item' is the item that will be appended to the returned data
  // if a user adds to 'other' field.
  const [newItem, setNewItem] = useState("");
  const [showDoubleVision, setShowDoubleVision] = useState(false);
  const [showWordMoving, setShowWordMoving] = useState(false);
  const naItems = ["N/A", "n/a", "NA", "na"];
  // suffix shows the user how many to choose
  let suffix;
  if (!noSuffix) {
    if (!maxSelectAmount) {
      suffix = ` (${CHOOSE[language].choose_all}) `;
    } else {
      suffix = ` (${CHOOSE[language].choose} ${maxSelectAmount}) `;
    }
  }

  const handleChange = (event) => {
    setNewItem(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (
      event.key === "Enter" ||
      (!event.currentTarget.contains(document.activeElement) &&
        newItem !== "" &&
        !naItems.includes(newItem))
    ) {
      const formattedItem = addOther(newItem, index);
      if (maxSelectAmount === 1) {
        selectOne(formattedItem, category);
      } else {
        toggleFromSelectedList(
          formattedItem,
          selectedList,
          category,
          maxSelectAmount
        );
      }
      setNewItem("");
    }
  };

  const toggleHelpImg = (item, status) => {
    const helpImgItems = ["Double vision", "Words move when reading"];
    if (helpImgItems.includes(item)) {
      if (item === helpImgItems[0] && status === "enter") {
        setShowDoubleVision(true);
      } else if (item === helpImgItems[1] && status === "enter") {
        setShowWordMoving(true);
      } else {
        setShowDoubleVision(false);
        setShowWordMoving(false);
      }
    }
  };

  const onChange = (e) => {
    let newValue = e.target.value;
    if (e.target.checked && !headacheParts.includes(newValue)) {
      setHeadacheParts((selectedList) => [...selectedList, newValue]);
    } else if (!e.target.checked) {
      setHeadacheParts(headacheParts.filter((item) => item !== newValue));
    }
  };

  return (
    <div className="listSelector">
      <div className="mb-2">
        <h6 className="d-inline">{message}</h6>
        <em className="d-inline">{suffix}</em>
      </div>
      {list?.map((selectableItem) => (
        <span key={selectableItem + 0}>
          <PillButton
            onClick={() =>
              maxSelectAmount === 1
                ? selectOne(selectableItem, category)
                : toggleFromSelectedList(
                  selectableItem,
                  selectedList,
                  category,
                  maxSelectAmount
                )
            }
            onMouseOver={() => {
              toggleHelpImg(selectableItem, "enter");
            }}
            onMouseOut={() => {
              toggleHelpImg(selectableItem, "leave");
            }}
            variant={
              selectedList.includes(selectableItem) ? "info" : "secondary"
            }
            key={selectableItem}
          >
            {selectedList.includes(selectableItem) ? (
              // <div>
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>icon/action/check_ccircle_24px</title>
                <defs>
                  <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z" id="path-1"></path>
                </defs>
                <g id="icon/action/check_ccircle_24px" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                  </mask>
                  <use id="-↳Color" fill="#edebf3" xlinkHref="#path-1"></use>
                </g>
              </svg>
              // </div>
            ) : (
              ""
            )}
            {selectableItem}
          </PillButton>
          {selectableItem === "Double vision" && showDoubleVision ? (
            <img
              className="helpImage"
              alt="double vision image"
              src={doubleImage}
              key={selectableItem + 1}
            />
          ) : null}
          {selectableItem === "Words move when reading" && showWordMoving ? (
            <img
              className="helpImage"
              alt="word moving image"
              src={wordMovingImage}
              key={selectableItem + 2}
            />
          ) : null}
        </span>
      ))}
      {selectedList.includes(
        "Headaches caused by close work: computers, reading, gaming"
      ) && headacheParts ? (
        <>
          <Form className="headacheForm">
            <label className="w-100">
              Headaches from close work appear in the:
            </label>
            {[
              { label: "Forehead", value: "Fore" },
              { label: "Back of head (top)", value: "BackTop" },
              { label: "Right side of head", value: "Right" },
              { label: "Left side of head", value: "Left" },
              { label: "Behind", value: "Behind" },
              { label: "Eyes", value: "Eyes" },
              { label: "Back of head (bottom)", value: "BackBottom" },
              { label: "All over Headache", value: "All" },
            ].map((location, i) => (
              <Form.Check
                inline
                label={location.label}
                value={location.value}
                type="checkbox"
                name="headachePart"
                key={i}
                onChange={onChange}
                checked={headacheParts.includes(location.value) ? true : false}
              />
            ))}
          </Form>
          <hr></hr>
          <br></br>
        </>
      ) : null}
      {noOther ? (
        ""
      ) : (
        <label htmlFor={`${category}-other`} className="w-100">
          {OTHER[language]}: &nbsp;
          <input
            value={newItem}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            onBlur={handleKeyPress}
            aria-label="Type the name of a new item and press enter to add a button to the list above"
            id={`${category}-other`}
            style={{
              border: "0",
              outline: "0",
              background: "transparent",
              borderBottom: "2px solid black",
              width: "33%",
            }}
            name="other"
            placeholder={OTHER[language]}
          />
        </label>
      )}
    </div>
  );
};

export default ListSelector;
