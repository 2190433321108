/* eslint-disable no-underscore-dangle */
import * as yup from 'yup'

// allows empty strings, see :https://github.com/jquense/yup/issues/298#issuecomment-519222238

// function transform(value, originalValue) {
//   if (originalValue.trim() === '' || originalValue.trim() === '*') {
//     return null
//   }
//   return value
// }

const TrackerSchema = yup.object().shape({
  describeSymptom: yup.string().required('Please enter a note'),
})

export default TrackerSchema
