import axios from "axios";

import apiUrl from "./apiConfig";
const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
const channelId = process.env.REACT_APP_YOUTUBE_CHANNEL_ID;

axios.defaults.withCredentials = true;

export const postHelp = (data) => {
  return axios.post(`${apiUrl}/misc/help-ticket/`, data);
};

export const sendWarnEmailToProviderAndTP = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/sendWarnEmailToProviderAndTP`, data, {
    params: { patientId },
  });
};

export const sendConfirmEmail = (data) => {
  return axios.post(`${apiUrl}/sendConfirmEmail`, data);
};

export const checkExistingList = (data) => {
  return axios.post(`${apiUrl}/checkExistingList`, data);
};

export const checkEmailToken = (data) => {
  return axios.post(`${apiUrl}/checkEmailToken`, data);
};

export const deleteEmailToken = () => {
  return axios.delete(`${apiUrl}/deleteEmailToken`);
};

export const sendResetPwEmail = (data) => {
  return axios.post(`${apiUrl}/misc/sendResetPwEmail`, data);
};

export const getYoutubeVideos = () => {
  return fetch(
    `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelId}&part=snippet,id&order=date&maxResults=50`
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const creatConnection = (data) => {
  return axios.post(`${apiUrl}/creatConnection`, data);
};

export const getProviderTokenInformation = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/getProviderTokenInformation`, data, {
    params: { patientId },
  });
};

export const getProviderID = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/getProviderID`, data, {
    params: { patientId },
  });
};

export const getPatientID = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/getPatientID`, data, {
    params: { patientId },
  });
};

export const getInviterInfo = (data) => {
  return axios.post(`${apiUrl}/getInviterInfo`, data);
};
